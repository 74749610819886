import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastProvider } from '../../components/ui/toast';
import Dropdown from '../../components/ui/dropdown';
import Avatar from '../../components/ui/avatar';
import newLogo from '../../assets/marketing/newLogo.webp';
import { adminLogout } from '../../store/admin';

// Import icons
import {
	FiMenu,
	FiX,
	FiHome,
	FiUsers,
	FiUserPlus,
	FiSettings,
	FiLogOut,
	FiChevronDown,
	FiBox,
} from 'react-icons/fi';

const AdminLayout = ({ children }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	const handleLogout = () => {
		dispatch(adminLogout());
		history.push('/admin/login');
	};

	const navigation = [
		{ name: 'Dashboard', href: '/admin/dashboard', icon: FiHome },
		{ name: 'Clients', href: '/admin/clients', icon: FiUsers },
		{ name: 'Create User', href: '/admin/create-user', icon: FiUserPlus },
		{ name: 'Form Builder', href: '/admin/form-builder', icon: FiBox },
	];

	const isActive = (path) => {
		return (
			location.pathname === path || location.pathname.startsWith(`${path}/`)
		);
	};

	return (
		<ToastProvider>
			<div className='min-h-screen bg-clientMidWhiteSmoke'>
				{/* Mobile sidebar */}
				<div
					className={`fixed inset-0 z-40 flex md:hidden ${
						sidebarOpen ? 'visible' : 'invisible'
					}`}
					role='dialog'
					aria-modal='true'>
					{/* Overlay */}
					<div
						className={`fixed inset-0 bg-black bg-opacity-75 transition-opacity ease-in-out duration-300 ${
							sidebarOpen ? 'opacity-100' : 'opacity-0'
						}`}
						aria-hidden='true'
						onClick={toggleSidebar}></div>

					{/* Sidebar */}
					<div
						className={`relative flex-1 flex flex-col max-w-xs w-full bg-clientBlack transition ease-in-out duration-300 transform ${
							sidebarOpen ? 'translate-x-0' : '-translate-x-full'
						}`}>
						<div className='absolute top-0 right-0 -mr-12 pt-2'>
							<button
								type='button'
								className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
								onClick={toggleSidebar}>
								<span className='sr-only'>Close sidebar</span>
								<FiX className='h-6 w-6 text-white' />
							</button>
						</div>

						{/* Mobile sidebar logo */}
						<div className='flex items-center justify-center h-[72px] px-4 border-b border-gray-800'>
							<img
								className='h-12 w-auto'
								src={newLogo}
								alt='Jane Rothe Logo'
							/>
						</div>

						<div className='flex-1 h-0 overflow-y-auto'>
							<nav className='px-2 pt-8 pb-4'>
								{navigation.map((item) => (
									<Link
										key={item.name}
										to={item.href}
										className={`group flex items-center px-3 py-3 mb-2 text-base font-montserrat-medium rounded-sm ${
											isActive(item.href)
												? 'bg-gray-900 text-primary'
												: 'text-gray-300 hover:bg-gray-800 hover:text-white'
										}`}>
										<item.icon
											className={`mr-4 flex-shrink-0 h-6 w-6 ${
												isActive(item.href)
													? 'text-primary'
													: 'text-gray-400 group-hover:text-gray-300'
											}`}
											aria-hidden='true'
										/>
										{item.name}
									</Link>
								))}
							</nav>
						</div>
					</div>

					<div className='flex-shrink-0 w-14' aria-hidden='true'>
						{/* Dummy element to force sidebar to shrink to fit close icon */}
					</div>
				</div>

				{/* Static sidebar for desktop */}
				<div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
					<div className='flex-1 flex flex-col min-h-0 border-r border-gray-800 bg-clientBlack'>
						<div className='flex-1 flex flex-col overflow-y-auto'>
							{/* Desktop sidebar logo */}
							<div className='flex items-center justify-center h-[72px] px-4 border-b border-gray-800'>
								<img
									className='h-12 w-auto'
									src={newLogo}
									alt='Jane Rothe Logo'
								/>
							</div>
							<nav className='flex-1 px-2 pt-8 pb-4'>
								{navigation.map((item) => (
									<Link
										key={item.name}
										to={item.href}
										className={`group flex items-center px-3 py-3 mb-2 text-sm font-montserrat-medium rounded-sm ${
											isActive(item.href)
												? 'bg-gray-900 text-primary'
												: 'text-gray-300 hover:bg-gray-800 hover:text-white'
										}`}>
										<item.icon
											className={`mr-3 flex-shrink-0 h-6 w-6 ${
												isActive(item.href)
													? 'text-primary'
													: 'text-gray-400 group-hover:text-gray-300'
											}`}
											aria-hidden='true'
										/>
										{item.name}
									</Link>
								))}
							</nav>
						</div>
					</div>
				</div>

				{/* Main content */}
				<div className='md:pl-64 flex flex-col flex-1'>
					<div className='sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white'>
						<button
							type='button'
							className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'
							onClick={toggleSidebar}>
							<span className='sr-only'>Open sidebar</span>
							<FiMenu className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>

					{/* Header */}
					<header className='bg-white shadow-sm border-b border-gray-100'>
						<div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-[72px] flex justify-between items-center'>
							<div>
								<h1 className='text-2xl font-glacial uppercase text-gray-900'>
									{navigation.find((item) => isActive(item.href))?.name ||
										'Admin Portal'}
								</h1>
								<p className='text-sm font-montserrat-regular text-gray-500 mt-1'>
									{isActive('/admin/dashboard')
										? 'Overview of your platform'
										: isActive('/admin/clients')
										? 'Manage your client accounts'
										: isActive('/admin/create-user')
										? 'Create new user accounts'
										: isActive('/admin/form-builder')
										? 'Build and manage forms'
										: ''}
								</p>
							</div>

							{/* User dropdown */}
							<Dropdown
								trigger={
									<div className='flex items-center'>
										<Avatar className='h-8 w-8 border border-gray-200'>
											<Avatar.Image
												src={
													currentAdmin.imageUrl ||
													'https://via.placeholder.com/40'
												}
												alt={`${currentAdmin.firstName} ${currentAdmin.lastName}`}
											/>
											<Avatar.Fallback>
												{currentAdmin.firstName && currentAdmin.lastName
													? `${currentAdmin.firstName[0]}${currentAdmin.lastName[0]}`
													: 'AD'}
											</Avatar.Fallback>
										</Avatar>
										<span className='ml-2 text-sm font-montserrat-medium text-gray-700 group-hover:text-gray-900'>
											{currentAdmin.firstName} {currentAdmin.lastName}
										</span>
										<FiChevronDown
											className='ml-1 h-5 w-5 text-gray-400'
											aria-hidden='true'
										/>
									</div>
								}>
								<Dropdown.Item>
									<div className='flex items-center'>
										<FiSettings className='mr-2 h-4 w-4' />
										<span className='font-montserrat-medium'>Settings</span>
									</div>
								</Dropdown.Item>
								<Dropdown.Separator />
								<Dropdown.Item onClick={handleLogout}>
									<div className='flex items-center'>
										<FiLogOut className='mr-2 h-4 w-4' />
										<span className='font-montserrat-medium'>Logout</span>
									</div>
								</Dropdown.Item>
							</Dropdown>
						</div>
					</header>

					{/* Main content */}
					<main className='flex-1'>
						<div className='py-6'>
							<div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
								{children}
							</div>
						</div>
					</main>
				</div>
			</div>
		</ToastProvider>
	);
};

AdminLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AdminLayout;
