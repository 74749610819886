import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
	getForms,
	deleteForm,
	clearCurrentForm,
} from '../../store/formBuilder';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Table from '../../components/ui/table';
import { FiEdit, FiTrash2, FiPlus, FiEye } from 'react-icons/fi';

// Utility to check localStorage for persisted Redux data
const checkLocalStorage = () => {
	try {
		// Get Redux persisted data from localStorage
		const persistedData = localStorage.getItem('persist:root');

		if (persistedData) {
			const parsedData = JSON.parse(persistedData);

			// If there's a formBuilder key, parse it
			if (parsedData.formBuilder) {
				const formBuilderData = JSON.parse(parsedData.formBuilder);
				console.log('Persisted FormBuilder data:', formBuilderData);

				// Return the forms array from persisted data
				return formBuilderData.forms || [];
			}
		}
	} catch (error) {
		console.error('Error parsing localStorage data:', error);
	}

	return [];
};

const FormBuilderList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { forms, status, error, apiNotAvailable } = useSelector(
		(state) => state.formBuilder
	);
	const token = useSelector((state) => state.admin.token);
	const reduxState = useSelector((state) => state); // Get Redux state outside the hook
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [formToDelete, setFormToDelete] = useState(null);

	useEffect(() => {
		// Check if there's persisted data in localStorage
		const persistedForms = checkLocalStorage();
		console.log('Persisted forms from localStorage:', persistedForms);

		// Fetch forms when component mounts
		dispatch(getForms({ token }));

		// Clear current form when returning to list view
		dispatch(clearCurrentForm());

		// Debug: Log Redux state
		console.log('Redux state:', reduxState);

		// This should only run once when component mounts
	}, [dispatch, token]); // Remove forms and reduxState from dependencies to avoid infinite loop

	// Debug: Log forms whenever they change in a separate useEffect
	useEffect(() => {
		console.log('Forms updated:', forms);
	}, [forms]);

	const handleCreateForm = () => {
		history.push('/admin/form-builder/create');
	};

	const handleEditForm = (id) => {
		history.push(`/admin/form-builder/edit/${id}`);
	};

	const handleViewForm = (id) => {
		history.push(`/admin/form-builder/view/${id}`);
	};

	const confirmDelete = (form) => {
		setFormToDelete(form);
		setShowDeleteModal(true);
	};

	const handleDeleteForm = async () => {
		if (formToDelete) {
			await dispatch(deleteForm({ id: formToDelete.id, token }));
			setShowDeleteModal(false);
			setFormToDelete(null);
		}
	};

	const cancelDelete = () => {
		setShowDeleteModal(false);
		setFormToDelete(null);
	};

	// Render delete confirmation modal
	const DeleteConfirmationModal = () => (
		<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
			<div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full'>
				<h2 className='text-xl font-bold mb-4'>Delete Form</h2>
				<p className='mb-6'>
					Are you sure you want to delete the form "{formToDelete?.name}"? This
					action cannot be undone.
				</p>
				<div className='flex justify-end space-x-2'>
					<Button variant='outline' onClick={cancelDelete}>
						Cancel
					</Button>
					<Button variant='destructive' onClick={handleDeleteForm}>
						Delete
					</Button>
				</div>
			</div>
		</div>
	);

	// Display loading state - modified to handle the error case properly
	if (status === 'loading' && forms.length === 0) {
		return (
			<div className='flex justify-center items-center h-64'>
				<div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900'></div>
			</div>
		);
	}

	// Add a condition to handle the failed state with fallback UI
	if (status === 'failed' && forms.length === 0) {
		return (
			<div className='container mx-auto px-4 py-8'>
				<div className='flex justify-between items-center mb-6'>
					<h1 className='text-2xl font-bold text-gray-900'>Form Builder</h1>
					<Button onClick={handleCreateForm} className='flex items-center'>
						<FiPlus className='mr-2' />
						Create New Form
					</Button>
				</div>

				{error && (
					<div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4'>
						{error}
					</div>
				)}

				<Card>
					<Card.Header>
						<div className='text-xl font-semibold'>Forms</div>
					</Card.Header>
					<Card.Content>
						<div className='flex flex-col items-center justify-center py-12'>
							<p className='text-gray-500 mb-4'>
								{apiNotAvailable
									? "The Forms API endpoint is not available. You can still create forms but they won't be saved to the server."
									: 'No forms available. Try creating a new form.'}
							</p>
							<Button onClick={handleCreateForm}>Create Your First Form</Button>
						</div>
					</Card.Content>
				</Card>
			</div>
		);
	}

	return (
		<div className='container mx-auto px-4 py-8'>
			{showDeleteModal && <DeleteConfirmationModal />}

			<div className='flex justify-between items-center mb-6'>
				<h1 className='text-2xl font-bold text-gray-900'>Form Builder</h1>
				<Button onClick={handleCreateForm} className='flex items-center'>
					<FiPlus className='mr-2' />
					Create New Form
				</Button>
			</div>

			{error && (
				<div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4'>
					{error}
				</div>
			)}

			<Card>
				<Card.Header>
					<div className='text-xl font-semibold'>Forms</div>
				</Card.Header>
				<Card.Content>
					{forms.length > 0 ? (
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.Head>Name</Table.Head>
									<Table.Head>Category</Table.Head>
									<Table.Head>Subscription Required</Table.Head>
									<Table.Head>Status</Table.Head>
									<Table.Head>Last Updated</Table.Head>
									<Table.Head className='text-right'>Actions</Table.Head>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{forms.map((form) => (
									<Table.Row key={form.id}>
										<Table.Cell className='font-medium'>{form.name}</Table.Cell>
										<Table.Cell>{form.category}</Table.Cell>
										<Table.Cell>
											{form.required_subscription &&
											form.required_subscription.length > 0 ? (
												<div className='flex flex-wrap gap-1'>
													{form.required_subscription.map((sub, index) => (
														<span
															key={index}
															className='inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-gray-100'>
															{sub}
														</span>
													))}
												</div>
											) : (
												<span className='text-gray-500'>None</span>
											)}
										</Table.Cell>
										<Table.Cell>
											<span
												className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
													form.is_active
														? 'bg-green-100 text-green-800'
														: 'bg-gray-100 text-gray-800'
												}`}>
												{form.is_active ? 'Active' : 'Inactive'}
											</span>
										</Table.Cell>
										<Table.Cell>
											{new Date(form.updated_at).toLocaleDateString()}
										</Table.Cell>
										<Table.Cell className='text-right'>
											<div className='flex justify-end space-x-2'>
												<Button
													variant='ghost'
													size='sm'
													onClick={() => handleViewForm(form.id)}
													className='h-8 w-8 p-0'>
													<FiEye className='h-4 w-4' />
													<span className='sr-only'>View</span>
												</Button>
												<Button
													variant='ghost'
													size='sm'
													onClick={() => handleEditForm(form.id)}
													className='h-8 w-8 p-0'>
													<FiEdit className='h-4 w-4' />
													<span className='sr-only'>Edit</span>
												</Button>
												<Button
													variant='ghost'
													size='sm'
													onClick={() => confirmDelete(form)}
													className='h-8 w-8 p-0 text-red-500 hover:text-red-700'>
													<FiTrash2 className='h-4 w-4' />
													<span className='sr-only'>Delete</span>
												</Button>
											</div>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					) : (
						<div className='flex flex-col items-center justify-center py-12'>
							<p className='text-gray-500 mb-4'>
								No forms have been created yet.
							</p>
							<Button onClick={handleCreateForm}>Create Your First Form</Button>
						</div>
					)}
				</Card.Content>
			</Card>
		</div>
	);
};

export default FormBuilderList;
