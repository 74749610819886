import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
	getFormById,
	createForm,
	updateForm,
	createFormField,
	updateFormField,
	deleteFormField,
	createFormSection,
	updateFormSection,
	deleteFormSection,
	assignFieldToSection,
	updateFieldPosition,
	reorderFormFields,
	removeFieldFromSection,
	getFieldTypes,
} from '../../store/formBuilder';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Tabs from '../../components/ui/tabs';
import Form from '../../components/ui/form';
import Input from '../../components/ui/input';
import Label from '../../components/ui/label';
import {
	FiPlus,
	FiSave,
	FiArrowLeft,
	FiTrash2,
	FiMove,
	FiX,
	FiSettings,
} from 'react-icons/fi';
import FormFieldEditor from './FormFieldEditor';
import FormSectionEditor from './FormSectionEditor';
import FormPreview from './FormPreview';

// Helper to get next position number for fields or sections
const getNextPosition = (items) => {
	if (!items || items.length === 0) return 0;
	const maxPosition = Math.max(...items.map((item) => item.position));
	return maxPosition + 1;
};

const FormBuilderEditor = () => {
	const { id } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const isEditMode = Boolean(id);

	console.log('FormBuilderEditor mounting. ID:', id, 'isEditMode:', isEditMode);

	// Redux state
	const {
		currentForm,
		formFields,
		formSections,
		fieldSectionMap,
		fieldTypes,
		status,
		error,
	} = useSelector((state) => state.formBuilder);
	const token = useSelector((state) => state.admin.token);

	console.log('FormBuilderEditor - token:', token ? 'present' : 'missing');
	console.log('FormBuilderEditor - status:', status);

	// Local state
	const [activeTab, setActiveTab] = useState(0);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		category: '',
		required_subscription: [],
		is_active: true,
	});
	const [subscriptionInput, setSubscriptionInput] = useState('');
	const [selectedField, setSelectedField] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
	const [showFieldEditor, setShowFieldEditor] = useState(false);
	const [showSectionEditor, setShowSectionEditor] = useState(false);
	const [draggedFieldId, setDraggedFieldId] = useState(null);
	const [draggedSectionId, setDraggedSectionId] = useState(null);
	const [dropTarget, setDropTarget] = useState(null);

	// Effect to load form data when editing
	useEffect(() => {
		if (isEditMode) {
			dispatch(getFormById({ id, token }));
		}

		// Always fetch field types, whether in edit or create mode
		dispatch(getFieldTypes({ token }));
	}, [dispatch, id, isEditMode, token]);

	// Effect to set form data from loaded form
	useEffect(() => {
		if (currentForm && isEditMode) {
			setFormData({
				name: currentForm.name || '',
				description: currentForm.description || '',
				category: currentForm.category || '',
				required_subscription: currentForm.required_subscription || [],
				is_active:
					currentForm.is_active !== undefined ? currentForm.is_active : true,
			});
		}
	}, [currentForm, isEditMode]);

	// Handle form input changes
	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	// Add subscription to the list
	const handleAddSubscription = () => {
		if (
			subscriptionInput.trim() &&
			!formData.required_subscription.includes(subscriptionInput.trim())
		) {
			setFormData({
				...formData,
				required_subscription: [
					...formData.required_subscription,
					subscriptionInput.trim(),
				],
			});
			setSubscriptionInput('');
		}
	};

	// Remove subscription from the list
	const handleRemoveSubscription = (subscription) => {
		setFormData({
			...formData,
			required_subscription: formData.required_subscription.filter(
				(s) => s !== subscription
			),
		});
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Form submission started. Form data:', formData);

		try {
			if (isEditMode) {
				console.log('Updating existing form with ID:', id);
				const result = await dispatch(
					updateForm({
						id,
						formData,
						token,
					})
				).unwrap();
				console.log('Form update successful:', result);
			} else {
				console.log('Creating new form');
				const result = await dispatch(
					createForm({
						formData,
						token,
					})
				).unwrap();
				console.log('Form creation successful. New form ID:', result.form?.id);

				// Check if we have a valid ID before redirecting
				if (result.form && result.form.id) {
					history.replace(`/admin/form-builder/edit/${result.form.id}`);
				} else {
					console.error('Form was created but no ID was returned:', result);
					alert(
						'Form was created but there was an issue loading the edit page. Please return to the form list and try again.'
					);
				}
			}
		} catch (error) {
			console.error('Error saving form:', error);
			alert(`Error saving form: ${error.message || 'Unknown error'}`);
		}
	};

	// Add new field
	const handleAddField = () => {
		setSelectedField(null); // Creating a new field
		setShowFieldEditor(true);
	};

	// Edit existing field
	const handleEditField = (field) => {
		setSelectedField(field);
		setShowFieldEditor(true);
	};

	// Save field
	const handleSaveField = async (fieldData) => {
		try {
			if (!currentForm || !currentForm.id) {
				console.error('Current form is not available');
				return;
			}

			if (selectedField) {
				// Update existing field
				await dispatch(
					updateFormField({
						formId: currentForm.id,
						fieldId: selectedField.id,
						fieldData,
						token,
					})
				).unwrap();
			} else {
				// Create new field
				const newField = {
					...fieldData,
					form_id: currentForm.id,
					position: getNextPosition(formFields || []),
				};
				await dispatch(
					createFormField({
						formId: currentForm.id,
						fieldData: newField,
						token,
					})
				).unwrap();
			}
			setShowFieldEditor(false);
		} catch (error) {
			console.error('Error saving field:', error);
		}
	};

	// Delete field
	const handleDeleteField = async (fieldId) => {
		if (
			window.confirm(
				'Are you sure you want to delete this field? This cannot be undone.'
			)
		) {
			try {
				await dispatch(
					deleteFormField({
						formId: currentForm.id,
						fieldId,
						token,
					})
				).unwrap();
				setSelectedField(null);
				setShowFieldEditor(false);
			} catch (error) {
				console.error('Error deleting field:', error);
			}
		}
	};

	// Add new section
	const handleAddSection = () => {
		setSelectedSection(null); // Creating a new section
		setShowSectionEditor(true);
	};

	// Edit existing section
	const handleEditSection = (section) => {
		setSelectedSection(section);
		setShowSectionEditor(true);
	};

	// Save section
	const handleSaveSection = async (sectionData) => {
		try {
			if (selectedSection) {
				// Update existing section
				await dispatch(
					updateFormSection({
						formId: currentForm.id,
						sectionId: selectedSection.id,
						sectionData,
						token,
					})
				).unwrap();
			} else {
				// Create new section
				const newSection = {
					...sectionData,
					form_id: currentForm.id,
					position: getNextPosition(formSections),
				};
				await dispatch(
					createFormSection({
						formId: currentForm.id,
						sectionData: newSection,
						token,
					})
				).unwrap();
			}
			setShowSectionEditor(false);
		} catch (error) {
			console.error('Error saving section:', error);
		}
	};

	// Delete section
	const handleDeleteSection = async (sectionId) => {
		if (
			window.confirm(
				'Are you sure you want to delete this section? All fields in this section will be removed from the section.'
			)
		) {
			try {
				await dispatch(
					deleteFormSection({
						formId: currentForm.id,
						sectionId,
						token,
					})
				).unwrap();
				setSelectedSection(null);
				setShowSectionEditor(false);
			} catch (error) {
				console.error('Error deleting section:', error);
			}
		}
	};

	// Add field to section
	const handleAddFieldToSection = async (fieldId, sectionId) => {
		try {
			if (!currentForm || !fieldSectionMap) {
				console.error('Current form or field section mapping is not available');
				return;
			}

			const sectionFields = fieldSectionMap
				.filter((mapping) => mapping.section_id === sectionId)
				.sort((a, b) => a.position - b.position);

			const position =
				sectionFields.length > 0
					? Math.max(...sectionFields.map((mapping) => mapping.position)) + 1
					: 0;

			await dispatch(
				assignFieldToSection({
					formId: currentForm.id,
					fieldId,
					sectionId,
					position,
					token,
				})
			).unwrap();
		} catch (error) {
			console.error('Error adding field to section:', error);
		}
	};

	// Remove field from section
	const handleRemoveFieldFromSection = async (fieldId, sectionId) => {
		try {
			if (!fieldSectionMap) {
				console.error('Field section mapping is not available');
				return;
			}

			// Find the mapping to get the field details
			const fieldMapping = fieldSectionMap.find(
				(mapping) =>
					mapping.field_id === fieldId && mapping.section_id === sectionId
			);

			if (!fieldMapping) {
				console.error('Field mapping not found');
				return;
			}

			dispatch(
				removeFieldFromSection({
					fieldId,
					sectionId,
				})
			);
		} catch (error) {
			console.error('Error removing field from section:', error);
		}
	};

	// Get fields for a section
	const getFieldsForSection = (sectionId) => {
		if (!fieldSectionMap || !formFields) return [];

		const mappings = fieldSectionMap
			.filter((mapping) => mapping.section_id === sectionId)
			.sort((a, b) => a.position - b.position);

		return mappings
			.map((mapping) => {
				return formFields.find((field) => field.id === mapping.field_id);
			})
			.filter(Boolean);
	};

	// Get fields not in any section
	const getUnassignedFields = () => {
		if (!fieldSectionMap || !formFields) return [];

		const assignedFieldIds = fieldSectionMap.map((mapping) => mapping.field_id);
		return formFields
			.filter((field) => !assignedFieldIds.includes(field.id))
			.sort((a, b) => a.position - b.position);
	};

	// Handle field drop on a section
	const handleFieldDrop = (fieldId, sectionId) => {
		if (!fieldSectionMap) {
			console.error('Field section mapping is not available');
			return;
		}

		// Check if the field is already in this section
		const existingMapping = fieldSectionMap.find(
			(mapping) =>
				mapping.field_id === fieldId && mapping.section_id === sectionId
		);

		if (!existingMapping) {
			// Get the current section ID if the field is in another section
			const currentSectionMapping = fieldSectionMap.find(
				(mapping) => mapping.field_id === fieldId
			);

			if (currentSectionMapping) {
				// Remove from current section and add to new section
				handleRemoveFieldFromSection(
					fieldId,
					currentSectionMapping.section_id
				).then(() => handleAddFieldToSection(fieldId, sectionId));
			} else {
				// Just add to the new section
				handleAddFieldToSection(fieldId, sectionId);
			}
		}
	};

	// Render form fields
	const renderFields = (fields, sectionId = null) => {
		if (!fields || !Array.isArray(fields)) return null;

		return (
			<div className='space-y-2 min-h-[100px] p-2'>
				{fields.map((field) => (
					<div
						key={field.id}
						className='flex items-center p-2 bg-white border rounded shadow-sm hover:shadow-md cursor-move'
						draggable
						onDragStart={() => setDraggedFieldId(field.id)}
						onDragEnd={() => {
							if (dropTarget && dropTarget.type === 'section') {
								handleFieldDrop(draggedFieldId, dropTarget.id);
							}
							setDraggedFieldId(null);
							setDropTarget(null);
						}}>
						<FiMove className='mr-2 text-gray-400' />
						<div className='flex-1'>
							<div className='font-medium'>{field.label}</div>
							<div className='text-sm text-gray-500'>{field.type}</div>
						</div>
						<div className='flex space-x-1'>
							<button
								onClick={() => handleEditField(field)}
								className='p-1 hover:bg-gray-100 rounded'>
								<FiSettings className='text-gray-500' />
							</button>
							{sectionId && (
								<button
									onClick={() =>
										handleRemoveFieldFromSection(field.id, sectionId)
									}
									className='p-1 hover:bg-gray-100 rounded'>
									<FiX className='text-gray-500' />
								</button>
							)}
							<button
								onClick={() => handleDeleteField(field.id)}
								className='p-1 hover:bg-red-100 rounded'>
								<FiTrash2 className='text-red-500' />
							</button>
						</div>
					</div>
				))}
			</div>
		);
	};

	// Render form sections
	const renderSections = () => {
		if (!formSections) return null;

		return (
			<div className='space-y-4'>
				{formSections
					.sort((a, b) => a.position - b.position)
					.map((section) => {
						const sectionFields = getFieldsForSection(section.id);

						return (
							<div
								key={section.id}
								className='border rounded-lg overflow-hidden'
								onDragOver={(e) => {
									e.preventDefault();
									setDropTarget({ type: 'section', id: section.id });
								}}
								onDragLeave={() => setDropTarget(null)}>
								<div className='flex items-center justify-between bg-gray-50 p-3 border-b'>
									<div className='font-medium'>{section.title}</div>
									<div className='flex space-x-1'>
										<button
											onClick={() => handleEditSection(section)}
											className='p-1 hover:bg-gray-200 rounded'>
											<FiSettings className='text-gray-500' />
										</button>
										<button
											onClick={() => handleDeleteSection(section.id)}
											className='p-1 hover:bg-red-100 rounded'>
											<FiTrash2 className='text-red-500' />
										</button>
									</div>
								</div>
								<div
									className={`p-3 ${
										dropTarget?.id === section.id ? 'bg-blue-50' : 'bg-white'
									}`}>
									<p className='text-sm text-gray-500 mb-2'>
										{section.description}
									</p>
									{renderFields(sectionFields, section.id)}
								</div>
							</div>
						);
					})}
			</div>
		);
	};

	// Loading state
	if (isEditMode && status === 'loading' && !currentForm) {
		return (
			<div className='flex justify-center items-center h-64'>
				<div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900'></div>
			</div>
		);
	}

	// Error state
	if (error) {
		return (
			<div className='container mx-auto px-4 py-8'>
				<div className='flex items-center mb-6'>
					<button
						onClick={() => history.push('/admin/form-builder')}
						className='mr-4 p-2 hover:bg-gray-100 rounded'>
						<FiArrowLeft className='text-gray-500' />
					</button>
					<h1 className='text-2xl font-bold text-gray-900'>
						{isEditMode ? 'Edit Form' : 'Create Form'}
					</h1>
				</div>

				<div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4'>
					<p className='font-bold'>Error:</p>
					<p>{error}</p>
				</div>

				<Button
					onClick={() => history.push('/admin/form-builder')}
					className='mt-4'>
					Return to Form List
				</Button>
			</div>
		);
	}

	return (
		<div className='container mx-auto px-4 py-8'>
			<div className='flex items-center mb-6'>
				<button
					onClick={() => history.push('/admin/form-builder')}
					className='mr-4 p-2 hover:bg-gray-100 rounded'>
					<FiArrowLeft className='text-gray-500' />
				</button>
				<h1 className='text-2xl font-bold text-gray-900'>
					{isEditMode ? 'Edit Form' : 'Create Form'}
				</h1>
			</div>

			<Tabs defaultValue={0} onValueChange={setActiveTab} value={activeTab}>
				<Tabs.List className='border-b mb-4'>
					<Tabs.Trigger value='0'>Form Details</Tabs.Trigger>
					{isEditMode && (
						<Tabs.Trigger value='1'>Fields & Sections</Tabs.Trigger>
					)}
					{isEditMode && <Tabs.Trigger value='2'>Preview</Tabs.Trigger>}
				</Tabs.List>

				<Tabs.Content value='0'>
					<Card>
						<Card.Header>
							<h2 className='text-xl font-semibold'>Form Information</h2>
						</Card.Header>
						<Card.Content>
							<Form onSubmit={handleSubmit}>
								<div className='grid grid-cols-1 gap-6'>
									<div>
										<Label htmlFor='name'>Form Name</Label>
										<Input
											id='name'
											name='name'
											value={formData.name}
											onChange={handleInputChange}
											required
										/>
									</div>

									<div>
										<Label htmlFor='category'>Category</Label>
										<Input
											id='category'
											name='category'
											value={formData.category}
											onChange={handleInputChange}
											required
										/>
									</div>

									<div>
										<Label htmlFor='description'>Description</Label>
										<textarea
											id='description'
											name='description'
											value={formData.description}
											onChange={handleInputChange}
											rows={3}
											className='w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-400'
										/>
									</div>

									<div>
										<Label>Required Subscriptions</Label>
										<div className='flex gap-2 mb-2'>
											<Input
												placeholder='Add subscription'
												value={subscriptionInput}
												onChange={(e) => setSubscriptionInput(e.target.value)}
											/>
											<Button
												type='button'
												onClick={handleAddSubscription}
												variant='outline'>
												<FiPlus />
											</Button>
										</div>

										<div className='flex flex-wrap gap-2 mt-2'>
											{formData.required_subscription.map(
												(subscription, index) => (
													<div
														key={index}
														className='bg-gray-100 px-2 py-1 rounded-md flex items-center'>
														<span>{subscription}</span>
														<button
															type='button'
															onClick={() =>
																handleRemoveSubscription(subscription)
															}
															className='ml-2 text-gray-500 hover:text-red-500'>
															<FiX size={14} />
														</button>
													</div>
												)
											)}
										</div>
									</div>

									<div className='flex items-center'>
										<input
											type='checkbox'
											id='is_active'
											name='is_active'
											checked={formData.is_active}
											onChange={handleInputChange}
											className='h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded'
										/>
										<Label htmlFor='is_active' className='ml-2'>
											Active
										</Label>
									</div>

									<div className='flex justify-end'>
										<Button type='submit' className='flex items-center'>
											<FiSave className='mr-2' />
											Save Form
										</Button>
									</div>
								</div>
							</Form>
						</Card.Content>
					</Card>
				</Tabs.Content>

				{isEditMode && (
					<Tabs.Content value='1'>
						<DndProvider backend={HTML5Backend}>
							<div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
								<div className='lg:col-span-2'>
									<Card>
										<Card.Header className='flex justify-between items-center'>
											<h2 className='text-xl font-semibold'>Form Sections</h2>
											<Button
												onClick={handleAddSection}
												size='sm'
												className='flex items-center'>
												<FiPlus className='mr-1' />
												Add Section
											</Button>
										</Card.Header>
										<Card.Content>
											{formSections && formSections.length > 0 ? (
												renderSections()
											) : (
												<div className='text-center py-8 text-gray-500'>
													<p>No sections have been added to this form.</p>
													<Button
														onClick={handleAddSection}
														variant='outline'
														className='mt-2'>
														Add First Section
													</Button>
												</div>
											)}
										</Card.Content>
									</Card>
								</div>

								<div>
									<Card>
										<Card.Header className='flex justify-between items-center'>
											<h2 className='text-xl font-semibold'>Form Fields</h2>
											<Button
												onClick={handleAddField}
												size='sm'
												className='flex items-center'>
												<FiPlus className='mr-1' />
												Add Field
											</Button>
										</Card.Header>
										<Card.Content>
											<div className='mb-4'>
												<h3 className='font-medium text-gray-700 mb-2'>
													Unassigned Fields
												</h3>
												{renderFields(getUnassignedFields())}
											</div>
											<p className='text-sm text-gray-500 italic'>
												Drag fields to sections to organize your form
											</p>
										</Card.Content>
									</Card>
								</div>
							</div>
						</DndProvider>
					</Tabs.Content>
				)}

				{isEditMode && (
					<Tabs.Content value='2'>
						<Card>
							<Card.Header>
								<h2 className='text-xl font-semibold'>Form Preview</h2>
							</Card.Header>
							<Card.Content>
								{currentForm &&
								formSections &&
								formFields &&
								fieldSectionMap ? (
									<FormPreview
										form={currentForm}
										sections={formSections}
										fields={formFields}
										fieldSectionMap={fieldSectionMap}
									/>
								) : (
									<div className='text-center py-8 text-gray-500'>
										<p>Loading preview data...</p>
									</div>
								)}
							</Card.Content>
						</Card>
					</Tabs.Content>
				)}
			</Tabs>

			{/* Field Editor Modal */}
			{showFieldEditor && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full'>
						<FormFieldEditor
							field={selectedField}
							fieldTypes={fieldTypes}
							onSave={handleSaveField}
							onCancel={() => setShowFieldEditor(false)}
							onDelete={
								selectedField ? () => handleDeleteField(selectedField.id) : null
							}
						/>
					</div>
				</div>
			)}

			{/* Section Editor Modal */}
			{showSectionEditor && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white p-6 rounded-lg shadow-lg max-w-md w-full'>
						<FormSectionEditor
							section={selectedSection}
							onSave={handleSaveSection}
							onCancel={() => setShowSectionEditor(false)}
							onDelete={
								selectedSection
									? () => handleDeleteSection(selectedSection.id)
									: null
							}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default FormBuilderEditor;
