import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ className = '', value, onValueChange, children, ...props }) => {
	const [activeTab, setActiveTab] = React.useState(value || '');

	React.useEffect(() => {
		if (value !== undefined) {
			setActiveTab(value);
		}
	}, [value]);

	const handleTabChange = (newValue) => {
		setActiveTab(newValue);
		if (onValueChange) {
			onValueChange(newValue);
		}
	};

	// Clone children and pass activeTab and handleTabChange
	const enhancedChildren = React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) return child;

		return React.cloneElement(child, {
			activeTab,
			onTabChange: handleTabChange,
		});
	});

	return (
		<div className={`${className}`} {...props}>
			{enhancedChildren}
		</div>
	);
};

const TabsList = ({
	className = '',
	children,
	activeTab,
	onTabChange,
	...props
}) => {
	// Clone children and pass activeTab and onTabChange
	const enhancedChildren = React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) return child;

		return React.cloneElement(child, {
			activeTab,
			onTabChange,
		});
	});

	return (
		<div
			className={`flex w-full border-b border-gray-200 ${className}`}
			{...props}>
			{enhancedChildren}
		</div>
	);
};

const TabsTrigger = ({
	className = '',
	value,
	activeTab,
	onTabChange,
	children,
	...props
}) => {
	const isActive = activeTab === value;

	const handleClick = () => {
		if (onTabChange) {
			onTabChange(value);
		}
	};

	return (
		<button
			className={`relative flex items-center justify-center whitespace-nowrap px-4 py-3 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${
				isActive ? 'text-primary' : 'text-gray-600 hover:text-gray-900'
			} ${className}`}
			onClick={handleClick}
			data-state={isActive ? 'active' : 'inactive'}
			{...props}>
			{children}
			{isActive && (
				<div className='absolute bottom-0 left-0 right-0 h-0.5 bg-primary transform transition-transform duration-200'></div>
			)}
		</button>
	);
};

const TabsContent = ({
	className = '',
	value,
	activeTab,
	children,
	...props
}) => {
	const isActive = activeTab === value;

	if (!isActive) return null;

	return (
		<div
			className={`animate-in fade-in-0 slide-in-from-bottom-1 duration-200 ${className}`}
			data-state={isActive ? 'active' : 'inactive'}
			{...props}>
			{children}
		</div>
	);
};

Tabs.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onValueChange: PropTypes.func,
	children: PropTypes.node,
};

TabsList.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	activeTab: PropTypes.string,
	onTabChange: PropTypes.func,
};

TabsTrigger.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string.isRequired,
	activeTab: PropTypes.string,
	onTabChange: PropTypes.func,
	children: PropTypes.node,
};

TabsContent.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string.isRequired,
	activeTab: PropTypes.string,
	children: PropTypes.node,
};

Tabs.List = TabsList;
Tabs.Trigger = TabsTrigger;
Tabs.Content = TabsContent;

export default Tabs;
