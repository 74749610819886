import React, { useState } from 'react';
import ServiceBg from '../../../assets/marketing/ServicesBgImg1.webp';
import { ServiceCardData } from '../ServicePage/ServiceCardData';
import package1 from '../../../assets/marketing/DrawingRoom.webp';
import package2 from '../../../assets/marketing/DrawingRoom2.webp';
import { PopupButton } from 'react-calendly';
import { CONTACT_US_CALENDLY_URL } from '../../../utils/constant/constants';
import { GoArrowLeft } from 'react-icons/go';
import { GoArrowRight } from 'react-icons/go';
import { useSelector } from 'react-redux';
// Import additional icons for service features
import {
	FaCheckCircle,
	FaBriefcase,
	FaPalette,
	FaEnvelope,
	FaHashtag,
	FaMailBulk,
	FaAd,
	FaHome,
	FaUsers,
	FaPlus,
	FaMapMarkerAlt,
	FaUserFriends,
	FaBullseye,
	FaChartLine,
	FaCalendarAlt,
	FaComments,
	FaCog,
} from 'react-icons/fa';

// Custom styles for text shadow
const styles = {
	shadowText: {
		textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
	},
};

function ServiceSectionNew() {
	const [expandedCard, setExpandedCard] = useState(null);

	// Toggle function to show/hide content
	const toggleReadMore = (id) => {
		setExpandedCard(expandedCard === id ? null : id);
	};

	const [showFullContent1, setShowFullContent1] = useState(false);
	const [showFullContent, setShowFullContent] = useState(false);

	// Service feature icons mapping
	const featureIcons = {
		business: <FaBriefcase className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		design: <FaPalette className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		email: <FaEnvelope className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		social: <FaHashtag className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		mail: <FaMailBulk className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		ads: <FaAd className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		listing: <FaHome className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		clients: <FaUsers className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		more: <FaPlus className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		check: <FaCheckCircle className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		location: (
			<FaMapMarkerAlt className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
		),
		audience: <FaUserFriends className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		target: <FaBullseye className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		analytics: <FaChartLine className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		calendar: <FaCalendarAlt className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		chat: <FaComments className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
		management: <FaCog className='text-[rgba(204,_29,_185,_0.8)] mr-2' />,
	};

	// Feature item component with icon
	const FeatureItem = ({ icon, text, indent = false }) => (
		<li
			className={`mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF] flex items-start ${
				indent ? 'ml-[30px]' : ''
			}`}>
			<span className='mt-1 flex-shrink-0'>{icon}</span>
			<span>{text}</span>
		</li>
	);

	return (
		<div className='pt-[80px]'>
			<div className='w-full h-[400px] sm:h-[400px] h-[300px] mx-[auto] relative'>
				<img
					src={ServiceBg}
					alt='ServiceBg'
					loading='lazy'
					className='w-full h-full object-cover rounded-none m-[0]'></img>

				<div
					className='absolute bottom-0 mb-[20px] ml-[80px] xl:mb-[30px] 
       tablet:mb-[15px] tablet:ml-[40px]
       mobile:mb-[10px] mobile:ml-[20px]
      '>
					<h1
						className='font-lejour text-[clamp(35px,_3vw,_70px)] font-normal leading-[70px] sm:leading-[70px] leading-[45px] text-left m-0 text-clientWhite
        '>
						Services
					</h1>
					<div className='w-[80%] max-w-[800px] tablet:w-[85%] mobile:w-[95%]'>
						<p
							className='font-glacial text-[20px] font-bold leading-[1.5] text-left m-0 text-clientWhite uppercase
             tablet:text-[15px]
             mobile:text-[14px]'>
							We handle all your marketing needs. Focus on selling properties
							while we deliver results-driven marketing strategies.
						</p>
					</div>
				</div>
			</div>

			{/* Main Services Section with improved comparison layout */}
			<div className='w-full mx-[auto] bg-[#F3F3F3]'>
				<div className='max-w-[1180px] mx-auto w-full px-[20px]'>
					<h3 className='font-canela-trial text-[35px] sm:text-[35px] text-[28px] pt-6 pb-3 mb-[35px] font-bold leading-[42px] sm:leading-[42px] leading-[34px] text-center text-clientBlack smallView:text-[24px] smallView:leading-[30px]'>
						Choose Your Marketing Package
					</h3>

					{/* Package comparison table header */}
					<div className='hidden md:flex justify-between mb-6 px-4'>
						<div className='w-1/2 text-center'>
							<h4 className='font-canela-trial text-[22px] font-medium uppercase text-clientBlack'>
								For Individual Agents
							</h4>
						</div>
						<div className='w-1/2 text-center'>
							<h4 className='font-canela-trial text-[22px] font-medium uppercase text-clientBlack'>
								For Brokerages and Teams
							</h4>
						</div>
					</div>
				</div>

				{/* Packages section with improved visual comparison */}
				<div className='bg-[#1E1E1E] mx-auto w-full md:mt-[280px] px-[30px] sm:px-[30px] px-[15px] md:pt-0 pt-[30px]'>
					<div className='flex justify-center md:flex-row flex-col mx-auto w-[fit-content] max-w-[1180px]'>
						{/* Premier Package */}
						<div className='md:mr-[20px] w-full max-w-[487px] relative'>
							{/* Mobile-only header */}
							<div className='md:hidden text-center mb-4'>
								<h4 className='font-canela-trial text-[20px] font-medium uppercase text-clientWhite'>
									For Individual Agents
								</h4>
							</div>

							<div className='bg-[#2A2A2A] rounded-t-[13px] border-t-4 border-[rgba(204,_29,_185,_0.7)]'>
								<img
									src={package1}
									alt='Premier Package'
									loading='lazy'
									className='w-full max-w-[487px] h-auto sm:h-auto h-[180px] rounded-t-[13px] rounded-b-none object-cover m-[0] md:mt-[-260px]'
								/>
								<div className='px-6 sm:px-6 px-4 py-4 flex flex-col h-full'>
									<div className='mt-[20px] w-full'>
										<p className='font-lejour text-[clamp(16px,_3vw,_22px)] font-normal leading-[34.2px] sm:leading-[34.2px] leading-[28px] uppercase m-0 text-clientWhite'>
											The Premier Package
											<span className='block font-glacial text-[clamp(16px,_3vw,_22px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_0.9)]'>
												$850
												<span className='font-bold text-clientWhite'>
													/month
												</span>
											</span>
										</p>
									</div>

									<div className='flex-grow mb-[10px] mt-[20px] h-auto w-full text-[#CFCFCF]'>
										<p className='mb-[20px] font-glacial text-[18px] sm:text-[18px] text-[16px] font-normal leading-[25.2px] sm:leading-[25.2px] leading-[22px] text-[#CFCFCF]'>
											Designed for real estate professionals seeking expert
											marketing support to grow their business.
										</p>

										<FeatureItem
											icon={featureIcons.business}
											text='Business consultation & strategic growth planning'
										/>
										<FeatureItem
											icon={featureIcons.design}
											text='Custom marketing design & execution'
										/>

										<div>
											{showFullContent1 ? (
												<React.Fragment>
													<FeatureItem
														icon={featureIcons.email}
														text='Email marketing campaigns that engage & convert'
													/>
													<FeatureItem
														icon={featureIcons.social}
														text='Targeted social media marketing'
													/>
													<FeatureItem
														icon={featureIcons.mail}
														text='Direct mail campaigns'
													/>
													<FeatureItem
														icon={featureIcons.ads}
														text='Digital advertising management'
													/>
													<FeatureItem
														icon={featureIcons.listing}
														text='Listing marketing, including:'
													/>

													<FeatureItem
														icon={featureIcons.check}
														text='Professionally designed listing flyers & social media posts'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Targeted listing ads'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Personalized client letters'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Property listing websites & marketing collateral'
														indent={true}
													/>

													<FeatureItem
														icon={featureIcons.clients}
														text='Post-close marketing to maintain client relationships:'
													/>

													<FeatureItem
														icon={featureIcons.check}
														text='Social media engagement'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Follow-up client letters'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Review request strategies'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Personalized client follow-ups'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='CRM management & optimization'
														indent={true}
													/>

													<FeatureItem
														icon={featureIcons.more}
														text='Additional custom design work & more'
													/>
												</React.Fragment>
											) : (
												''
											)}
										</div>

										<button
											type='button'
											onClick={() => setShowFullContent1(!showFullContent1)}
											className='flex items-center font-bold mt-[20px] text-clientWhite text-[14px] leading-[16.8px] cursor-pointer hover:text-[rgba(204,_29,_185,_0.8)] transition-colors'>
											{showFullContent1
												? 'VIEW LESS DETAILS'
												: 'VIEW ALL DETAILS'}

											{showFullContent1 ? (
												<GoArrowLeft className='text-[rgba(204,_29,_185,_0.8)] text-[20px] ml-2 mb-[2px]' />
											) : (
												<GoArrowRight className='text-[rgba(204,_29,_185,_0.8)] text-[20px] ml-2 mb-[2px]' />
											)}
										</button>

										<div className='mt-auto pt-6 flex justify-center'>
											<PopupButton
												className='font-glacial my-[18px] bg-[rgba(204,_29,_185,_0.8)] hover:bg-[rgba(204,_29,_185,_0.7)] transition-colors rounded-[6px] mb-[50px] h-[60px] sm:h-[60px] h-[50px] w-full text-[18px] sm:text-[18px] text-[16px] smallView:text-[15px] font-bold leading-[28px] text-clientWhite cursor-pointer'
												url={CONTACT_US_CALENDLY_URL}
												rootElement={document.getElementById('root')}
												text='Get Started with Premier Package'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Brokerage Package */}
						<div className='md:ml-[20px] w-full max-w-[487px] relative'>
							{/* Mobile-only header */}
							<div className='md:hidden text-center mb-4 mt-8'>
								<h4 className='font-canela-trial text-[20px] font-medium uppercase text-clientWhite'>
									For Brokerages and Teams
								</h4>
							</div>

							<div className='bg-[#2A2A2A] rounded-t-[13px] border-t-4 border-[rgba(204,_29,_185,_0.7)]'>
								<img
									src={package2}
									alt='Brokerage Marketing Package'
									loading='lazy'
									className='w-full max-w-[487px] h-auto sm:h-auto h-[180px] rounded-t-[13px] rounded-b-none object-cover m-[0] md:mt-[-260px]'
								/>
								<div className='px-6 sm:px-6 px-4 py-4 flex flex-col h-full'>
									<div className='mt-[20px] w-full'>
										<p className='font-lejour text-[clamp(16px,_3vw,_22px)] font-normal leading-[34.2px] sm:leading-[34.2px] leading-[28px] uppercase m-0 text-clientWhite'>
											Brokerage Marketing and Strategy
											<span className='block font-glacial text-[clamp(16px,_3vw,_22px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_0.9)]'>
												$1,650
												<span className='font-bold text-clientWhite'>
													/month +{' '}
												</span>
												<span className='font-glacial text-[clamp(16px,_3vw,_19px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_0.9)]'>
													$150
												</span>
												<span className='font-bold text-clientWhite'>
													/consultation
												</span>
											</span>
										</p>
									</div>

									<div className='flex-grow mb-[10px] mt-[20px] h-auto w-full text-[#CFCFCF]'>
										<p className='mb-[20px] font-glacial text-[18px] sm:text-[18px] text-[16px] font-normal leading-[25.2px] sm:leading-[25.2px] leading-[22px] text-[#CFCFCF]'>
											For boutique brokerages aiming to scale with a dedicated
											marketing director.
										</p>

										<FeatureItem
											icon={featureIcons.business}
											text='Marketing strategy assessment'
										/>
										<FeatureItem
											icon={featureIcons.design}
											text='Marketing director services'
										/>

										<div>
											{showFullContent ? (
												<React.Fragment>
													<FeatureItem
														icon={featureIcons.design}
														text='Development of new marketing content across multiple channels:'
													/>

													<FeatureItem
														icon={featureIcons.check}
														text='Email campaigns'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Social media marketing'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Direct mail outreach'
														indent={true}
													/>
													<FeatureItem
														icon={featureIcons.check}
														text='Digital ad strategy'
														indent={true}
													/>

													<FeatureItem
														icon={featureIcons.design}
														text='Branding refinement & creation of brokerage-wide marketing templates'
													/>
													<FeatureItem
														icon={featureIcons.clients}
														text='Agent growth strategy & marketing consultations'
													/>
												</React.Fragment>
											) : (
												''
											)}
										</div>

										<button
											type='button'
											onClick={() => setShowFullContent(!showFullContent)}
											className='flex items-center text-clientWhite text-[14px] font-bold mt-[20px] leading-[16.8px] cursor-pointer hover:text-[rgba(204,_29,_185,_0.8)] transition-colors'>
											{showFullContent
												? 'VIEW LESS DETAILS'
												: 'VIEW ALL DETAILS'}

											{showFullContent ? (
												<GoArrowLeft className='text-[rgba(204,_29,_185,_0.8)] text-[20px] ml-2 mb-[2px]' />
											) : (
												<GoArrowRight className='text-[rgba(204,_29,_185,_0.8)] text-[20px] ml-2 mb-[2px]' />
											)}
										</button>

										<div className='mt-auto pt-6 flex justify-center'>
											<PopupButton
												className='font-glacial my-[18px] bg-[rgba(204,_29,_185,_0.8)] hover:bg-[rgba(204,_29,_185,_0.7)] transition-colors rounded-[6px] mb-[50px] h-[60px] sm:h-[60px] h-[50px] w-full text-[18px] sm:text-[18px] text-[16px] smallView:text-[15px] font-bold leading-[28px] text-clientWhite cursor-pointer'
												url={CONTACT_US_CALENDLY_URL}
												rootElement={document.getElementById('root')}
												text='Grow Your Brokerage with a Marketing Director'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Additional Services Section with improved cards */}
			<div className='w-full mx-[auto] bg-[#F1F1F1]'>
				<div className='max-w-[1180px] mx-auto w-full p-[20px]'>
					<h3 className='font-canela-trial text-[35px] sm:text-[35px] text-[28px] my-[20px] mb-[35px] font-bold leading-[42px] sm:leading-[42px] leading-[34px] text-center text-clientBlack'>
						Additional Services
					</h3>
					<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-6 gap-4 w-full mx-auto'>
						{ServiceCardData.length > 0 &&
							ServiceCardData?.map((service, index) => {
								// Determine service description based on the service type
								let serviceDescription = '';
								let serviceIcon = null;

								if (service.headingStart.includes('Digital Billboards')) {
									serviceDescription =
										'Keep your brand in front of potential clients with strategic online ads.';
									serviceIcon = (
										<FaAd className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else if (service.headingStart.includes('Social Media')) {
									serviceDescription =
										'Grow your brand, engage your audience, and increase visibility.';
									serviceIcon = (
										<FaHashtag className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else if (service.headingStart.includes('Email Marketing')) {
									serviceDescription =
										'Stay top-of-mind with professionally designed email campaigns.';
									serviceIcon = (
										<FaEnvelope className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else if (service.headingStart.includes('Direct Mail')) {
									serviceDescription =
										'Send high-quality mailers to keep in touch with your audience.';
									serviceIcon = (
										<FaMailBulk className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else if (service.headingStart.includes('Listing')) {
									serviceDescription =
										'We help you market your listings and provide you with material that impresses your clients.';
									serviceIcon = (
										<FaHome className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else if (
									service.headingStart.includes('Business Consultation')
								) {
									serviceDescription =
										'Get expert guidance on growing & organizing your real estate business.';
									serviceIcon = (
										<FaBriefcase className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								} else {
									serviceDescription =
										'Professional marketing services tailored to your needs.';
									serviceIcon = (
										<FaBriefcase className='text-[rgba(204,_29,_185,_0.8)] mr-2' />
									);
								}

								return (
									<div
										key={index}
										className='overflow-hidden bg-white rounded-[13px] shadow-md hover:shadow-lg transition-shadow duration-300 border-2 border-gray-300'>
										<div className='relative'>
											<img
												src={service.image}
												alt={service.title}
												loading='lazy'
												className='w-full h-[200px] sm:h-[200px] h-[160px] object-cover m-0 rounded-t-[13px] rounded-b-none'
											/>
										</div>

										<div className='bg-[#3A3A3A] py-3 px-4 text-center h-[70px] flex items-center justify-center'>
											<h3 className='font-lejour text-[20px] sm:text-[20px] text-[18px] font-normal leading-[24px] sm:leading-[24px] leading-[22px] uppercase m-0 text-white'>
												{service.headingStart}
											</h3>
										</div>

										<div className='px-6 py-4'>
											<div className='mb-4 text-center'>
												<div className='bg-gray-100 py-2 px-3 rounded-lg inline-block mb-3'>
													<p className='font-glacial text-[28px] font-bold leading-[32px] m-0 text-[rgba(204,_29,_185,_0.8)]'>
														{service.headingMiddle}
														<span className='font-bold text-[#3A3A3A]'>
															/{service.headingEnd}
														</span>
													</p>
												</div>
												<p className='text-gray-600 text-[16px] min-h-[48px]'>
													{serviceDescription}
												</p>
											</div>

											<div className='mt-4 h-auto'>
												{expandedCard === service.id ? (
													<div className='bg-gray-50 p-4 rounded-lg'>
														<ul className='list-none p-0 m-0'>
															{service.listingPint.map((listItem, idx) => {
																// Check if this is a header item (ends with a colon)
																const isHeader =
																	listItem.includes('</b>:') ||
																	listItem.includes('</b>');
																// Check if this is a sub-item (starts with an emoji)
																const isSubItem =
																	listItem.startsWith('📍') ||
																	listItem.startsWith('🎯') ||
																	listItem.startsWith('👥') ||
																	listItem.startsWith('🎞️') ||
																	listItem.startsWith('📅') ||
																	listItem.startsWith('🚀') ||
																	listItem.startsWith('🔗') ||
																	listItem.startsWith('🎨') ||
																	listItem.startsWith('📬') ||
																	listItem.startsWith('📆') ||
																	listItem.startsWith('🗓️') ||
																	listItem.startsWith('💬') ||
																	listItem.startsWith('✅');

																return (
																	<li
																		className={`flex items-start mb-3 font-glacial text-[16px] font-normal leading-[23.84px] text-clientDarkCharcoal ${
																			isSubItem ? 'ml-6' : ''
																		} ${isHeader ? 'mt-2' : ''}`}
																		key={idx}>
																		{!isSubItem && (
																			<FaCheckCircle className='text-[rgba(204,_29,_185,_0.8)] mr-2 mt-1 flex-shrink-0' />
																		)}
																		<span
																			dangerouslySetInnerHTML={{
																				__html: listItem,
																			}}></span>
																	</li>
																);
															})}
														</ul>
														<div className='flex justify-center mt-4'>
															<button
																type='button'
																className='mt-4 px-6 py-2 text-[15px] font-bold leading-[15px] border border-[rgba(204,_29,_185,_0.8)] text-[rgba(204,_29,_185,_0.8)] rounded-[4px] hover:bg-[rgba(204,_29,_185,_0.1)] transition-colors'
																onClick={() => toggleReadMore(service.id)}
																aria-label='Show less details'
																tabIndex={0}>
																View Less
															</button>
														</div>
													</div>
												) : (
													<div>
														<div className='h-[80px] overflow-hidden bg-gray-50 p-4 rounded-lg'>
															<ul className='list-none p-0 m-0'>
																{service.listingPint
																	.slice(0, 2)
																	.map((listItem, idx) => {
																		// Check if this is a header item (ends with a colon)
																		const isHeader =
																			listItem.includes('</b>:') ||
																			listItem.includes('</b>');
																		// Check if this is a sub-item (starts with an emoji)
																		const isSubItem =
																			listItem.startsWith('📍') ||
																			listItem.startsWith('🎯') ||
																			listItem.startsWith('👥') ||
																			listItem.startsWith('🎞️') ||
																			listItem.startsWith('📅') ||
																			listItem.startsWith('🚀') ||
																			listItem.startsWith('🔗') ||
																			listItem.startsWith('🎨') ||
																			listItem.startsWith('📬') ||
																			listItem.startsWith('📆') ||
																			listItem.startsWith('🗓️') ||
																			listItem.startsWith('💬') ||
																			listItem.startsWith('✅');

																		return (
																			<li
																				className={`flex items-start mb-3 font-glacial text-[16px] font-normal leading-[23.84px] text-clientDarkCharcoal ${
																					isSubItem ? 'ml-6' : ''
																				} ${isHeader ? 'mt-2' : ''}`}
																				key={idx}>
																				{!isSubItem && (
																					<FaCheckCircle className='text-[rgba(204,_29,_185,_0.8)] mr-2 mt-1 flex-shrink-0' />
																				)}
																				<span
																					dangerouslySetInnerHTML={{
																						__html: listItem,
																					}}></span>
																			</li>
																		);
																	})}
															</ul>
														</div>
														<div className='flex justify-center mt-4'>
															<button
																type='button'
																className='mt-4 px-6 py-2 text-[15px] font-bold leading-[15px] bg-[rgba(204,_29,_185,_0.8)] text-white rounded-[4px] hover:bg-[rgba(204,_29,_185,_0.7)] transition-colors'
																onClick={() => toggleReadMore(service.id)}
																aria-label='Show more details'
																tabIndex={0}>
																View Details
															</button>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								);
							})}
					</div>

					<div className='text-center my-16'>
						<PopupButton
							className='h-[60px] w-full max-w-[298px] bg-[rgba(204,_29,_185,_0.8)] hover:bg-[rgba(204,_29,_185,_0.7)] transition-colors text-[18px] font-bold leading-[25.2px] text-clientWhite rounded-[6px]'
							url={CONTACT_US_CALENDLY_URL}
							rootElement={document.getElementById('root')}
							text='Schedule a Free Consultation'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServiceSectionNew;
