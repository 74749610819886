import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ className = '', ...props }) => {
	return (
		<div
			className={`rounded-sm border border-gray-100 bg-white text-gray-700 shadow-sm hover:shadow-md transition-shadow duration-200 ${className}`}
			{...props}
		/>
	);
};

const CardHeader = ({ className = '', ...props }) => {
	return (
		<div className={`flex flex-col space-y-1 p-6 ${className}`} {...props} />
	);
};

const CardTitle = ({ className = '', ...props }) => {
	return (
		<h3
			className={`text-lg font-montserrat-medium text-gray-900 ${className}`}
			{...props}
		/>
	);
};

const CardDescription = ({ className = '', ...props }) => {
	return (
		<p
			className={`text-sm font-montserrat-regular text-gray-500 mt-1 ${className}`}
			{...props}
		/>
	);
};

const CardContent = ({ className = '', ...props }) => {
	return <div className={`p-6 pt-0 ${className}`} {...props} />;
};

const CardFooter = ({ className = '', ...props }) => {
	return (
		<div className={`flex items-center p-6 pt-0 ${className}`} {...props} />
	);
};

// Luxury variant with gold accents
const LuxuryCard = ({ className = '', ...props }) => {
	return (
		<div
			className={`rounded-sm border border-clientLightYellow/30 bg-white text-gray-700 shadow-sm hover:shadow-md transition-shadow duration-200 ${className}`}
			{...props}
		/>
	);
};

// Dark variant for contrast
const DarkCard = ({ className = '', ...props }) => {
	return (
		<div
			className={`rounded-sm border border-gray-800 bg-clientBlack text-white shadow-sm hover:shadow-md transition-shadow duration-200 ${className}`}
			{...props}
		/>
	);
};

Card.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

CardHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

CardTitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

CardDescription.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

CardContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

CardFooter.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

LuxuryCard.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

DarkCard.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Description = CardDescription;
Card.Content = CardContent;
Card.Footer = CardFooter;
Card.Luxury = LuxuryCard;
Card.Dark = DarkCard;

export default Card;
