import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateUser, getAllUsers } from '../../store/user.js';
import { FiSave, FiX, FiEye, FiEyeOff } from 'react-icons/fi';
import './Client.css';

const ClientBusinessInfoForm = ({ user, setEdit }) => {
	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);
	const { username } = useParams();

	// State for showing/hiding sensitive data
	const [showData, setShowData] = useState({
		crmUsername: false,
		crmPassword: false,
	});

	// Form setup with react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			phone: user.phone || '',
			designations: user.designations || '',
			certifications: user.certifications || '',
			specialties: user.specialties || '',
			officeAddressOne: user.officeAddressOne || '',
			officeAddressTwo: user.officeAddressTwo || '',
			officeCity: user.officeCity || '',
			officeState: user.officeState || '',
			officeZip: user.officeZip || '',
			mlsInfo: user.mlsInfo || '',
			websiteUrl: user.websiteUrl || '',
			googleBusinessUrl: user.googleBusinessUrl || '',
			crmName: user.crmName || '',
			crmUsername: user.crmUsername || '',
			crmPassword: user.crmPassword || '',
		},
	});

	// Toggle visibility of sensitive data
	const toggleDataVisibility = (field) => {
		setShowData((prev) => ({
			...prev,
			[field]: !prev[field],
		}));
	};

	// Submit form data
	const onSubmit = async (formData) => {
		try {
			await dispatch(
				updateUser({
					username,
					data: formData,
					token,
				})
			).unwrap();

			// Refresh user data
			dispatch(getAllUsers({ token }));

			// Return to view mode
			setEdit(false);
		} catch (error) {
			console.error('Error updating user:', error);
		}
	};

	// Form field component for consistent styling
	const FormField = ({
		label,
		name,
		type = 'text',
		required = false,
		placeholder = '',
		children,
	}) => {
		return (
			<div className='mb-4'>
				<label
					htmlFor={name}
					className='block text-sm font-montserrat-medium text-gray-700 mb-1'>
					{label} {required && <span className='text-red-500'>*</span>}
				</label>
				{children || (
					<input
						id={name}
						type={type}
						{...register(name, {
							required: required ? `${label} is required` : false,
						})}
						className={`w-full px-3 py-2 border ${
							errors[name] ? 'border-red-500' : 'border-gray-300'
						} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 font-montserrat-regular`}
						placeholder={placeholder}
					/>
				)}
				{errors[name] && (
					<p className='mt-1 text-sm text-red-600 font-montserrat-regular'>
						{errors[name].message}
					</p>
				)}
			</div>
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
			<div className='flex justify-between items-center mb-6'>
				<h2 className='text-xl font-glacial font-semibold text-gray-900'>
					Edit Business Information
				</h2>
				<div className='flex space-x-2'>
					<button
						type='button'
						onClick={() => setEdit(false)}
						className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-montserrat-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
						<FiX className='mr-2 h-4 w-4' />
						Cancel
					</button>
					<button
						type='submit'
						disabled={isSubmitting}
						className='inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-montserrat-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
						<FiSave className='mr-2 h-4 w-4' />
						Save Changes
					</button>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100 mb-6'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					Basic Information
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='Primary Phone'
						name='phone'
						placeholder='(555) 123-4567'
					/>
					<FormField
						label='Designations'
						name='designations'
						placeholder='e.g., CRS, ABR, GRI'
					/>
					<FormField
						label='Certifications'
						name='certifications'
						placeholder='e.g., Certified Luxury Home Marketing Specialist'
					/>
					<FormField
						label='Real Estate Specialties'
						name='specialties'
						placeholder='e.g., Residential, Commercial, Luxury'
					/>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100 mb-6'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					Office Location
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='Address Line 1'
						name='officeAddressOne'
						placeholder='Street address'
					/>
					<FormField
						label='Address Line 2'
						name='officeAddressTwo'
						placeholder='Apt, Suite, etc. (optional)'
					/>
					<FormField label='City' name='officeCity' placeholder='City' />
					<FormField label='State' name='officeState' placeholder='State' />
					<FormField label='Zip Code' name='officeZip' placeholder='Zip Code' />
					<FormField
						label='MLS Association'
						name='mlsInfo'
						placeholder='MLS Association'
					/>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100 mb-6'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					Online Presence
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='Website URL'
						name='websiteUrl'
						placeholder='https://example.com'
					/>
					<FormField
						label='Google Business Page'
						name='googleBusinessUrl'
						placeholder='https://business.google.com/...'
					/>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					CRM Information
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='CRM Name'
						name='crmName'
						placeholder='e.g., Salesforce, HubSpot'
					/>

					<FormField label='CRM Login Username' name='crmUsername'>
						<div className='relative'>
							<input
								id='crmUsername'
								type={showData.crmUsername ? 'text' : 'password'}
								{...register('crmUsername')}
								className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 pr-10 font-montserrat-regular'
								placeholder='CRM username'
							/>
							<button
								type='button'
								className='absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700'
								onClick={() => toggleDataVisibility('crmUsername')}>
								{showData.crmUsername ? (
									<FiEyeOff className='h-4 w-4' />
								) : (
									<FiEye className='h-4 w-4' />
								)}
							</button>
						</div>
					</FormField>

					<FormField label='CRM Password' name='crmPassword'>
						<div className='relative'>
							<input
								id='crmPassword'
								type={showData.crmPassword ? 'text' : 'password'}
								{...register('crmPassword')}
								className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 pr-10 font-montserrat-regular'
								placeholder='CRM password'
							/>
							<button
								type='button'
								className='absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700'
								onClick={() => toggleDataVisibility('crmPassword')}>
								{showData.crmPassword ? (
									<FiEyeOff className='h-4 w-4' />
								) : (
									<FiEye className='h-4 w-4' />
								)}
							</button>
						</div>
					</FormField>
				</div>
			</div>
		</form>
	);
};

export default ClientBusinessInfoForm;
