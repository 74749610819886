/** @format */
import React, { useState } from 'react';
import AdminInfoForm from './AdminInfoForm.js';
import { FiEdit2, FiTag, FiFileText } from 'react-icons/fi';
import './Client.css';

const AdminInfo = ({ user }) => {
	const [edit, setEdit] = useState(false);

	// Function to render a field with icon
	const renderField = (label, value, icon) => (
		<div className='bg-white rounded-sm p-4 border border-gray-100 hover:border-gray-200 hover:shadow-sm transition-all'>
			<div className='flex items-center mb-2'>
				{React.cloneElement(icon, { className: 'h-4 w-4 text-gray-900' })}
				<h3 className='text-sm font-montserrat-medium text-gray-900 ml-2'>
					{label}
				</h3>
			</div>
			<div className='text-gray-700 font-montserrat-regular'>
				{value ? (
					typeof value === 'string' ? (
						<p className='whitespace-pre-wrap'>{value}</p>
					) : (
						value
					)
				) : (
					<span className='text-gray-400 italic'>Not provided</span>
				)}
			</div>
		</div>
	);

	// Render hashtags with styling
	const renderHashtags = (hashtags) => {
		if (!hashtags) return null;

		const tags = hashtags
			.split(',')
			.map((tag) => tag.trim())
			.filter((tag) => tag);

		if (tags.length === 0) return null;

		return (
			<div className='flex flex-wrap gap-2 mt-1'>
				{tags.map((tag, index) => (
					<span
						key={index}
						className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-montserrat-medium bg-gray-100 text-gray-900'>
						#{tag}
					</span>
				))}
			</div>
		);
	};

	if (edit) {
		return <AdminInfoForm user={user} setEdit={setEdit} />;
	}

	return (
		<div className='space-y-6'>
			<div className='flex justify-between items-center mb-4'>
				<h2 className='text-lg font-glacial text-gray-900'>
					Admin Information
				</h2>
				<button
					onClick={() => setEdit(true)}
					className='flex items-center justify-center h-9 w-9 rounded-sm bg-gray-100 text-gray-900 hover:bg-gray-200 transition-colors'
					aria-label='Edit admin information'>
					<FiEdit2 className='h-4 w-4' />
				</button>
			</div>

			<div className='grid grid-cols-1 gap-6'>
				{renderField('Admin Notes', user.adminNotes, <FiFileText />)}
				{renderField('Hashtags', renderHashtags(user.hashtags), <FiTag />)}
			</div>
		</div>
	);
};

export default AdminInfo;
