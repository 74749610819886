import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import FormBuilderList from './FormBuilderList';
import FormBuilderEditor from './FormBuilderEditor';

const FormBuilder = () => {
	const { path } = useRouteMatch();
	const location = useLocation();

	console.log('FormBuilder component mounted. Path:', path);
	console.log('Current location:', location.pathname);

	useEffect(() => {
		console.log('FormBuilder paths - base path:', path);
		console.log('FormBuilder paths - create path:', `${path}/create`);
		console.log(
			'FormBuilder paths - checking if current location matches:',
			location.pathname,
			location.pathname.includes('/create'),
			location.pathname === '/admin/form-builder/create'
		);
	}, [path, location]);

	return (
		<div>
			<Switch>
				<Route exact path='/admin/form-builder'>
					{console.log('Rendering FormBuilderList route')}
					<FormBuilderList />
				</Route>
				<Route exact path='/admin/form-builder/create'>
					{console.log(
						'Rendering FormBuilderEditor create route - using absolute path'
					)}
					<FormBuilderEditor />
				</Route>
				<Route path='/admin/form-builder/edit/:id'>
					{console.log(
						'Rendering FormBuilderEditor edit route - using absolute path'
					)}
					<FormBuilderEditor />
				</Route>
				<Route path='/admin/form-builder/view/:id'>
					{console.log(
						'Rendering FormBuilderEditor view route - using absolute path'
					)}
					<FormBuilderEditor />
				</Route>
			</Switch>
		</div>
	);
};

export default FormBuilder;
