import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../config';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Avatar from '../../components/ui/avatar';
import {
	FiX,
	FiPlus,
	FiCreditCard,
	FiCalendar,
	FiAlertCircle,
	FiCheckCircle,
	FiInfo,
	FiRefreshCw,
	FiExternalLink,
} from 'react-icons/fi';
import Moment from 'react-moment';
import SublyApi from '../../helpers/Api.js';
import { useToast } from '../../components/ui/toast';

// Make sure to call `loadStripe` outside of a component's render to avoid re-renders
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const ManageSubscriptionsModal = ({
	user,
	subscriptions = [],
	availablePrices = [],
	onClose,
	onSubscriptionChange,
	token,
}) => {
	const { addToast } = useToast();
	const [loading, setLoading] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [showAddSubscription, setShowAddSubscription] = useState(false);
	const [filteredPrices, setFilteredPrices] = useState([]);

	// Filter available prices to exclude ones the user already has
	useEffect(() => {
		if (!Array.isArray(availablePrices) || !Array.isArray(subscriptions)) {
			setFilteredPrices([]);
			return;
		}

		if (availablePrices.length > 0) {
			// Sort prices from highest to lowest
			const sortedPrices = [...availablePrices].sort(
				(a, b) => b.unit_amount - a.unit_amount
			);

			// Filter out prices for products the user is already subscribed to
			const filtered = sortedPrices.filter((price) => {
				if (!price.active) return false; // Remove inactive prices

				// Check if user is already subscribed to this product
				for (let sub of subscriptions) {
					if (sub?.items?.data?.[0]?.price?.product === price.product?.id) {
						return false;
					}
				}

				return true;
			});

			setFilteredPrices(filtered);
		} else {
			setFilteredPrices([]);
		}
	}, [availablePrices, subscriptions]);

	// Get subscription name from various possible locations in the object
	const getSubscriptionName = (subscription) => {
		// Check all possible locations for the product name
		return (
			subscription?.items?.data?.[0]?.price?.product?.name || // Nested in items
			subscription?.productName || // Direct property
			'Unnamed Subscription' // Fallback
		);
	};

	// Get subscription price from various possible locations in the object
	const getSubscriptionPrice = (subscription) => {
		if (subscription?.items?.data?.[0]?.price?.unit_amount) {
			return (subscription.items.data[0].price.unit_amount / 100).toFixed(2);
		} else if (subscription?.price) {
			return (subscription.price / 100).toFixed(2);
		}
		return null;
	};

	// Handle adding a new subscription
	const handleAddSubscription = async (priceId) => {
		if (!user?.customerId) {
			addToast({
				title: 'Error',
				description: 'Customer ID is missing. Cannot create subscription.',
				type: 'error',
			});
			return;
		}

		setLoading(true);
		try {
			const stripe = await stripePromise;

			// Create checkout session
			const response = await SublyApi.createCheckoutSession(
				{ priceId, customerId: user.customerId },
				token
			);

			const sessionId = response.sessionId;

			// Redirect to Stripe checkout
			const result = await stripe.redirectToCheckout({
				sessionId: sessionId,
			});

			if (result.error) {
				setLoading(false);
				addToast({
					title: 'Error',
					description: 'Failed to redirect to checkout. Please try again.',
					type: 'error',
				});
			}
		} catch (err) {
			console.error('Checkout error:', err);
			setLoading(false);
			addToast({
				title: 'Error',
				description: 'Failed to create checkout session. Please try again.',
				type: 'error',
			});
		}
	};

	// Handle opening Stripe customer portal for subscription management
	const handleOpenCustomerPortal = async () => {
		if (!user?.customerId) {
			addToast({
				title: 'Error',
				description: 'Customer ID is missing. Cannot open customer portal.',
				type: 'error',
			});
			return;
		}

		setLoading(true);
		try {
			const portalSessionUrl = await SublyApi.customerPortal(
				user.customerId,
				token
			);
			window.open(portalSessionUrl, '_blank');
			setLoading(false);
		} catch (err) {
			console.error('Portal error:', err);
			setLoading(false);
			addToast({
				title: 'Error',
				description: 'Failed to open customer portal. Please try again.',
				type: 'error',
			});
		}
	};

	// Handle refreshing subscriptions
	const handleRefreshSubscriptions = async () => {
		if (!user?.customerId) {
			addToast({
				title: 'Error',
				description: 'Customer ID is missing. Cannot refresh subscriptions.',
				type: 'error',
			});
			return;
		}

		setRefreshing(true);
		try {
			// Call the parent component's refresh function
			await onSubscriptionChange();

			// No need to set subscriptions here as the parent component will update the prop
			setRefreshing(false);
		} catch (err) {
			console.error('Refresh error:', err);
			setRefreshing(false);
			addToast({
				title: 'Error',
				description: 'Failed to refresh subscriptions. Please try again.',
				type: 'error',
			});
		}
	};

	// Handle toggling subscription options
	const handleToggleSubscriptionOptions = () => {
		setShowAddSubscription(!showAddSubscription);
	};

	// Get subscription status badge
	const getSubscriptionBadge = (subscription) => {
		if (!subscription) return null;

		// Check if canceled at period end but still active
		if (subscription.cancel_at_period_end) {
			return (
				<div className='inline-flex items-center justify-center text-orange-600 bg-orange-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
					<FiAlertCircle className='mr-1 h-3 w-3 flex-shrink-0' />
					<span>Canceling</span>
				</div>
			);
		}

		// Handle based on status
		switch (subscription.status) {
			case 'active':
				return (
					<div className='inline-flex items-center justify-center text-green-600 bg-green-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiCheckCircle className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>Active</span>
					</div>
				);
			case 'canceled':
				return (
					<div className='inline-flex items-center justify-center text-red-600 bg-red-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiAlertCircle className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>Canceled</span>
					</div>
				);
			default:
				return (
					<div className='inline-flex items-center justify-center text-blue-600 bg-blue-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiInfo className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>{subscription.status || 'Unknown'}</span>
					</div>
				);
		}
	};

	// Get user's full name safely
	const getUserFullName = () => {
		const firstName = user?.firstName || '';
		const lastName = user?.lastName || '';
		return firstName || lastName ? `${firstName} ${lastName}`.trim() : 'User';
	};

	const modalContent = (
		<div className='fixed inset-0 bg-black bg-opacity-75 z-[99999] flex items-center justify-center p-4'>
			<div className='bg-white rounded-md shadow-xl max-w-4xl w-full max-h-[90vh] overflow-hidden flex flex-col relative'>
				{/* Header */}
				<div className='flex justify-between items-center p-4 border-b border-gray-200'>
					<div className='flex items-center'>
						<Avatar className='h-10 w-10 mr-3'>
							<Avatar.Image src={user.headshot} alt={getUserFullName()} />
							<Avatar.Fallback>
								{user.firstName && user.lastName
									? `${user.firstName[0]}${user.lastName[0]}`
									: 'U'}
							</Avatar.Fallback>
						</Avatar>
						<h2 className='text-xl font-montserrat-medium text-gray-900 flex items-center'>
							<FiCreditCard className='mr-2 h-5 w-5 text-primary' />
							Manage Subscriptions for {getUserFullName()}
						</h2>
					</div>
					<Button
						variant='ghost'
						size='sm'
						onClick={onClose}
						aria-label='Close modal'>
						<FiX className='h-5 w-5' />
					</Button>
				</div>

				{/* Content */}
				<div className='flex-1 overflow-auto p-4'>
					{/* Current Subscriptions */}
					<div className='mb-6'>
						<div className='flex justify-between items-center mb-4'>
							<h3 className='text-lg font-montserrat-medium text-gray-900'>
								Current Subscriptions
							</h3>
							<div className='flex space-x-2'>
								<Button
									variant='ghost'
									size='sm'
									onClick={handleRefreshSubscriptions}
									disabled={refreshing}>
									<FiRefreshCw
										className={`mr-2 h-4 w-4 ${
											refreshing ? 'animate-spin' : ''
										}`}
									/>
									{refreshing ? 'Refreshing...' : 'Refresh'}
								</Button>
								<Button
									variant='secondary'
									size='sm'
									onClick={handleOpenCustomerPortal}>
									<FiExternalLink className='mr-2 h-4 w-4' />
									Stripe Portal
								</Button>
							</div>
						</div>

						{refreshing ? (
							<Card>
								<Card.Content className='text-center p-6'>
									<div className='animate-spin h-8 w-8 mx-auto mb-2 border-4 border-primary border-t-transparent rounded-full'></div>
									<p className='text-gray-500'>Refreshing subscriptions...</p>
								</Card.Content>
							</Card>
						) : Array.isArray(subscriptions) && subscriptions.length > 0 ? (
							<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
								{subscriptions.map((subscription, index) => (
									<Card key={index}>
										<Card.Content>
											<div className='flex flex-col'>
												<div className='font-medium text-gray-900'>
													{getSubscriptionName(subscription)}
												</div>
												<div className='flex items-center justify-between mt-1'>
													<div className='text-sm text-gray-500 flex items-center'>
														<FiCalendar className='mr-1 h-3 w-3 flex-shrink-0' />
														{subscription?.created && (
															<Moment format='LL'>
																{subscription.created * 1000}
															</Moment>
														)}
													</div>
													<div className='flex-shrink-0'>
														{getSubscriptionBadge(subscription)}
													</div>
												</div>
												{subscription?.cancel_at_period_end && (
													<div className='text-xs text-orange-600 mt-1'>
														Will be canceled at the end of the billing period
													</div>
												)}
												{getSubscriptionPrice(subscription) && (
													<div className='mt-2 text-sm text-gray-700'>
														${getSubscriptionPrice(subscription)}/month
													</div>
												)}
											</div>
										</Card.Content>
									</Card>
								))}
							</div>
						) : (
							<Card>
								<Card.Content className='text-center p-6'>
									<FiCreditCard className='h-8 w-8 mx-auto mb-2 text-gray-400' />
									<p className='text-gray-500'>No active subscriptions</p>
								</Card.Content>
							</Card>
						)}
					</div>

					{/* Add Subscription Section */}
					<div className='mt-8'>
						<div className='flex justify-between items-center mb-4'>
							<h3 className='text-lg font-montserrat-medium text-gray-900'>
								Add Subscription
							</h3>
							<Button
								variant={showAddSubscription ? 'secondary' : 'primary'}
								size='sm'
								onClick={handleToggleSubscriptionOptions}>
								{showAddSubscription ? (
									'Hide Options'
								) : (
									<>
										<FiPlus className='mr-2 h-4 w-4' />
										Add Subscription
									</>
								)}
							</Button>
						</div>

						{showAddSubscription && (
							<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
								{filteredPrices.length > 0 ? (
									filteredPrices.map((price) => {
										const isGoldPackage =
											price?.product?.name === 'The Gold Package';
										return (
											<Card
												key={price.id}
												className={
													isGoldPackage
														? 'border-2 border-clientLightYellow'
														: ''
												}>
												<Card.Content>
													<div className='font-medium text-gray-900 mb-2'>
														{price?.product?.name || 'Unnamed Product'}
													</div>
													<div className='text-sm text-gray-500 mb-2'>
														{price?.nickname || 'Standard Plan'}
													</div>
													<div className='text-lg font-montserrat-medium text-gray-900 mb-4'>
														${((price?.unit_amount || 0) / 100).toFixed(2)}
														/month
													</div>
													<div className='text-sm text-gray-600 mb-4 line-clamp-3'>
														{price?.product?.description ||
															'Premium service for real estate professionals'}
													</div>
													<Button
														variant={isGoldPackage ? 'gold' : 'primary'}
														className='w-full'
														onClick={() => handleAddSubscription(price.id)}
														disabled={loading}>
														Subscribe
													</Button>
												</Card.Content>
											</Card>
										);
									})
								) : (
									<Card className='col-span-full'>
										<Card.Content className='text-center p-6'>
											<p className='text-gray-500'>
												No additional subscription options available
											</p>
										</Card.Content>
									</Card>
								)}
							</div>
						)}
					</div>
				</div>

				{/* Loading Overlay */}
				{loading && (
					<div className='absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center'>
						<div className='flex flex-col items-center'>
							<div className='animate-spin h-10 w-10 border-4 border-primary border-t-transparent rounded-full mb-4'></div>
							<p className='text-gray-700 font-montserrat-medium'>
								Processing...
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);

	return createPortal(modalContent, document.body);
};

export default ManageSubscriptionsModal;
