import React from 'react';
import PropTypes from 'prop-types';

const Label = React.forwardRef(
	({ className = '', htmlFor, required = false, ...props }, ref) => {
		return (
			<label
				ref={ref}
				htmlFor={htmlFor}
				className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${className}`}
				{...props}>
				{props.children}
				{required && <span className='text-red-500 ml-1'>*</span>}
			</label>
		);
	}
);

Label.displayName = 'Label';

Label.propTypes = {
	className: PropTypes.string,
	htmlFor: PropTypes.string,
	required: PropTypes.bool,
	children: PropTypes.node,
};

export default Label;
