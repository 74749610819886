import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';

// Create a context for the toast
const ToastContext = createContext(null);

// Toast provider component
export const ToastProvider = ({ children }) => {
	const [toasts, setToasts] = useState([]);

	const addToast = useCallback((toast) => {
		const id = Math.random().toString(36).substring(2, 9);
		setToasts((prevToasts) => [...prevToasts, { ...toast, id }]);
		return id;
	}, []);

	const removeToast = useCallback((id) => {
		setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
	}, []);

	const value = {
		toasts,
		addToast,
		removeToast,
	};

	return (
		<ToastContext.Provider value={value}>
			{children}
			<ToastContainer />
		</ToastContext.Provider>
	);
};

ToastProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

// Hook to use the toast
export const useToast = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error('useToast must be used within a ToastProvider');
	}
	return context;
};

// Toast container component
const ToastContainer = () => {
	const { toasts, removeToast } = useToast();

	return (
		<div className='fixed bottom-0 right-0 z-50 flex flex-col gap-2 p-4 max-w-md w-full'>
			{toasts.map((toast) => (
				<Toast
					key={toast.id}
					{...toast}
					onClose={() => removeToast(toast.id)}
				/>
			))}
		</div>
	);
};

// Individual toast component
const Toast = ({
	id,
	title,
	description,
	type = 'default',
	duration = 5000,
	onClose,
}) => {
	useEffect(() => {
		if (duration) {
			const timer = setTimeout(() => {
				onClose();
			}, duration);
			return () => clearTimeout(timer);
		}
	}, [duration, onClose]);

	const typeClasses = {
		default: 'bg-white border-gray-200',
		success: 'bg-green-50 border-green-200',
		error: 'bg-red-50 border-red-200',
		warning: 'bg-yellow-50 border-yellow-200',
		info: 'bg-blue-50 border-blue-200',
	};

	return (
		<div
			className={`rounded-lg border p-4 shadow-md transition-all ${typeClasses[type]} animate-in slide-in-from-right-full`}
			role='alert'>
			<div className='flex justify-between items-start'>
				<div>
					{title && <div className='font-medium'>{title}</div>}
					{description && (
						<div className='text-sm text-gray-500 mt-1'>{description}</div>
					)}
				</div>
				<button
					onClick={onClose}
					className='ml-4 inline-flex h-6 w-6 items-center justify-center rounded-md text-gray-500 hover:bg-gray-100'>
					<span className='sr-only'>Close</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-4 w-4'
						viewBox='0 0 20 20'
						fill='currentColor'>
						<path
							fillRule='evenodd'
							d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
							clipRule='evenodd'
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

Toast.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
	type: PropTypes.oneOf(['default', 'success', 'error', 'warning', 'info']),
	duration: PropTypes.number,
	onClose: PropTypes.func.isRequired,
};

export default Toast;
