import RightImg from "../../../assets/marketing/rightIcon.webp";

export default function SuccessModal({ isModalOpen, closeModal }) {
  return (
    <div>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-blackTrasparent bg-opacity-50">
          <div className="bg-white w-full max-w-[420px] rounded-[15px] self-start mt-[150px] sm:mx-auto m-3">
            <img
              loading="lazy"
              src={RightImg}
              alt="RightImg"
              className="rounded-[50%] m-0 w-full max-w-[65px] h-[65px] mx-auto mt-[-30px]"
            />
            <div className="mt-4 p-5 ">
              <h3 className="text-[30px] text-center">Success</h3>
              <p className="text-[15px] text-center mt-[20px]">
                You've successfully signed up for Jane Rothe updates!
              </p>
            </div>
            <div className="m-4 w-full max-w-[275px] mx-auto">
              <button
                className="bg-[#32b332] text-clientWhite w-full p-2 text-[20px] mb-[20px] rounded-[5px]"
                type="submit"
                onClick={closeModal}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
