import React, { useContext, useEffect, memo } from 'react';
import {
	Route,
	Switch,
	Redirect,
	useHistory,
	useLocation,
} from 'react-router-dom';
import Home from '../appComponents/home/Home';
import Stripe from '../appComponents/myServices/Stripe';
import Nav from '../navComponents/Nav';

import LandingPage from '../marketingComponents/LandingPage';

import LoginForm from '../authComponents/LoginForm';
import SignupForm from '../authComponents/SignupForm';
import AdminSignup from '../admin/adminAuth/AdminSignup';
import AdminLogin from '../admin/adminAuth/AdminLogin';
import Verify from '../authComponents/Verify';
import MyServices from '../appComponents/myServices/MyServices';
import { useSelector } from 'react-redux';
import ForgotPassword from '../authComponents/ForgotPassword';
import ResetPassword from '../authComponents/ResetPassword';
import Settings from '../appComponents/settings/Settings.js';
import Dashboard from '../admin/dashboard/Dashboard';
import ClientList from '../admin/client/ClientList.js';
import ClientDetails from '../admin/client/ClientDetails.js';
import ServiceDetail from '../appComponents/serviceDetail/ServiceDetail';
import MyForms from '../appComponents/myForms/MyForms';
import SpendingTracker from '../appComponents/spendingTracker/SpendingTracker';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import CreateUser from '../admin/createUser/CreateUser';

// new marketing pages
import LandingPage2 from '../marketingComponents2/pages/landingPageNew/LandingPage';
import ServicePage from '../marketingComponents2/pages/ServicePage/ServicePage.js';
import AboutPage from '../marketingComponents2/pages/about/AboutPage.js';
import SignInSection from '../marketingComponents2/section/SignInSection.js';
import ForgetPasswordSection from '../marketingComponents2/section/ForgetPasswordSection.js';
import SignUpSection from '../marketingComponents2/section/SignUpSection.js';
import BlogPage from '../marketingComponents2/pages/blog/BlogPage.js';
import BlogDetailPage from '../marketingComponents2/pages/blog/BlogDetailPage.js';
import ServicePageNew from '../marketingComponents2/pages/ServicePageNew/ServicePageNew.js';
import TermsOfService from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import LandingFooter from '../marketingComponents2/landingFooter/LandingFooter';
import Header from '../marketingComponents2/header/Header';

// Import the Form Builder component
import FormBuilder from '../admin/formBuilder';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant', // Enables smooth scrolling effect
		}); // Scrolls to the top of the page
	}, [pathname]);

	return null;
};

const PageLayout = ({ children }) => {
	return (
		<>
			<Header />
			{children}
			<LandingFooter />
		</>
	);
};

/** Components for displaying routes. Here authetication is checked.  */
function Routes() {
	const currentUser = useSelector((st) => st.user.currentUser);
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);

	/** !Note: When using the component prop for the Route component, a new instance of this combined component is created, causing the child components (Nav and Verify) to unmount and remount, effectively resetting their state. So for ex: if you intend to call a useEffect once in the component, it will be called repeatedly every time this Route component re-renders. Instead, use render prop for Route component  */
	const withNav = (Component) => (props) => {
		return (
			<>
				<Nav />
				<Component {...props} />
			</>
		);
	};

	return (
		<div data-testid='routes'>
			<ScrollToTop></ScrollToTop>
			<Switch>
				<Route
					exact
					path='/'
					component={
						currentUser && currentUser.username ? withNav(Home) : LandingPage2
					}
				/>
				<Route
					exact
					path='/login'
					component={
						// currentUser && currentUser.username ? RedirectHome : LoginForm
						currentUser && currentUser.username ? RedirectHome : SignInSection
					}
				/>
				<Route
					exact
					path='/signup'
					component={
						// currentUser && currentUser.username ? RedirectHome : SignupForm
						currentUser && currentUser.username ? RedirectHome : SignUpSection
					}
				/>
				<Route
					exact
					path='/forgot-password'
					component={ForgetPasswordSection}
				/>
				<Route
					exact
					path='/services'
					component={
						currentUser && currentUser.username ? RedirectHome : ServicePageNew
					}
				/>
				<Route
					exact
					path='/about-us'
					component={
						currentUser && currentUser.username ? RedirectHome : AboutPage
					}
				/>
				<Route
					exact
					path='/blog'
					component={
						currentUser && currentUser.username ? RedirectHome : BlogPage
					}
				/>
				<Route
					exact
					path='/blog/:id'
					component={
						currentUser && currentUser.username ? RedirectHome : BlogDetailPage
					}
				/>

				<Route exact path='/verify/:username/:code' render={withNav(Verify)} />
				{/* <Route exact path="/verify/:username/:code">
					<Nav />
					<Verify />
				</Route> */}
				<Route
					exact
					path='/reset-password/:username/:code'
					component={ResetPassword}
				/>
				<Route exact path='/stripe' component={Stripe} />

				{/* This is only a partially protected route. The component is shown when user isn't verified but mostly hidden */}
				<Route
					exact
					path='/home'
					render={
						currentUser && currentUser.username ? withNav(Home) : RedirectLogin
					}
				/>
				<ProtectedRoute exact path='/my-services' component={MyServices} />
				<ProtectedRoute exact path='/servicedetail' component={ServiceDetail} />
				<ProtectedRoute exact path='/forms' component={MyForms} />
				<ProtectedRoute exact path='/settings' component={Settings} />
				<ProtectedRoute
					exact
					path='/spending-tracker'
					component={SpendingTracker}
				/>

				<Route
					exact
					path='/admin/signup'
					component={
						currentAdmin && currentAdmin.username
							? RedirectAdminDashboard
							: AdminSignup
					}
				/>
				<Route
					exact
					path='/admin/login'
					component={
						currentAdmin && currentAdmin.username
							? RedirectAdminDashboard
							: AdminLogin
					}
				/>
				<AdminRoute exact path='/admin/dashboard' component={Dashboard} />
				<AdminRoute exact path='/admin/clients' component={ClientList} />
				<AdminRoute exact path='/admin/create-user' component={CreateUser} />
				<AdminRoute
					exact
					path='/admin/clients/client-details/:username'
					component={ClientDetails}
				/>

				{/* Form Builder Routes */}
				<AdminRoute exact path='/admin/form-builder' component={FormBuilder} />
				<AdminRoute
					exact
					path='/admin/form-builder/create'
					component={FormBuilder}
				/>
				<AdminRoute
					exact
					path='/admin/form-builder/edit/:id'
					component={FormBuilder}
				/>

				{/* Public routes for legal pages */}
				<Route
					exact
					path='/terms'
					render={() => (
						<PageLayout>
							<TermsOfService />
						</PageLayout>
					)}
				/>
				<Route
					exact
					path='/privacy'
					render={() => (
						<PageLayout>
							<PrivacyPolicy />
						</PageLayout>
					)}
				/>

				{/* captures all admin routes will go to dashboard if signed in, will go to login if not */}
				<Route
					path='/admin'
					component={
						currentAdmin && currentAdmin.username
							? RedirectAdminDashboard
							: RedirectAdminLogin
					}
				/>

				{/* captures all non-admin routes will go to dashboard if signed in, will go to login if not */}
				<Route
					path='/'
					component={
						currentUser && currentUser.username ? RedirectHome : RedirectLogin
					}
				/>

				<Redirect to='/' />
			</Switch>
		</div>
	);
}

const RedirectHome = () => <Redirect to='/home' />;
const RedirectLogin = () => <Redirect to='/login' />;
const RedirectAdminDashboard = () => <Redirect to='/admin/dashboard' />;
const RedirectAdminLogin = () => <Redirect to='/admin/login' />;

export default Routes;
