import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminSignup, clearErrors } from '../../store/admin';
import { useHistory, NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import newLogo from '../../assets/marketing/newLogo.webp';
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';
import Label from '../../components/ui/label';
import Form from '../../components/ui/form';
import { FiUser, FiMail, FiLock, FiAlertCircle } from 'react-icons/fi';

/** Form for signing up new admin. Adds admin to the db.
 *
 * Controlled form: admin's input is pulled in state and then displayed in form. Controlled by {@link handleChange } function
 *
 * Textfield Validation: Erros determined by attributes set in TextField's inputProps
 *
 * Error Visibility: triggered by errorTrigger state only when form is submitted via {@link handleSubmit}. State must be true for errors to show. Error shown via Texfield's helper text.
 *
 * Submission: checks if form is valid. If true, form data is dispatched. If one field is invalid, entire form is invalid--form won't submit, and errors become visible
 */

// Form validation schema
const schema = yup
	.object({
		username: yup.string().required('Username is required'),
		password: yup
			.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
				'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
			),
		firstName: yup.string().required('First name is required'),
		lastName: yup.string().required('Last name is required'),
		email: yup
			.string()
			.email('Invalid email format')
			.required('Email is required'),
	})
	.required();

const AdminSignup = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const errors = useSelector((st) => st.admin.errors);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Initialize react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: '',
			password: '',
			firstName: '',
			lastName: '',
			email: '',
		},
	});

	// Clear errors on mount and unmount
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, [dispatch]);

	// Handle form submission
	const onSubmit = (data) => {
		setIsSubmitting(true);
		dispatch(adminSignup(data))
			.unwrap()
			.then(() => {
				history.push('/admin/dashboard');
			})
			.catch(() => {
				setIsSubmitting(false);
			});
	};

	return (
		<div
			className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'
			data-testid='admin-signup'>
			<div className='sm:mx-auto sm:w-full sm:max-w-md'>
				<div className='flex justify-center mb-8'>
					<img className='h-16 w-auto' src={newLogo} alt='Jane Rothe Logo' />
				</div>
				<h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
					Admin Sign Up
				</h2>
				<p className='mt-2 text-center text-sm text-gray-600'>
					Create a new admin account
				</p>
			</div>

			<div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
				<div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
					<Form onSubmit={handleSubmit(onSubmit)}>
						{/* Username field */}
						<Form.Item>
							<Label htmlFor='username' required>
								Username
							</Label>
							<div className='mt-1 relative rounded-md shadow-sm'>
								<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
									<FiUser className='h-5 w-5 text-gray-400' />
								</div>
								<Input
									id='username'
									className='pl-10'
									error={!!formErrors.username}
									{...register('username')}
								/>
							</div>
							{formErrors.username && (
								<Form.Message>{formErrors.username.message}</Form.Message>
							)}
						</Form.Item>

						{/* Password field */}
						<Form.Item>
							<Label htmlFor='password' required>
								Password
							</Label>
							<div className='mt-1 relative rounded-md shadow-sm'>
								<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
									<FiLock className='h-5 w-5 text-gray-400' />
								</div>
								<Input
									id='password'
									type='password'
									className='pl-10'
									error={!!formErrors.password}
									{...register('password')}
								/>
							</div>
							{formErrors.password && (
								<Form.Message>{formErrors.password.message}</Form.Message>
							)}
						</Form.Item>

						{/* First Name field */}
						<Form.Item>
							<Label htmlFor='firstName' required>
								First Name
							</Label>
							<Input
								id='firstName'
								error={!!formErrors.firstName}
								{...register('firstName')}
							/>
							{formErrors.firstName && (
								<Form.Message>{formErrors.firstName.message}</Form.Message>
							)}
						</Form.Item>

						{/* Last Name field */}
						<Form.Item>
							<Label htmlFor='lastName' required>
								Last Name
							</Label>
							<Input
								id='lastName'
								error={!!formErrors.lastName}
								{...register('lastName')}
							/>
							{formErrors.lastName && (
								<Form.Message>{formErrors.lastName.message}</Form.Message>
							)}
						</Form.Item>

						{/* Email field */}
						<Form.Item>
							<Label htmlFor='email' required>
								Email
							</Label>
							<div className='mt-1 relative rounded-md shadow-sm'>
								<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
									<FiMail className='h-5 w-5 text-gray-400' />
								</div>
								<Input
									id='email'
									type='email'
									className='pl-10'
									error={!!formErrors.email}
									{...register('email')}
								/>
							</div>
							{formErrors.email && (
								<Form.Message>{formErrors.email.message}</Form.Message>
							)}
						</Form.Item>

						{/* API Errors */}
						{errors && errors.length > 0 && (
							<div className='rounded-md bg-red-50 p-4 mt-4'>
								<div className='flex'>
									<div className='flex-shrink-0'>
										<FiAlertCircle className='h-5 w-5 text-red-400' />
									</div>
									<div className='ml-3'>
										<h3 className='text-sm font-medium text-red-800'>
											There were errors with your submission
										</h3>
										<div className='mt-2 text-sm text-red-700'>
											<ul className='list-disc pl-5 space-y-1'>
												{errors.map((error, index) => (
													<li key={index}>{error}</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Submit button */}
						<div className='mt-6'>
							<Button
								type='submit'
								variant='primary'
								className='w-full'
								disabled={isSubmitting}>
								{isSubmitting ? 'Signing up...' : 'Sign up'}
							</Button>
						</div>
					</Form>

					{/* Sign in link */}
					<div className='mt-6'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-white text-gray-500'>
									Already have an account?
								</span>
							</div>
						</div>

						<div className='mt-6'>
							<NavLink to='/admin/login'>
								<Button type='button' variant='outline' className='w-full'>
									Sign in
								</Button>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminSignup;
