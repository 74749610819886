import mask1 from '../../../assets/marketing/Internet-Ads.webp';
import mask2 from '../../../assets/marketing/Social-Media-Service.webp';
import mask5 from '../../../assets/marketing/Direct-Mail.webp';
import consultationImg from '../../../assets/marketing/what-we-do-sub.webp';

export const ServiceCardData = [
	{
		id: 1,
		headingStart: 'Digital Billboards - Retargeting',
		headingMiddle: '$150',
		headingEnd: 'MONTH',
		image: mask1,
		listingPint: [
			'<b>Ad Types:</b> Banner ads, mobile ads, and retargeting ads',
			'<b>Targeting:</b>',
			'📍 <b>Geo-Specific</b> – Target buyers/sellers in key locations',
			'🎯 <b>Lookalike Audiences</b> – Expand your reach to similar prospects',
			'👥 <b>Sphere of Influence</b> – Keep your brand visible to warm leads',
			'<b>Management:</b> Full setup, optimization, and tracking',
			'<b>Custom Designs:</b> Standout graphics made for conversions',
		],
	},
	{
		id: 2,
		headingStart: 'Social Media Marketing',
		headingMiddle: '$395',
		headingEnd: 'MONTH',
		image: mask2,
		listingPint: [
			'<b>Content Strategy:</b>',
			'🎞️ Mix of <b>images, reels, videos, & stories</b>',
			'📅 <b>3 posts per week</b> on Instagram, Facebook, etc.',
			'<b>Engagement Goals:</b>',
			'🚀 Increase <b>followers & engagement</b>',
			'🔗 Drive <b>website & listing traffic</b>',
			'<b>Branding:</b> Personalized graphics aligned with your brand',
			'<b>Analytics & Adjustments:</b> Performance monitoring & strategy tweaks',
		],
	},
	{
		id: 3,
		headingStart: 'Direct Mail Marketing',
		headingMiddle: '$195',
		headingEnd: 'MONTH',
		image: mask5,
		listingPint: [
			'<b>Design & Management:</b>',
			'🎨 Custom-designed, professionally branded mailers',
			'📬 Full campaign setup & mailing execution',
			'<b>Mailing Frequency Options:</b>',
			'📆 <b>12x Yearly</b> – Monthly mailers for brand consistency',
			'📆 <b>6x Yearly</b> – Every other month for steady engagement',
			'📆 <b>4x Yearly</b> – Quarterly for key client touchpoints',
			'<b>Targeting:</b> Sent directly to your <b>database of contacts</b>',
		],
	},
	{
		id: 4,
		headingStart: 'Business Consultation',
		headingMiddle: '$200',
		headingEnd: 'MONTH',
		image: consultationImg,
		listingPint: [
			'<b>One-on-One Support:</b>',
			'🗓️ <b>Monthly 1-hour virtual strategy call</b>',
			'💬 <b>Access to chat throughout the month</b>',
			'<b>Who This is For:</b>',
			'✅ Agents who need <b>help with business planning & organization</b>',
			'✅ New agents looking for <b>growth & marketing strategies</b>',
			'✅ Experienced agents seeking <b>fresh insights & structure</b>',
			'<b>Takeaways:</b> Actionable steps to build & refine your business strategy',
		],
	},
];
