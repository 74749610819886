import { combineReducers } from 'redux';
import user from './user';
import admin from './admin';
import price from './price';
import formBuilder from './formBuilder';
import users from '../reducers/users';
import currentUser from '../reducers/currentUser';
import currentAdmin from '../reducers/currentAdmin';
import errors from '../reducers/errors';
import token from '../reducers/token';
import prices from '../reducers/prices';
import success from '../reducers/success';

/** combines the reducers into one root reducer */
export default combineReducers({ admin, price, user, formBuilder });
