import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({ trigger, children, className = '', ...props }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const dropdownRef = React.useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const closeDropdown = () => {
		setIsOpen(false);
	};

	// Close dropdown when clicking outside
	React.useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				closeDropdown();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div
			className={`relative inline-block text-left ${className}`}
			ref={dropdownRef}
			{...props}>
			<div onClick={toggleDropdown} className='cursor-pointer'>
				{trigger}
			</div>
			{isOpen && (
				<div className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
					<div
						className='py-1'
						role='menu'
						aria-orientation='vertical'
						aria-labelledby='options-menu'>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};

const DropdownItem = ({ className = '', onClick, children, ...props }) => {
	const handleClick = (e) => {
		if (onClick) {
			onClick(e);
		}
	};

	return (
		<div
			className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${className}`}
			role='menuitem'
			onClick={handleClick}
			{...props}>
			{children}
		</div>
	);
};

const DropdownSeparator = ({ className = '', ...props }) => {
	return <div className={`my-1 h-px bg-gray-200 ${className}`} {...props} />;
};

Dropdown.propTypes = {
	trigger: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

DropdownItem.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
};

DropdownSeparator.propTypes = {
	className: PropTypes.string,
};

Dropdown.Item = DropdownItem;
Dropdown.Separator = DropdownSeparator;

export default Dropdown;
