import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminLogin, changeRemember, clearErrors } from '../../store/admin';
import { useHistory, NavLink } from 'react-router-dom';
import newLogo from '../../assets/marketing/newLogo.webp';

const AdminLogin = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const errors = useSelector((st) => st.admin.errors);
	const [formData, setFormData] = useState({
		username: '',
		password: '',
	});
	const [rememberMe, setRememberMe] = useState(true);

	// Clear errors on mount and unmount
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, [dispatch]);

	// Update remember me status in redux
	useEffect(() => {
		dispatch(changeRemember(rememberMe));
	}, [rememberMe, dispatch]);

	// Handle input changes
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(adminLogin(formData));
	};

	return (
		<div
			className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'
			data-testid='admin-login'>
			<div className='sm:mx-auto sm:w-full sm:max-w-md'>
				<div className='flex justify-center mb-8'>
					<img className='h-16 w-auto' src={newLogo} alt='Jane Rothe Logo' />
				</div>
				<h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
					Admin Login
				</h2>
			</div>

			<div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
				<div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
					<form className='space-y-6' onSubmit={handleSubmit}>
						{/* Username field */}
						<div>
							<label
								htmlFor='username'
								className='block text-sm font-medium text-gray-700'>
								Username
							</label>
							<div className='mt-1'>
								<input
									id='username'
									name='username'
									type='text'
									required
									className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm'
									value={formData.username}
									onChange={handleChange}
								/>
							</div>
						</div>

						{/* Password field */}
						<div>
							<label
								htmlFor='password'
								className='block text-sm font-medium text-gray-700'>
								Password
							</label>
							<div className='mt-1'>
								<input
									id='password'
									name='password'
									type='password'
									required
									className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm'
									value={formData.password}
									onChange={handleChange}
								/>
							</div>
						</div>

						{/* Remember me and forgot password */}
						<div className='flex items-center justify-between'>
							<div className='flex items-center'>
								<input
									id='remember-me'
									name='remember-me'
									type='checkbox'
									className='h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded'
									checked={rememberMe}
									onChange={(e) => setRememberMe(e.target.checked)}
								/>
								<label
									htmlFor='remember-me'
									className='ml-2 block text-sm text-gray-900'>
									Remember me
								</label>
							</div>

							<div className='text-sm'>
								<NavLink
									to='/forgot-password'
									className='font-medium text-green-600 hover:text-green-500'>
									Forgot your password?
								</NavLink>
							</div>
						</div>

						{/* API Errors */}
						{errors && errors.length > 0 && (
							<div className='rounded-md bg-red-50 p-4'>
								<div className='flex'>
									<div className='flex-shrink-0'>
										<svg
											className='h-5 w-5 text-red-400'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'
											fill='currentColor'
											aria-hidden='true'>
											<path
												fillRule='evenodd'
												d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
												clipRule='evenodd'
											/>
										</svg>
									</div>
									<div className='ml-3'>
										<h3 className='text-sm font-medium text-red-800'>
											There were errors with your submission
										</h3>
										<div className='mt-2 text-sm text-red-700'>
											<ul className='list-disc pl-5 space-y-1'>
												{errors.map((error, index) => (
													<li key={index}>{error}</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Submit button */}
						<div>
							<button
								type='submit'
								className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
								Sign in
							</button>
						</div>
					</form>

					{/* Sign up link */}
					<div className='mt-6'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-white text-gray-500'>
									Don't have an account?
								</span>
							</div>
						</div>

						<div className='mt-6'>
							<NavLink
								to='/admin/signup'
								className='w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'>
								Sign up
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminLogin;
