/** @format */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers, getAllSubs } from '../../store/user';
import { getPrices } from '../../store/price';
import Card from '../../components/ui/card';
import Table from '../../components/ui/table';
import Button from '../../components/ui/button';
import {
	FiUsers,
	FiPackage,
	FiCreditCard,
	FiTrendingUp,
	FiCalendar,
	FiDollarSign,
	FiArrowRight,
} from 'react-icons/fi';

const Dashboard = () => {
	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);
	const prices = useSelector((st) => st.price.allPrices);
	const users = useSelector((st) => st.user.allUsers);
	const subs = useSelector((st) => st.user.allSubs);

	// Fetch data on component mount
	useEffect(() => {
		if (token) {
			dispatch(getAllUsers({ token }));
			dispatch(getPrices({ token }));
			dispatch(getAllSubs({ token }));
		}
	}, [token, dispatch]);

	// Calculate stats
	const totalRevenue = subs.reduce((acc, sub) => {
		const price = prices.find((p) => p.id === sub.productId);
		return acc + (price ? price.price / 100 : 0);
	}, 0);

	const recentUsers = [...users]
		.sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0))
		.slice(0, 5);

	const popularProducts = prices
		.map((price) => {
			const subscriberCount = subs.filter(
				(sub) => sub.productId === price.id
			).length;
			return {
				...price,
				subscriberCount,
			};
		})
		.sort((a, b) => b.subscriberCount - a.subscriberCount)
		.slice(0, 5);

	// Get current month name
	const currentMonth = new Date().toLocaleString('default', { month: 'long' });

	return (
		<div className='space-y-6'>
			{/* Stats Cards */}
			<div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
				{/* Total Users */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-blue-50'>
							<FiUsers className='h-6 w-6 text-blue-600' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								Client Count
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								{users.length}
							</p>
						</div>
					</div>
				</Card>

				{/* Total Services */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-indigo-50'>
							<FiPackage className='h-6 w-6 text-indigo-600' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								Total Services
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								{prices.length}
							</p>
						</div>
					</div>
				</Card>

				{/* Total Subscriptions */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-purple-50'>
							<FiCreditCard className='h-6 w-6 text-purple-600' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								Total Subscriptions
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								{subs.length}
							</p>
						</div>
					</div>
				</Card>

				{/* Total Revenue */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-green-50'>
							<FiDollarSign className='h-6 w-6 text-green-600' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								Total Revenue
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								${totalRevenue.toFixed(2)}
							</p>
						</div>
					</div>
				</Card>

				{/* Active Subscriptions */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-primary/10'>
							<FiTrendingUp className='h-6 w-6 text-primary' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								Active Subscriptions
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								{subs.filter((sub) => sub.status === 'active').length}
							</p>
						</div>
					</div>
				</Card>

				{/* Current Month */}
				<Card className='overflow-hidden'>
					<div className='p-6 flex items-center'>
						<div className='rounded-full p-3 bg-teal-50'>
							<FiCalendar className='h-6 w-6 text-teal-600' />
						</div>
						<div className='ml-4'>
							<h3 className='text-sm font-montserrat-medium text-gray-700'>
								This Month
							</h3>
							<p className='text-3xl font-glacial text-gray-900'>
								{currentMonth}
							</p>
						</div>
					</div>
				</Card>
			</div>

			{/* Recent Users - Changed from Dark to Light */}
			<Card className='overflow-hidden'>
				<div className='p-6'>
					<div className='flex justify-between items-center mb-6'>
						<h2 className='text-xl font-montserrat-medium uppercase text-gray-900'>
							Recent Users
						</h2>
						<p className='text-sm font-montserrat-regular text-gray-500'>
							The newest users who have joined the platform.
						</p>
					</div>

					<Table className='w-full'>
						<Table.Header>
							<Table.Row className='bg-gray-50'>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Name
								</Table.HeaderCell>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Verified
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{recentUsers.map((user) => (
								<Table.Row
									key={user.username}
									className='border-t border-gray-100'>
									<Table.Cell className='font-montserrat-regular text-gray-700'>
										{user.firstName} {user.lastName}
									</Table.Cell>
									<Table.Cell className='font-montserrat-regular text-gray-700'>
										{user.email}
									</Table.Cell>
									<Table.Cell>
										{user.verified ? (
											<span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-montserrat-medium bg-green-100 text-green-800'>
												Verified
											</span>
										) : (
											<span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-montserrat-medium bg-yellow-100 text-yellow-800'>
												Pending
											</span>
										)}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>

					<div className='mt-6 flex justify-end'>
						<Button
							variant='primary'
							size='sm'
							className='font-montserrat-medium text-sm'>
							View All Users <FiArrowRight className='ml-2' />
						</Button>
					</div>
				</div>
			</Card>

			{/* Popular Services - Updated styling */}
			<Card className='overflow-hidden'>
				<div className='p-6'>
					<div className='flex justify-between items-center mb-6'>
						<h2 className='text-xl font-montserrat-medium uppercase text-gray-900'>
							Popular Services
						</h2>
						<p className='text-sm font-montserrat-regular text-gray-500'>
							The most subscribed services on the platform.
						</p>
					</div>

					<Table className='w-full'>
						<Table.Header>
							<Table.Row className='bg-indigo-50'>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Service
								</Table.HeaderCell>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Price
								</Table.HeaderCell>
								<Table.HeaderCell className='font-montserrat-medium text-gray-900'>
									Subscribers
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{popularProducts.map((product) => (
								<Table.Row
									key={product.id}
									className='border-t border-gray-100'>
									<Table.Cell className='font-montserrat-medium text-gray-700'>
										{product.name}
									</Table.Cell>
									<Table.Cell className='font-montserrat-regular text-gray-700'>
										${(product.price / 100).toFixed(2)}
									</Table.Cell>
									<Table.Cell>
										<span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-montserrat-medium bg-indigo-100 text-indigo-800'>
											{product.subscriberCount}
										</span>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>

					<div className='mt-6 flex justify-end'>
						<Button
							variant='primary'
							size='sm'
							className='font-montserrat-medium text-sm'>
							View All Services <FiArrowRight className='ml-2' />
						</Button>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default Dashboard;
