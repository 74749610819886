import React, { useState } from 'react';
import logo from '../../../assets/marketing/newLogo.webp';
import { Link, useLocation } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import { CONTACT_US_CALENDLY_URL } from '../../../utils/constant/constants';

const Header = () => {
	const location = useLocation();
	const pathname = location?.pathname;
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen((prevState) => !prevState);
	};

	const RouteData = [
		{ name: 'Services', route: '/services' },
		{ name: 'About us', route: '/about-us' },
		{ name: 'Contact us', route: '/contact' },
		// { name: 'Blog', route: '/blog' },
	];

	return (
		<div className='bg-white w-full fixed z-20'>
			<header className='font-body [box-shadow:0px_1px_0px_0px_#0000000D] bg-white text-clientNero h-[80px]  mx-auto px-8 tablet:px-0 mobile:px-0'>
				<div className='flex justify-between items-center h-full tablet:px-[30px] mobile:px-[30px]'>
					<Link to='/' className='flex items-center'>
						<img
							className='w-20 h-auto tablet:m-0 mobile:m-0'
							alt='Janerothe Logo'
							src={logo}
						/>
					</Link>

					<nav className='ml-5'>
						<ul className='mobile:hidden tablet:hidden flex space-x-6'>
							{RouteData.map((item, index) =>
								item.route === '/contact' ? (
									<li key={index}>
										<PopupButton
											url={CONTACT_US_CALENDLY_URL}
											rootElement={document.getElementById('root')}
											text='Contact Us'
											className='body-regular'
										/>
									</li>
								) : (
									<li key={index}>
										<Link
											to={item.route}
											style={{
												color: pathname === item.route ? '#CC1DB9' : '#202020',
											}}
											className='body-regular'>
											{item.name}
										</Link>
									</li>
								)
							)}
						</ul>

						<div className='hidden mobile:block tablet:block'>
							<button
								onClick={toggleMobileMenu}
								className='text-gray-800 focus:outline-none pr-[5px]'>
								<svg
									className='w-6 h-6'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M4 6h16M4 12h16M4 18h16'></path>
								</svg>
							</button>
						</div>
					</nav>
				</div>

				{isMobileMenuOpen && (
					<div className='hidden tablet:block mobile:block'>
						<ul className='flex flex-col space-y-2 p-4 bg-white shadow-md'>
							{RouteData.map((item, index) =>
								item.route === '/contact' ? (
									<li key={index}>
										<PopupButton
											url={CONTACT_US_CALENDLY_URL}
											rootElement={document.getElementById('root')}
											text='Contact Us'
											className='body-regular'
										/>
									</li>
								) : (
									<li key={index}>
										<Link
											to={item?.route}
											style={{
												color: pathname === item.route ? '#CC1DB9' : '#202020',
											}}
											className='body-regular'>
											{item.name}
										</Link>
									</li>
								)
							)}
						</ul>
					</div>
				)}
			</header>
		</div>
	);
};

export default Header;
