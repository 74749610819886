import React, { useState } from 'react';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';
import Label from '../../components/ui/label';
import { FiInfo, FiAlertCircle } from 'react-icons/fi';

const FormPreview = ({ form, sections, fields, fieldSectionMap }) => {
	// Initialize the form state with empty values
	const [formState, setFormState] = useState({});
	const [formErrors, setFormErrors] = useState({});

	// Helper to get formatted field values
	const getFieldValue = (field) => {
		if (!formState[field.field_key]) {
			return field.type === 'checkbox' ? [] : '';
		}
		return formState[field.field_key];
	};

	// Handle form input changes
	const handleInputChange = (fieldKey, value, fieldType) => {
		let newValue = value;

		// Special handling for checkbox fields
		if (fieldType === 'checkbox') {
			const currentValues = formState[fieldKey] || [];
			if (currentValues.includes(value)) {
				newValue = currentValues.filter((v) => v !== value);
			} else {
				newValue = [...currentValues, value];
			}
		}

		setFormState({
			...formState,
			[fieldKey]: newValue,
		});

		// Clear the error for this field when user changes the value
		if (formErrors[fieldKey]) {
			setFormErrors({
				...formErrors,
				[fieldKey]: null,
			});
		}
	};

	// Validate a field based on its validation rules
	const validateField = (field, value) => {
		const rules = field.validation_rules;

		// Handle required fields
		if (field.is_required) {
			if (!value || (Array.isArray(value) && value.length === 0)) {
				return field.validation_rules?.errorMessage || 'This field is required';
			}
		}

		// If value is empty and not required, no need to validate further
		if (!value || value === '') return null;

		// Specific validations based on field type
		switch (field.type) {
			case 'number':
				if (rules?.min && Number(value) < Number(rules.min)) {
					return `Value must be at least ${rules.min}`;
				}
				if (rules?.max && Number(value) > Number(rules.max)) {
					return `Value must be at most ${rules.max}`;
				}
				break;

			case 'text':
			case 'textarea':
				if (rules?.min && value.length < Number(rules.min)) {
					return `Must be at least ${rules.min} characters`;
				}
				if (rules?.max && value.length > Number(rules.max)) {
					return `Must be at most ${rules.max} characters`;
				}
				if (rules?.pattern && !new RegExp(rules.pattern).test(value)) {
					return rules.errorMessage || 'Invalid format';
				}
				break;

			case 'email':
				if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
					return 'Please enter a valid email address';
				}
				if (rules?.pattern && !new RegExp(rules.pattern).test(value)) {
					return rules.errorMessage || 'Invalid email format';
				}
				break;

			case 'tel':
				if (rules?.pattern && !new RegExp(rules.pattern).test(value)) {
					return rules.errorMessage || 'Invalid phone number format';
				}
				break;
		}

		return null;
	};

	// Handle form submission preview
	const handleSubmit = (e) => {
		e.preventDefault();

		// Validate all fields
		const errors = {};
		let hasErrors = false;

		fields.forEach((field) => {
			const value = formState[field.field_key];
			const error = validateField(field, value);

			if (error) {
				errors[field.field_key] = error;
				hasErrors = true;
			}
		});

		setFormErrors(errors);

		if (!hasErrors) {
			// In a real scenario, we would submit the form here
			window.alert(
				'Form submission preview: Form is valid and ready to submit.'
			);
			console.log('Form data:', formState);
		}
	};

	// Get fields for a section
	const getFieldsForSection = (sectionId) => {
		const mappings = fieldSectionMap
			.filter((mapping) => mapping.section_id === sectionId)
			.sort((a, b) => a.position - b.position);

		return mappings
			.map((mapping) => fields.find((field) => field.id === mapping.field_id))
			.filter(Boolean);
	};

	// Get unassigned fields (not in any section)
	const getUnassignedFields = () => {
		const assignedFieldIds = fieldSectionMap.map((mapping) => mapping.field_id);
		return fields
			.filter((field) => !assignedFieldIds.includes(field.id))
			.sort((a, b) => a.position - b.position);
	};

	// Render a single field based on its type
	const renderField = (field) => {
		const fieldValue = getFieldValue(field);
		const fieldError = formErrors[field.field_key];

		switch (field.type) {
			case 'text':
			case 'email':
			case 'tel':
			case 'date':
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<Input
							id={field.field_key}
							name={field.field_key}
							type={
								field.type === 'tel'
									? 'tel'
									: field.type === 'date'
									? 'date'
									: 'text'
							}
							value={fieldValue}
							onChange={(e) =>
								handleInputChange(field.field_key, e.target.value, field.type)
							}
							placeholder={field.placeholder}
							className={fieldError ? 'border-red-500' : ''}
						/>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);

			case 'textarea':
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<textarea
							id={field.field_key}
							name={field.field_key}
							value={fieldValue}
							onChange={(e) =>
								handleInputChange(field.field_key, e.target.value, field.type)
							}
							placeholder={field.placeholder}
							rows={4}
							className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-400 ${
								fieldError ? 'border-red-500' : ''
							}`}
						/>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);

			case 'number':
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<Input
							id={field.field_key}
							name={field.field_key}
							type='number'
							value={fieldValue}
							onChange={(e) =>
								handleInputChange(field.field_key, e.target.value, field.type)
							}
							placeholder={field.placeholder}
							className={fieldError ? 'border-red-500' : ''}
							min={field.validation_rules?.min}
							max={field.validation_rules?.max}
						/>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);

			case 'select':
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<select
							id={field.field_key}
							name={field.field_key}
							value={fieldValue}
							onChange={(e) =>
								handleInputChange(field.field_key, e.target.value, field.type)
							}
							className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-400 ${
								fieldError ? 'border-red-500' : ''
							}`}>
							<option value=''>Select an option</option>
							{field.options &&
								field.options.map((option, index) => (
									<option key={index} value={option.value}>
										{option.label}
									</option>
								))}
						</select>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);

			case 'checkbox':
				return (
					<div className='mb-4' key={field.id}>
						<fieldset>
							<legend className='font-medium text-gray-700 mb-1'>
								{field.label}
								{field.is_required && (
									<span className='text-red-500 ml-1'>*</span>
								)}
							</legend>

							{field.description && (
								<p className='text-sm text-gray-500 mb-2'>
									{field.description}
								</p>
							)}

							<div className='space-y-2'>
								{field.options &&
									field.options.map((option, index) => (
										<div key={index} className='flex items-center'>
											<input
												id={`${field.field_key}-${option.value}`}
												name={`${field.field_key}[]`}
												type='checkbox'
												value={option.value}
												checked={(fieldValue || []).includes(option.value)}
												onChange={(e) =>
													handleInputChange(
														field.field_key,
														e.target.value,
														field.type
													)
												}
												className='h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded'
											/>
											<Label
												htmlFor={`${field.field_key}-${option.value}`}
												className='ml-2'>
												{option.label}
											</Label>
										</div>
									))}
							</div>

							{fieldError && (
								<div className='text-red-500 text-sm mt-1 flex items-center'>
									<FiAlertCircle className='mr-1' />
									{fieldError}
								</div>
							)}
						</fieldset>
					</div>
				);

			case 'radio':
				return (
					<div className='mb-4' key={field.id}>
						<fieldset>
							<legend className='font-medium text-gray-700 mb-1'>
								{field.label}
								{field.is_required && (
									<span className='text-red-500 ml-1'>*</span>
								)}
							</legend>

							{field.description && (
								<p className='text-sm text-gray-500 mb-2'>
									{field.description}
								</p>
							)}

							<div className='space-y-2'>
								{field.options &&
									field.options.map((option, index) => (
										<div key={index} className='flex items-center'>
											<input
												id={`${field.field_key}-${option.value}`}
												name={field.field_key}
												type='radio'
												value={option.value}
												checked={fieldValue === option.value}
												onChange={(e) =>
													handleInputChange(
														field.field_key,
														e.target.value,
														field.type
													)
												}
												className='h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300'
											/>
											<Label
												htmlFor={`${field.field_key}-${option.value}`}
												className='ml-2'>
												{option.label}
											</Label>
										</div>
									))}
							</div>

							{fieldError && (
								<div className='text-red-500 text-sm mt-1 flex items-center'>
									<FiAlertCircle className='mr-1' />
									{fieldError}
								</div>
							)}
						</fieldset>
					</div>
				);

			case 'file':
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<div className='mt-1 flex items-center'>
							<label
								htmlFor={field.field_key}
								className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 cursor-pointer'>
								Choose file
								<input
									id={field.field_key}
									name={field.field_key}
									type='file'
									onChange={(e) =>
										handleInputChange(
											field.field_key,
											e.target.files?.length ? e.target.files[0].name : '',
											field.type
										)
									}
									className='sr-only'
								/>
							</label>
							<span className='ml-2 text-sm text-gray-500'>
								{fieldValue || 'No file chosen'}
							</span>
						</div>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);

			// For any other field types, render a generic input
			default:
				return (
					<div className='mb-4' key={field.id}>
						<Label htmlFor={field.field_key}>
							{field.label}
							{field.is_required && (
								<span className='text-red-500 ml-1'>*</span>
							)}
						</Label>

						{field.description && (
							<p className='text-sm text-gray-500 mb-1'>{field.description}</p>
						)}

						<Input
							id={field.field_key}
							name={field.field_key}
							value={fieldValue}
							onChange={(e) =>
								handleInputChange(field.field_key, e.target.value, field.type)
							}
							placeholder={field.placeholder}
							className={fieldError ? 'border-red-500' : ''}
						/>

						{fieldError && (
							<div className='text-red-500 text-sm mt-1 flex items-center'>
								<FiAlertCircle className='mr-1' />
								{fieldError}
							</div>
						)}
					</div>
				);
		}
	};

	// If no form is loaded
	if (!form) {
		return (
			<div className='flex justify-center items-center h-64'>
				<p className='text-gray-500'>Please load a form to preview</p>
			</div>
		);
	}

	// If no fields are defined yet
	if (!fields || fields.length === 0) {
		return (
			<div className='flex justify-center items-center h-64 flex-col'>
				<FiInfo className='text-gray-400 mb-2' size={24} />
				<p className='text-gray-500'>This form doesn't have any fields yet</p>
			</div>
		);
	}

	return (
		<div className='max-w-3xl mx-auto'>
			<div className='bg-white p-6 rounded-lg shadow-sm'>
				<div className='mb-6'>
					<h1 className='text-2xl font-bold text-gray-900 mb-2'>{form.name}</h1>
					{form.description && (
						<p className='text-gray-600'>{form.description}</p>
					)}
				</div>

				<form onSubmit={handleSubmit}>
					{/* Form sections */}
					{sections && sections.length > 0 ? (
						<div className='space-y-8'>
							{sections
								.sort((a, b) => a.position - b.position)
								.map((section) => {
									const sectionFields = getFieldsForSection(section.id);

									if (sectionFields.length === 0) return null;

									return (
										<div
											key={section.id}
											className='border-b pb-6 mb-6 last:border-b-0'>
											<h2 className='text-lg font-semibold text-gray-800 mb-4'>
												{section.title}
											</h2>
											{section.description && (
												<p className='text-gray-600 mb-4'>
													{section.description}
												</p>
											)}

											<div className='space-y-4'>
												{sectionFields.map((field) => renderField(field))}
											</div>
										</div>
									);
								})}

							{/* Unassigned fields */}
							{getUnassignedFields().length > 0 && (
								<div className='space-y-4'>
									{getUnassignedFields().map((field) => renderField(field))}
								</div>
							)}
						</div>
					) : (
						// If no sections, just render all fields
						<div className='space-y-4'>
							{fields
								.sort((a, b) => a.position - b.position)
								.map((field) => renderField(field))}
						</div>
					)}

					<div className='mt-6 pt-6 border-t'>
						<Button type='submit' className='w-full md:w-auto'>
							Submit Form
						</Button>
					</div>
				</form>
			</div>

			<div className='mt-4 text-sm text-gray-500 text-center'>
				<p>This is a preview of how the form will look to users.</p>
				<p>Form submissions in preview mode are not saved.</p>
			</div>
		</div>
	);
};

export default FormPreview;
