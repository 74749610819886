import sidebarMenu1 from '../img/admin/sidebarMenu1.png';
import sidebarMenu2 from '../img/admin/sidebarMenu2.png';
import sidebarMenu3 from '../img/admin/sidebarMenu3.png';
import ActiveSideMenu1 from '../img/admin/ActiveSideMenu1.png';
import ActiveSideMenu2 from '../img/admin/ActiveSideMenu2.png';
import ActiveSideMenu3 from '../img/admin/ActiveSideMenu3.png';
// Adding form builder icon - using the same icon as create user temporarily
import sidebarMenu4 from '../img/admin/sidebarMenu3.png';
import ActiveSideMenu4 from '../img/admin/ActiveSideMenu3.png';
// import ActiveSideMenu2 from '../img/admin/ActiveSideMenu2.png';

const handleClick = () => {
	console.info('You clicked the super Chip.');
};

class Sidebar {
	static menus = [
		{
			image: `${sidebarMenu1}`,
			hoverimage: `${ActiveSideMenu1}`,
			name: 'Dashboard',
			hovercolor: 'Sidehovercolor',
			class: '',
			url: 'dashboard',
		},

		{
			image: `${sidebarMenu2}`,
			hoverimage: `${ActiveSideMenu2}`,
			name: 'Clients',
			hovercolor: 'Sidehovercolor',
			class: '',
			url: 'clients',
		},

		{
			image: `${sidebarMenu3}`,
			hoverimage: `${ActiveSideMenu3}`,
			name: 'Create User',
			hovercolor: 'Sidehovercolor',
			class: '',
			url: 'create-user',
		},

		{
			image: `${sidebarMenu4}`,
			hoverimage: `${ActiveSideMenu4}`,
			name: 'Form Builder',
			hovercolor: 'Sidehovercolor',
			class: '',
			url: 'form-builder',
		},
	];
}

export default Sidebar;
