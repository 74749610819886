import React, { useState, useEffect } from 'react';
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';
import Label from '../../components/ui/label';
import { FiSave, FiX, FiTrash2 } from 'react-icons/fi';

const FormSectionEditor = ({ section, onSave, onCancel, onDelete }) => {
	const isEditMode = Boolean(section);

	// Initialize section data state
	const [sectionData, setSectionData] = useState({
		title: '',
		description: '',
	});

	// Load section data when editing existing section
	useEffect(() => {
		if (section) {
			setSectionData({
				title: section.title || '',
				description: section.description || '',
			});
		}
	}, [section]);

	// Handle section input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setSectionData({
			...sectionData,
			[name]: value,
		});
	};

	// Handle save button click
	const handleSave = () => {
		// Validate section data
		if (!sectionData.title) {
			window.alert('Section title is required');
			return;
		}

		// Call the parent save handler
		onSave(sectionData);
	};

	return (
		<div>
			<div className='flex justify-between items-center mb-4'>
				<h2 className='text-xl font-semibold'>
					{isEditMode ? 'Edit Section' : 'Add Section'}
				</h2>
				<button
					onClick={onCancel}
					className='p-2 hover:bg-gray-100 rounded'
					aria-label='Close'>
					<FiX />
				</button>
			</div>

			<div className='mb-4'>
				<Label htmlFor='title'>Section Title</Label>
				<Input
					id='title'
					name='title'
					value={sectionData.title}
					onChange={handleInputChange}
					placeholder='Enter section title'
					required
				/>
			</div>

			<div className='mb-4'>
				<Label htmlFor='description'>Description</Label>
				<textarea
					id='description'
					name='description'
					value={sectionData.description}
					onChange={handleInputChange}
					placeholder='Description or instructions for this section'
					rows={3}
					className='w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-400'
				/>
			</div>

			<div className='flex justify-between mt-6'>
				{onDelete && (
					<Button
						onClick={onDelete}
						variant='outline'
						className='text-red-500 border-red-500 hover:bg-red-50 flex items-center'>
						<FiTrash2 className='mr-2' />
						Delete Section
					</Button>
				)}

				<div className='flex space-x-2'>
					<Button onClick={onCancel} variant='outline'>
						Cancel
					</Button>
					<Button onClick={handleSave} className='flex items-center'>
						<FiSave className='mr-2' />
						Save Section
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FormSectionEditor;
