/** @format */
import React, { useState } from 'react';
import ClientOtherInfoForm from './ClientOtherInfoForm.js';
import {
	FiEdit2,
	FiBriefcase,
	FiUsers,
	FiCalendar,
	FiDollarSign,
} from 'react-icons/fi';
import './Client.css';

const ClientOtherInfo = ({ user }) => {
	const [edit, setEdit] = useState(false);

	// Function to render a field with icon
	const renderField = (label, value, icon) => (
		<div className='bg-white rounded-sm p-4 border border-gray-100 hover:border-gray-200 hover:shadow-sm transition-all'>
			<div className='flex items-center mb-2'>
				{React.cloneElement(icon, { className: 'h-4 w-4 text-gray-900' })}
				<h3 className='text-sm font-montserrat-medium text-gray-900 ml-2'>
					{label}
				</h3>
			</div>
			<p className='text-gray-700 font-montserrat-regular'>
				{value || <span className='text-gray-400 italic'>Not provided</span>}
			</p>
		</div>
	);

	if (edit) {
		return <ClientOtherInfoForm user={user} setEdit={setEdit} />;
	}

	return (
		<div className='space-y-6'>
			<div className='flex justify-between items-center mb-4'>
				<h2 className='text-lg font-glacial text-gray-900'>
					Other Information
				</h2>
				<button
					onClick={() => setEdit(true)}
					className='flex items-center justify-center h-9 w-9 rounded-sm bg-gray-100 text-gray-900 hover:bg-gray-200 transition-colors'
					aria-label='Edit other information'>
					<FiEdit2 className='h-4 w-4' />
				</button>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				{renderField('Brokerage Name', user.brokerageName, <FiBriefcase />)}
				{renderField('Team Name', user.teamName, <FiUsers />)}
				{renderField('Years in Business', user.yearsInBusiness, <FiCalendar />)}
				{renderField(
					'Average Sales Price',
					user.averageSalesPrice ? `$${user.averageSalesPrice}` : null,
					<FiDollarSign />
				)}
			</div>
		</div>
	);
};

export default ClientOtherInfo;
