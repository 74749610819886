import React from 'react';
import PropTypes from 'prop-types';

const FormItem = ({ className = '', ...props }) => {
	return <div className={`space-y-2 ${className}`} {...props} />;
};

const FormLabel = ({ className = '', ...props }) => {
	return (
		<div
			className={`text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${className}`}
			{...props}
		/>
	);
};

const FormControl = ({ className = '', ...props }) => {
	return <div className={`mt-2 ${className}`} {...props} />;
};

const FormDescription = ({ className = '', ...props }) => {
	return <p className={`text-sm text-gray-500 ${className}`} {...props} />;
};

const FormMessage = ({ className = '', ...props }) => {
	return (
		<p className={`text-sm font-medium text-red-500 ${className}`} {...props} />
	);
};

const Form = ({ className = '', onSubmit, ...props }) => {
	return (
		<form onSubmit={onSubmit} className={`space-y-6 ${className}`} {...props} />
	);
};

Form.propTypes = {
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	children: PropTypes.node,
};

FormItem.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

FormLabel.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

FormControl.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

FormDescription.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

FormMessage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Form.Item = FormItem;
Form.Label = FormLabel;
Form.Control = FormControl;
Form.Description = FormDescription;
Form.Message = FormMessage;

export default Form;
