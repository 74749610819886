/** @format */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'fontsource-roboto';
import { Grid, Box, TextField, Typography } from '@material-ui/core';
import Sidebar from '../sidebar/Sidebar';
import clientHelper from '../../adminHelper/clientList';
import SearchIcon from '@mui/icons-material/Search';
import './Client.css';
import SublyApi from '../../helpers/Api';
import newLogo from '../../assets/marketing/newLogo.webp';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers } from '../../store/user';
import Card from '../../components/ui/card';
import Input from '../../components/ui/input';
import Avatar from '../../components/ui/avatar';
import {
	FiSearch,
	FiPlus,
	FiFilter,
	FiChevronLeft,
	FiChevronRight,
} from 'react-icons/fi';

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const ClientList = () => {
	const dispatch = useDispatch();
	const users = useSelector((st) => st.user.allUsers);
	const token = useSelector((st) => st.admin.token);
	const [clientList, setClientList] = useState(users);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(12);

	// Fetch users on component mount
	useEffect(() => {
		if (token) {
			dispatch(getAllUsers({ token }));
		}
	}, [token, dispatch]);

	// Update client list when users change
	useEffect(() => {
		setClientList(users);
	}, [users]);

	// Handle search input change
	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
		setCurrentPage(1); // Reset to first page on new search
	};

	// Filter clients based on search term
	const filteredClients = clientList.filter((client) => {
		if (searchTerm === '') {
			return true;
		}

		const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
		const searchLower = searchTerm.toLowerCase();

		return (
			fullName.includes(searchLower) ||
			client.firstName.toLowerCase().includes(searchLower) ||
			client.lastName.toLowerCase().includes(searchLower) ||
			client.email.toLowerCase().includes(searchLower)
		);
	});

	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredClients.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(filteredClients.length / itemsPerPage);

	// Change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className='space-y-6'>
			{/* Header with search and actions */}
			<div className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4'>
				<div className='relative w-full sm:w-96'>
					<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
						<FiSearch className='h-5 w-5 text-gray-400' />
					</div>
					<Input
						type='text'
						placeholder='Search clients...'
						className='pl-10 font-montserrat-regular'
						value={searchTerm}
						onChange={handleSearchChange}
					/>
				</div>

				<div className='flex gap-2'>
					<Button
						variant='secondary'
						size='sm'
						className='font-montserrat-medium'>
						<FiFilter className='mr-2 h-4 w-4' />
						Filter
					</Button>
					<Link to='/admin/create-user'>
						<Button
							variant='primary'
							size='sm'
							className='font-montserrat-medium'>
							<FiPlus className='mr-2 h-4 w-4' />
							Add Client
						</Button>
					</Link>
				</div>
			</div>

			{/* Client grid */}
			<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
				{currentItems.length > 0 ? (
					currentItems.map((client) => (
						<Card key={client.username} className='overflow-hidden'>
							<div className='p-6 flex flex-col items-center text-center'>
								<Avatar className='h-20 w-20 mb-4'>
									<Avatar.Image
										src={client.headshot || newLogo}
										alt={`${client.firstName} ${client.lastName}`}
									/>
									<Avatar.Fallback>
										{client.firstName && client.lastName
											? `${client.firstName[0]}${client.lastName[0]}`
											: 'CL'}
									</Avatar.Fallback>
								</Avatar>

								<h3 className='text-lg font-montserrat-medium text-gray-900'>
									{client.firstName} {client.lastName}
								</h3>

								<p className='text-sm font-montserrat-regular text-gray-500 mt-1 mb-4'>
									{client.email}
								</p>

								<div className='mt-auto'>
									<Link to={`/admin/clients/client-details/${client.username}`}>
										<Button
											variant='secondary'
											size='sm'
											className='w-full font-montserrat-medium'>
											View Details
										</Button>
									</Link>
								</div>
							</div>
						</Card>
					))
				) : (
					<div className='col-span-full flex justify-center items-center p-8'>
						<p className='text-gray-500 font-montserrat-regular'>
							No clients found matching your search.
						</p>
					</div>
				)}
			</div>

			{/* Pagination */}
			{filteredClients.length > itemsPerPage && (
				<div className='flex justify-center mt-6'>
					<nav className='flex items-center'>
						<Button
							variant='secondary'
							size='sm'
							onClick={() => paginate(currentPage - 1)}
							disabled={currentPage === 1}
							className='mr-2 font-montserrat-medium'>
							<FiChevronLeft className='mr-1 h-4 w-4' />
							Previous
						</Button>

						<div className='flex space-x-1'>
							{Array.from({ length: totalPages }, (_, i) => i + 1)
								.filter((num) => {
									// Show first page, last page, current page, and pages around current page
									return (
										num === 1 ||
										num === totalPages ||
										(num >= currentPage - 1 && num <= currentPage + 1)
									);
								})
								.map((number) => {
									// Add ellipsis
									const pages = [];
									if (
										number > 1 &&
										!Array.from({ length: totalPages }, (_, i) => i + 1)
											.filter((num) => {
												return (
													num === 1 ||
													num === totalPages ||
													(num >= currentPage - 1 && num <= currentPage + 1)
												);
											})
											.includes(number - 1)
									) {
										pages.push(
											<span
												key={`ellipsis-${number}`}
												className='px-3 py-1 font-montserrat-regular'>
												...
											</span>
										);
									}

									pages.push(
										<button
											key={number}
											onClick={() => paginate(number)}
											className={`px-3 py-1 rounded-sm font-montserrat-medium ${
												currentPage === number
													? 'bg-primary text-white'
													: 'text-gray-700 hover:bg-gray-100'
											}`}>
											{number}
										</button>
									);

									return pages;
								})}
						</div>

						<Button
							variant='secondary'
							size='sm'
							onClick={() => paginate(currentPage + 1)}
							disabled={currentPage === totalPages}
							className='ml-2 font-montserrat-medium'>
							Next
							<FiChevronRight className='ml-1 h-4 w-4' />
						</Button>
					</nav>
				</div>
			)}
		</div>
	);
};

export default ClientList;
