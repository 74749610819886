import React from 'react';

const TermsOfService = () => {
	return (
		<div className='container mx-auto px-4 pt-32 pb-8'>
			<div className='max-w-4xl mx-auto'>
				<h1 className='text-4xl font-bold mb-6 text-gray-900'>
					Terms of Service
				</h1>

				<p className='text-lg mb-8 text-gray-700'>
					Last Updated: February 24, 2025
				</p>

				<div>
					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						1. Agreement to Terms
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						These Terms of Service ("Terms") constitute a legally binding
						agreement between you ("you," "your," or "Client") and The Jane
						Rothe Co. ("we," "our," "us," or "Company") governing your access to
						and use of our marketing and related services. By using our
						services, you agree to be bound by these Terms. If you disagree with
						any part of these Terms, you may not use our services.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						2. Description of Services
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						We provide marketing and promotional services primarily for real
						estate professionals, which may include but are not limited to:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>Social media marketing and management</li>
						<li className='mb-3'>Direct mail marketing campaigns</li>
						<li className='mb-3'>
							Paid advertising services (including Google Ads)
						</li>
						<li className='mb-3'>Marketing strategy and consulting</li>
						<li className='mb-3'>Content creation and distribution</li>
						<li className='mb-3'>
							Other related marketing and promotional services
						</li>
					</ul>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We reserve the right to modify, suspend, or discontinue any aspect
						of our services at any time, with or without notice.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						3. Service Terms and Billing
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						Our services are provided on a month-to-month basis. By agreeing to
						these Terms, you acknowledge and agree to the following:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Services are billed monthly, with payment due in advance of
							service delivery
						</li>
						<li className='mb-3'>
							The billing cycle begins on your signup date and continues monthly
							thereafter
						</li>
						<li className='mb-3'>
							Additional costs for third-party services (such as advertising
							costs, printing, or postage) will be billed separately at the end
							of each month
						</li>
						<li className='mb-3'>
							No long-term contract is required; services may be cancelled
							before the next billing cycle
						</li>
						<li className='mb-3'>
							No refunds will be issued for unused portions of the current
							billing period
						</li>
						<li className='mb-3'>
							Failure to provide timely payment may result in service suspension
							or termination
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						4. Cancellation and Termination
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						You may cancel your services at any time before your next billing
						date. To cancel, you must provide written notice of cancellation
						before your next billing cycle begins. If you fail to cancel before
						the renewal date, you will be charged for the next billing period.
						We reserve the right to terminate or suspend services at any time
						for violation of these Terms, non-payment, or any other reason we
						deem appropriate, with or without notice.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						5. Service Delivery and Timeline
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						Our service delivery process includes the following terms:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Standard turnaround time for deliverables is 3-5 business days
							unless otherwise specified
						</li>
						<li className='mb-3'>
							Client feedback and approvals must be provided within 2 business
							days to maintain project timeline
						</li>
						<li className='mb-3'>
							Rush services may be available for an additional fee
						</li>
						<li className='mb-3'>
							We require minimum 48 hours notice for any campaign changes or
							pauses
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						6. Financial Terms
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						In addition to our standard billing terms:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Late payments will incur a 1.5% monthly interest charge
						</li>
						<li className='mb-3'>
							Service fees are subject to change with 30 days notice
						</li>
						<li className='mb-3'>
							Ad spend budgets must be funded 5 business days in advance
						</li>
						<li className='mb-3'>
							Additional revision requests beyond the scope may incur extra
							charges
						</li>
						<li className='mb-3'>
							Chargebacks or payment disputes may result in immediate service
							termination
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						7. Client Responsibilities
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						To enable us to provide our services effectively, you agree to:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Provide accurate and complete information necessary for service
							delivery
						</li>
						<li className='mb-3'>
							Respond to requests for approvals or information in a timely
							manner
						</li>
						<li className='mb-3'>
							Ensure all content provided to us complies with applicable laws
							and regulations
						</li>
						<li className='mb-3'>
							Maintain necessary licenses and permits for your real estate
							business
						</li>
						<li className='mb-3'>
							Notify us promptly of any changes that may affect our services
						</li>
						<li className='mb-3'>
							Comply with all applicable real estate marketing laws and
							regulations
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						8. Intellectual Property Rights
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						All materials, content, and deliverables we create in providing our
						services are protected by intellectual property laws. Upon full
						payment of applicable fees:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							You receive a non-exclusive license to use marketing materials we
							create for your business purposes
						</li>
						<li className='mb-3'>
							We retain ownership of all templates, processes, and methodologies
							used in creating your materials
						</li>
						<li className='mb-3'>
							You retain ownership of your pre-existing materials and content
							provided to us
						</li>
						<li className='mb-3'>
							We may showcase our work for your business in our portfolio unless
							you explicitly opt out
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						9. Representations and Warranties
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Each party represents and warrants that it has the legal power and
						authority to enter into this agreement. You represent and warrant
						that any content or materials you provide to us do not infringe upon
						any third party's rights. We warrant that our services will be
						performed in a professional manner consistent with industry
						standards. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, WE MAKE NO
						WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
						WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						10. Limitation of Liability
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY INDIRECT,
						INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT
						OF OR IN CONNECTION WITH THE SERVICES, INCLUDING BUT NOT LIMITED TO
						LOST PROFITS OR BUSINESS INTERRUPTION. OUR TOTAL LIABILITY UNDER
						THIS AGREEMENT SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE
						SERVICES IN THE TWELVE (12) MONTHS PRECEDING THE INCIDENT GIVING
						RISE TO THE CLAIM.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						11. Indemnification
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						You agree to indemnify, defend, and hold harmless The Jane Rothe
						Co., its officers, directors, employees, and agents from and against
						any claims, liabilities, damages, judgments, awards, losses, costs,
						expenses, or fees (including reasonable attorneys' fees) arising out
						of or relating to your violation of these Terms or your use of the
						Services, including, but not limited to, any content you provide or
						your violation of any rights of another.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						12. Confidentiality
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Each party agrees to maintain the confidentiality of any proprietary
						information received from the other party during the course of
						service provision. This includes business strategies, customer
						lists, pricing, and other confidential business information. This
						obligation shall survive the termination of our services.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						13. Force Majeure
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Neither party shall be liable for any failure or delay in
						performance under these Terms arising out of or caused by forces
						beyond its control, including but not limited to acts of God, war,
						strikes, labor disputes, civil unrest, natural disasters, pandemic
						diseases, or governmental demands or restrictions. If such a delay
						occurs, the affected party shall be entitled to extend the time for
						performance by the period of such delay.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						14. Dispute Resolution
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Before initiating any legal action, the parties agree to attempt in
						good faith to resolve any dispute arising out of or relating to
						these Terms through negotiation. If the dispute cannot be resolved
						through negotiation within 30 days, either party may initiate legal
						proceedings. Any dispute resolution proceedings shall be conducted
						on an individual basis and not in a class, consolidated, or
						representative action.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						15. Additional Legal Terms
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Non-Solicitation: During the term of services and for twelve (12)
						months thereafter, you agree not to directly or indirectly solicit
						or attempt to solicit any employees or contractors of The Jane Rothe
						Co.
					</p>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Assignment: These Terms and any rights or obligations hereunder may
						not be transferred or assigned by you without our prior written
						consent. We may assign our rights and obligations under these Terms
						without restriction.
					</p>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Survival: The provisions relating to Intellectual Property Rights,
						Limitation of Liability, Indemnification, Confidentiality, and any
						other provisions which by their nature should survive, shall survive
						the termination of these Terms.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						16. Third-Party Platforms
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						For services requiring access to your third-party platform accounts
						(such as social media, advertising platforms, or marketing tools),
						you agree to provide necessary credentials and maintain active
						accounts in good standing. We are not responsible for any issues
						arising from third-party platform changes, restrictions, or
						terminations. You remain responsible for all charges and fees
						associated with third-party platforms.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						17. Amendments and Modifications
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We reserve the right to modify these Terms at any time. We will
						notify you of any material changes by posting the updated Terms on
						our website and/or sending you an email notification. Your continued
						use of our services following the posting of modified Terms means
						that you accept and agree to the changes. If you do not agree to the
						modified terms, you must stop using the services.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						18. Entire Agreement
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						These Terms, together with our Privacy Policy and any other legal
						notices or additional terms and conditions or policies published by
						us on our website, shall constitute the entire agreement between you
						and The Jane Rothe Co. concerning the services. These Terms
						supersede all prior agreements, understandings, representations, and
						warranties, both written and oral, with respect to the services.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						19. Contact Information
					</h2>
					<p className='text-lg mb-2 leading-relaxed text-gray-700'>
						If you have any questions about these Terms of Service, please
						contact us at:
					</p>
					<div className='text-lg mb-8 leading-relaxed text-gray-700'>
						<p>The Jane Rothe Co.</p>
						<p>3338 Parque Way</p>
						<p>Sacramento, CA 95838</p>
						<p>Email: info@janerothe.co</p>
						<p>Phone: 916-716-4070</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermsOfService;
