import React, { useEffect } from 'react';
import SignUpBg from '../../assets/marketing/SignUpBg.webp';
import logo from '../../assets/marketing/logo.webp';
import UserIcon from '../../assets/marketing/UserIcon.svg';
import LockImg from '../../assets/marketing/LockImg.svg';
import EmailIcon from '../../assets/marketing/EmailIcon.svg';
import { AuthenticationField } from '../CommonComponents/CustomInputField';
import { AuthenticationButton } from '../CommonComponents/CustomButton';
import { useHistory, Link } from 'react-router-dom';
import { signupSchema } from '../../utils/validation/validationSchema';
import { clearErrors, userSignup } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

function SignUpSection() {
	const dispatch = useDispatch();
	const apiError = useSelector((st) => st.user.errors);
	const loading = useSelector((st) => st.user.pending);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(signupSchema),
	});

	const onSubmit = (data) => {
		dispatch(userSignup(data));
	};

	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

	const history = useHistory();

	if (loading) return <Loader></Loader>;

	return (
		<div
			className='flex  w-full h-[100vh] 
      mobile:block
      mobile:h-full'>
			<div
				className='w-[36%] h-[100vh] overflow-y-auto p-[50px]
         tablet:w-full
         tablet:p-[20px]
         mobile:w-full
         mobile:h-full
         mobile:p-[10px]'>
				<div
					className='w-[258px] h-auto ml-[-17px]'
					onClick={() => {
						history.push('/');
					}}>
					<img src={logo} alt='logo' className='w-full h-full m-[0]'></img>
				</div>

				<div
					className='w-full h-auto hidden mt-[25px] 
          tablet:block
          mobile:block
          '>
					<img
						src={SignUpBg}
						alt='SignUpBg'
						className=' w-full h-full m-[0] object-cover rounded-none
           tablet:w-full tablet:h-auto
           mobile:w-full mobile:h-auto'></img>
				</div>

				<div className='mt-[50px]'>
					<h1 className='font-glacial text-[45px] font-bold leading-[45px] text-clientNero m-0'>
						Sign Up
					</h1>
				</div>

				<div className='mt-[25px]'>
					<h1 className='font-glacial text-[22px] font-normal leading-[22px] text-clientDimGrey m-0'>
						Create your account!
					</h1>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='mt-[25px]'>
						<AuthenticationField
							name='username'
							placeholder='User name'
							icon={UserIcon}
							register={register}
							errors={errors}
						/>

						<AuthenticationField
							type='email'
							placeholder='Email'
							icon={EmailIcon}
							name='email'
							register={register}
							errors={errors}
						/>

						<AuthenticationField
							type='text'
							placeholder='First name'
							icon={UserIcon}
							name='firstName'
							register={register}
							errors={errors}
						/>

						<AuthenticationField
							type='text'
							placeholder='Last name'
							icon={UserIcon}
							name='lastName'
							register={register}
							errors={errors}
						/>

						<AuthenticationField
							type='password'
							placeholder='Password'
							icon={LockImg}
							name='password'
							register={register}
							errors={errors}
						/>
					</div>
					<div className='text-red-600 mt-3 '>
						{apiError.map((e, indx) => (
							<div key={indx}>
								<small>ERROR: {e}</small>
							</div>
						))}
					</div>
					<div className='mt-4 text-sm text-gray-600'>
						By signing up, you agree to our{' '}
						<Link
							to='/terms'
							className='text-primary hover:text-primary-dark underline'>
							Terms of Service
						</Link>{' '}
						and{' '}
						<Link
							to='/privacy'
							className='text-primary hover:text-primary-dark underline'>
							Privacy Policy
						</Link>
					</div>
					<AuthenticationButton type={'submit'} label='Sign Up' />
				</form>

				<div className='w-full flex justify-center  items-center mt-[25px]'>
					<div>
						<p className='font-glacial text-[14px] font-bold leading-[14px] text-clientDimGrey'>
							Already have an account?{' '}
							<span
								className='text-primary cursor-pointer'
								onClick={() => {
									history.push('/login');
								}}>
								Sign In
							</span>
						</p>
					</div>
				</div>
			</div>

			<div
				className='w-[64%] h-full  
        tablet:hidden
        mobile:hidden
        '>
				<img
					src={SignUpBg}
					alt='SignUpBg'
					className=' w-full h-full m-[0] object-cover rounded-none'></img>
			</div>
		</div>
	);
}

export default SignUpSection;
