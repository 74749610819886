import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config';
import axios from 'axios';

// Thunks for form operations with real API implementations
export const getForms = createAsyncThunk(
	'formBuilder/getForms',
	async ({ token }, { rejectWithValue }) => {
		try {
			// If the API endpoint isn't implemented or returns 401/404, this will throw
			const response = await axios.get(`${API_BASE_URL}/api/admin/forms`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		} catch (error) {
			// Log the error for debugging
			console.warn(
				'Error fetching forms:',
				error.response?.status,
				error.message
			);

			// Determine if the API is not available (401/404 indicates API might not be implemented)
			const apiNotAvailable =
				error.response?.status === 401 || error.response?.status === 404;

			if (apiNotAvailable) {
				console.info(
					'The forms API endpoint may not be fully implemented yet.'
				);
			}

			const errorMessage =
				error.response?.data?.error?.message || 'Failed to fetch forms';

			// Include apiNotAvailable flag in the rejection value
			return rejectWithValue({
				message: errorMessage,
				apiNotAvailable: apiNotAvailable,
			});
		}
	}
);

export const getFieldTypes = createAsyncThunk(
	'formBuilder/getFieldTypes',
	async ({ token }, { rejectWithValue }) => {
		try {
			try {
				// Try to get field types from the API
				const response = await axios.get(
					`${API_BASE_URL}/api/admin/forms/field-types`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				return response.data;
			} catch (apiError) {
				// If API endpoint doesn't exist yet, use fallback field types
				console.warn(
					'Field types API endpoint not implemented yet, using fallback data'
				);

				// Fallback field types based on the documentation
				return {
					fieldTypes: [
						{ id: 'text', label: 'Text Field' },
						{ id: 'textarea', label: 'Text Area' },
						{ id: 'number', label: 'Number' },
						{ id: 'email', label: 'Email' },
						{ id: 'phone', label: 'Phone' },
						{ id: 'date', label: 'Date' },
						{ id: 'select', label: 'Dropdown' },
						{ id: 'radio', label: 'Radio Buttons' },
						{ id: 'checkbox', label: 'Checkboxes' },
						{ id: 'file', label: 'File Upload' },
						{ id: 'address', label: 'Address' },
						{ id: 'propertyAddress', label: 'Property Address' },
						{ id: 'mlsNumber', label: 'MLS Number' },
						{ id: 'clientInfo', label: 'Client Information' },
						{ id: 'agentInfo', label: 'Agent Information' },
						{ id: 'transactionDetails', label: 'Transaction Details' },
						{ id: 'giftPreferences', label: 'Gift Preferences' },
						{ id: 'movingDetails', label: 'Moving Details' },
						{ id: 'signature', label: 'Signature' },
					],
				};
			}
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to fetch field types';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const getFormById = createAsyncThunk(
	'formBuilder/getFormById',
	async ({ id, token }, { rejectWithValue }) => {
		try {
			// Make the API call to get the form by ID
			const response = await axios.get(
				`${API_BASE_URL}/api/admin/forms/${id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// Get the form data from the response
			const data = response.data;

			// Ensure that fields, sections, and mappings are arrays even if not returned by API
			const fields = data.fields || [];
			const sections = data.sections || [];
			const fieldSectionMap = data.fieldSectionMap || [];

			// Field types (static list from docs)
			const fieldTypes = [
				{ id: 'text', label: 'Text Field' },
				{ id: 'textarea', label: 'Text Area' },
				{ id: 'number', label: 'Number' },
				{ id: 'email', label: 'Email' },
				{ id: 'phone', label: 'Phone' },
				{ id: 'date', label: 'Date' },
				{ id: 'select', label: 'Dropdown' },
				{ id: 'radio', label: 'Radio Buttons' },
				{ id: 'checkbox', label: 'Checkboxes' },
				{ id: 'file', label: 'File Upload' },
			];

			return {
				form: data.form,
				fields,
				sections,
				fieldSectionMap,
				fieldTypes,
			};
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message ||
				`Failed to fetch form with ID ${id}`;
			return rejectWithValue(errorMessage);
		}
	}
);

export const createForm = createAsyncThunk(
	'formBuilder/createForm',
	async ({ formData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/admin/forms`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to create form';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const updateForm = createAsyncThunk(
	'formBuilder/updateForm',
	async ({ id, formData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/admin/forms/${id}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to update form';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const deleteForm = createAsyncThunk(
	'formBuilder/deleteForm',
	async ({ id, token }, { rejectWithValue }) => {
		try {
			await axios.delete(`${API_BASE_URL}/api/admin/forms/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return { id };
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to delete form';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const createFormField = createAsyncThunk(
	'formBuilder/createFormField',
	async ({ formId, sectionId, fieldData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/admin/forms/${formId}/fields`,
				{
					...fieldData,
					section_id: sectionId,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to create form field';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const updateFormField = createAsyncThunk(
	'formBuilder/updateFormField',
	async ({ formId, fieldId, fieldData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/admin/forms/${formId}/fields/${fieldId}`,
				fieldData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to update form field';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const deleteFormField = createAsyncThunk(
	'formBuilder/deleteFormField',
	async ({ formId, fieldId, token }, { rejectWithValue }) => {
		try {
			await axios.delete(
				`${API_BASE_URL}/api/admin/forms/${formId}/fields/${fieldId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return { fieldId };
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to delete form field';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const createFormSection = createAsyncThunk(
	'formBuilder/createFormSection',
	async ({ formId, sectionData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/admin/forms/${formId}/sections`,
				sectionData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to create form section';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const updateFormSection = createAsyncThunk(
	'formBuilder/updateFormSection',
	async ({ formId, sectionId, sectionData, token }, { rejectWithValue }) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/admin/forms/${formId}/sections/${sectionId}`,
				sectionData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to update form section';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const deleteFormSection = createAsyncThunk(
	'formBuilder/deleteFormSection',
	async ({ formId, sectionId, token }, { rejectWithValue }) => {
		try {
			await axios.delete(
				`${API_BASE_URL}/api/admin/forms/${formId}/sections/${sectionId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return { sectionId };
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to delete form section';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

export const reorderFormFields = createAsyncThunk(
	'formBuilder/reorderFormFields',
	async ({ formId, sectionId, fieldPositions, token }, { rejectWithValue }) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/admin/forms/sections/${sectionId}/reorder`,
				{ fieldPositions },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return {
				fieldPositions,
				sectionId,
				...response.data,
			};
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message || 'Failed to reorder fields';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

// Add a new thunk for assigning a field to a section
export const assignFieldToSection = createAsyncThunk(
	'formBuilder/assignFieldToSection',
	async (
		{ formId, fieldId, sectionId, position, token },
		{ rejectWithValue }
	) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/admin/forms/${formId}/fields/${fieldId}/section`,
				{
					section_id: sectionId,
					position: position || 0,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message ||
				'Failed to assign field to section';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

// Add a new thunk for updating a field's position within a section
export const updateFieldPosition = createAsyncThunk(
	'formBuilder/updateFieldPosition',
	async (
		{ formId, fieldId, sectionId, position, token },
		{ rejectWithValue }
	) => {
		try {
			const response = await axios.put(
				`${API_BASE_URL}/api/admin/forms/sections/${sectionId}/fields/${fieldId}/position`,
				{ position },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			const errorMessage =
				error.response?.data?.error?.message ||
				'Failed to update field position';
			return rejectWithValue({ message: errorMessage });
		}
	}
);

// Slice definition
const formBuilderSlice = createSlice({
	name: 'formBuilder',
	initialState: {
		// If you're seeing forms in the UI without a working API, they come from this array.
		// Once the API endpoint is working, this should be an empty array.
		forms: [], // Remove mock data and leave this as an empty array when API is ready
		currentForm: null,
		formFields: [],
		formSections: [],
		fieldSectionMap: [],
		fieldTypes: [],
		status: 'idle',
		error: null,
		apiNotAvailable: false, // Flag to indicate if the API is not available
	},
	reducers: {
		clearCurrentForm: (state) => {
			state.currentForm = null;
			state.formFields = [];
			state.formSections = [];
			state.fieldSectionMap = [];
		},
		setFieldPositionInSection: (state, action) => {
			const { fieldId, sectionId, position } = action.payload;

			const mappingIndex = state.fieldSectionMap.findIndex(
				(mapping) =>
					mapping.field_id === fieldId && mapping.section_id === sectionId
			);

			if (mappingIndex !== -1) {
				state.fieldSectionMap[mappingIndex].position = position;
			}
		},
		removeFieldFromSection: (state, action) => {
			const { fieldId, sectionId } = action.payload;

			state.fieldSectionMap = state.fieldSectionMap.filter(
				(mapping) =>
					!(mapping.field_id === fieldId && mapping.section_id === sectionId)
			);
		},
	},
	extraReducers: (builder) => {
		builder
			// Loading states
			.addCase(getForms.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(getFormById.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(createForm.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(updateForm.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(deleteForm.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})

			// Success states
			.addCase(getForms.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.forms = action.payload.forms;
			})
			.addCase(getFormById.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.currentForm = action.payload.form;
				state.formFields = action.payload.fields;
				state.formSections = action.payload.sections;
				state.fieldSectionMap = action.payload.fieldSectionMap;
			})
			.addCase(createForm.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.forms.push(action.payload.form);
			})
			.addCase(updateForm.fulfilled, (state, action) => {
				state.status = 'succeeded';
				const index = state.forms.findIndex(
					(form) => form.id === action.payload.form.id
				);
				if (index !== -1) {
					state.forms[index] = action.payload.form;
				}
				if (
					state.currentForm &&
					state.currentForm.id === action.payload.form.id
				) {
					state.currentForm = action.payload.form;
				}
			})
			.addCase(deleteForm.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.forms = state.forms.filter(
					(form) => form.id !== action.payload.id
				);
				if (state.currentForm && state.currentForm.id === action.payload.id) {
					state.currentForm = null;
				}
			})

			// Field Operations
			.addCase(createFormField.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.formFields.push(action.payload.field);
				// If there's also a mapping, add it
				if (action.payload.mapping) {
					state.fieldSectionMap.push(action.payload.mapping);
				}
			})
			.addCase(updateFormField.fulfilled, (state, action) => {
				state.status = 'succeeded';
				const index = state.formFields.findIndex(
					(field) => field.id === action.payload.field.id
				);
				if (index !== -1) {
					state.formFields[index] = action.payload.field;
				}
			})
			.addCase(deleteFormField.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.formFields = state.formFields.filter(
					(field) => field.id !== action.payload.fieldId
				);
				// Also remove from fieldSectionMap
				state.fieldSectionMap = state.fieldSectionMap.filter(
					(mapping) => mapping.field_id !== action.payload.fieldId
				);
			})

			// Section Operations
			.addCase(createFormSection.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.formSections.push(action.payload.section);
			})
			.addCase(updateFormSection.fulfilled, (state, action) => {
				state.status = 'succeeded';
				const index = state.formSections.findIndex(
					(section) => section.id === action.payload.section.id
				);
				if (index !== -1) {
					state.formSections[index] = action.payload.section;
				}
			})
			.addCase(deleteFormSection.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.formSections = state.formSections.filter(
					(section) => section.id !== action.payload.sectionId
				);
				// Also remove all fields associated with this section
				state.fieldSectionMap = state.fieldSectionMap.filter(
					(mapping) => mapping.section_id !== action.payload.sectionId
				);
			})

			// Mapping Operations
			.addCase(assignFieldToSection.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Remove any existing mapping for this field
				state.fieldSectionMap = state.fieldSectionMap.filter(
					(mapping) => mapping.field_id !== action.payload.mapping.field_id
				);
				// Add the new mapping
				state.fieldSectionMap.push(action.payload.mapping);
			})
			.addCase(updateFieldPosition.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Find and update the mapping
				const mappingIndex = state.fieldSectionMap.findIndex(
					(mapping) =>
						mapping.field_id === action.payload.mapping.field_id &&
						mapping.section_id === action.payload.mapping.section_id
				);
				if (mappingIndex !== -1) {
					state.fieldSectionMap[mappingIndex] = action.payload.mapping;
				}
			})
			.addCase(reorderFormFields.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Update the field-section mappings with the new positions
				if (action.payload.fieldSectionMap) {
					state.fieldSectionMap = action.payload.fieldSectionMap;
				}
				// If sections are also updated, update them
				if (action.payload.sections) {
					state.formSections = action.payload.sections;
				}
			})

			// Field types
			.addCase(getFieldTypes.pending, (state) => {
				state.status = 'loading';
				state.error = null;
			})
			.addCase(getFieldTypes.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.fieldTypes = action.payload.fieldTypes;
			})
			.addCase(getFieldTypes.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to fetch field types';
			})

			// Error states
			.addCase(getForms.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to fetch forms';

				// If API is not available, indicate this in the state
				if (action.payload?.apiNotAvailable) {
					state.apiNotAvailable = true;
				}
			})
			.addCase(getFormById.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to fetch form details';
			})
			.addCase(createForm.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to create form';
			})
			.addCase(updateForm.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to update form';
			})
			.addCase(deleteForm.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to delete form';
			})
			.addCase(createFormField.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to create field';
			})
			.addCase(updateFormField.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to update field';
			})
			.addCase(deleteFormField.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to delete field';
			})
			.addCase(createFormSection.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to create section';
			})
			.addCase(updateFormSection.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to update section';
			})
			.addCase(deleteFormSection.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to delete section';
			})
			.addCase(assignFieldToSection.rejected, (state, action) => {
				state.status = 'failed';
				state.error =
					action.payload?.message || 'Failed to assign field to section';
			})
			.addCase(updateFieldPosition.rejected, (state, action) => {
				state.status = 'failed';
				state.error =
					action.payload?.message || 'Failed to update field position';
			})
			.addCase(reorderFormFields.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload?.message || 'Failed to reorder fields';
			});
	},
});

export const {
	clearCurrentForm,
	setFieldPositionInSection,
	removeFieldFromSection,
} = formBuilderSlice.actions;

export default formBuilderSlice.reducer;
