import React from 'react';
import PropTypes from 'prop-types';

const Avatar = React.forwardRef(({ className = '', ...props }, ref) => {
	return (
		<div
			ref={ref}
			className={`relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full bg-gray-100 ${className}`}
			{...props}
		/>
	);
});

Avatar.displayName = 'Avatar';

const AvatarImage = React.forwardRef(
	({ className = '', src, alt = '', ...props }, ref) => {
		const [hasError, setHasError] = React.useState(false);

		const handleError = () => {
			setHasError(true);
		};

		if (hasError) {
			return <AvatarFallback>{getInitials(alt)}</AvatarFallback>;
		}

		return (
			<div className='absolute inset-0 flex items-center justify-center'>
				<img
					ref={ref}
					src={src}
					alt={alt}
					className={`h-full w-full object-cover object-center ${className}`}
					onError={handleError}
					{...props}
				/>
			</div>
		);
	}
);

AvatarImage.displayName = 'AvatarImage';

const AvatarFallback = React.forwardRef(
	({ className = '', children, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={`absolute inset-0 flex h-full w-full items-center justify-center rounded-full bg-gray-100 text-gray-500 font-montserrat-medium ${className}`}
				{...props}>
				{children}
			</div>
		);
	}
);

AvatarFallback.displayName = 'AvatarFallback';

// Helper function to get initials from a name
const getInitials = (name) => {
	if (!name) return '';

	const parts = name.split(' ');
	if (parts.length === 1) {
		return parts[0].charAt(0).toUpperCase();
	}

	return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
};

Avatar.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

AvatarImage.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
};

AvatarFallback.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Avatar.Image = AvatarImage;
Avatar.Fallback = AvatarFallback;

export default Avatar;
