import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateUser, getAllUsers } from '../../store/user.js';
import { FiSave, FiX } from 'react-icons/fi';
import './Client.css';

const ClientBrokerForm = ({ user, setEdit }) => {
	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);
	const { username } = useParams();

	// Form setup with react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			brokerName: user.brokerName || '',
			brokerEmail: user.brokerEmail || '',
			brokerAddressOne: user.brokerAddressOne || '',
			brokerAddressTwo: user.brokerAddressTwo || '',
			brokerCity: user.brokerCity || '',
			brokerState: user.brokerState || '',
			brokerZip: user.brokerZip || '',
			brokerLicense: user.brokerLicense || '',
		},
	});

	// Submit form data
	const onSubmit = async (formData) => {
		try {
			await dispatch(
				updateUser({
					username,
					data: formData,
					token,
				})
			).unwrap();

			// Refresh user data
			dispatch(getAllUsers({ token }));

			// Return to view mode
			setEdit(false);
		} catch (error) {
			console.error('Error updating user:', error);
		}
	};

	// Form field component for consistent styling
	const FormField = ({
		label,
		name,
		type = 'text',
		required = false,
		placeholder = '',
		children,
	}) => {
		return (
			<div className='mb-4'>
				<label
					htmlFor={name}
					className='block text-sm font-montserrat-medium text-gray-700 mb-1'>
					{label} {required && <span className='text-red-500'>*</span>}
				</label>
				{children || (
					<input
						id={name}
						type={type}
						{...register(name, {
							required: required ? `${label} is required` : false,
						})}
						className={`w-full px-3 py-2 border ${
							errors[name] ? 'border-red-500' : 'border-gray-300'
						} rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 font-montserrat-regular`}
						placeholder={placeholder}
					/>
				)}
				{errors[name] && (
					<p className='mt-1 text-sm text-red-600 font-montserrat-regular'>
						{errors[name].message}
					</p>
				)}
			</div>
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
			<div className='flex justify-between items-center mb-6'>
				<h2 className='text-xl font-glacial font-semibold text-gray-900'>
					Edit Broker Information
				</h2>
				<div className='flex space-x-2'>
					<button
						type='button'
						onClick={() => setEdit(false)}
						className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-montserrat-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
						<FiX className='mr-2 h-4 w-4' />
						Cancel
					</button>
					<button
						type='submit'
						disabled={isSubmitting}
						className='inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-montserrat-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
						<FiSave className='mr-2 h-4 w-4' />
						Save Changes
					</button>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100 mb-6'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					Broker Information
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='Broker Name'
						name='brokerName'
						placeholder='Enter broker name'
					/>
					<FormField
						label='Broker Email'
						name='brokerEmail'
						type='email'
						placeholder='Enter broker email'
					/>
				</div>
			</div>

			<div className='bg-gray-50 p-4 rounded-sm border border-gray-100'>
				<h3 className='text-lg font-montserrat-medium text-gray-900 mb-4 pb-2 border-b border-gray-200 pl-3 border-l-4 border-l-primary'>
					Broker Address
				</h3>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
					<FormField
						label='Address Line 1'
						name='brokerAddressOne'
						placeholder='Street address'
					/>
					<FormField
						label='Address Line 2'
						name='brokerAddressTwo'
						placeholder='Apt, Suite, etc. (optional)'
					/>
					<FormField label='City' name='brokerCity' placeholder='City' />
					<FormField label='State' name='brokerState' placeholder='State' />
					<FormField label='Zip Code' name='brokerZip' placeholder='Zip Code' />
					<FormField
						label='License #'
						name='brokerLicense'
						placeholder='License number'
					/>
				</div>
			</div>
		</form>
	);
};

export default ClientBrokerForm;
