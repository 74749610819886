import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminLayout from '../admin/layout/AdminLayout';

const AdminRoute = ({ component: Component, ...rest }) => {
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);

	const withAdminLayout = (Component) => (props) => {
		return (
			<AdminLayout>
				<Component {...props} />
			</AdminLayout>
		);
	};

	return (
		<Route
			{...rest}
			render={
				currentAdmin && currentAdmin.username
					? withAdminLayout(Component)
					: () => <Redirect to='/admin/login' />
			}
		/>
	);
};

export default AdminRoute;
