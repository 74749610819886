'use client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signupSubcribeSchema } from '../../../utils/validation/validationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { addErrors, clearErrors } from '../../../store/user';
import WorkingHandVid from '../../../assets/marketing/Homepage-SignUpforEmail.mp4';
import React, { useState } from 'react';
import SublyApi from '../../../helpers/Api';
import { EMAIL_EXIST } from '../../../utils/constant/constants';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import janeRothePdf from "./../../../assets/Jane_Rothe's.pdf";
import Loader from '../../../utils/Loader';
import { Link } from 'react-router-dom';

export default function ContactForm() {
	const dispatch = useDispatch();
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [isErrorModalOpen, setErrorIsModalOpen] = useState(false);
	const [isErrorModalMsg, setErrorIsModalMsg] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [loader, setLoader] = useState(false);

	// Functions to open and close the success modal
	const openSuccessModal = () => setIsSuccessModalOpen(true);
	const closeSuccessModal = () => setIsSuccessModalOpen(false);

	// Functions to open and close the error modal
	const openErrorModal = () => setErrorIsModalOpen(true);
	const closeErrorModal = () => setErrorIsModalOpen(false);
	const apiError = useSelector((st) => st.user.errors);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(signupSubcribeSchema),
	});

	// sets error to null when the component first mounts and when it dismounts.
	React.useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors()); // dismount
		};
	}, []);

	const downloadFromAPI = () => {
		const pdfUrl = janeRothePdf; // Path relative to the public folder
		const link = document.createElement('a');
		link.href = pdfUrl;
		link.download = 'JaneRothe.pdf'; // Name of the downloaded file
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleConatctSubmit = async (data) => {
		setLoader(true);
		try {
			const response = await SublyApi.addNewContactToMailer({ data });
			setLoader(false);
			if (response?.bigmailer?.code == EMAIL_EXIST || response?.bigmailer?.id) {
				downloadFromAPI();
				reset();
				openSuccessModal();
				setIsChecked(false);
			} else {
				openErrorModal();
			}
		} catch (error) {
			setLoader(false);
			setErrorIsModalMsg(error);
			openErrorModal();
		}
	};

	return (
		<div className=' w-full mx-auto'>
			{loader ? (
				<div className='loader'>
					<Loader />
				</div>
			) : null}
			<div className='flex relative flex-col items-end self-stretch px-[50px] py-10  w-full text-lg text-white min-h-[586px]'>
				{/* <img
          loading="lazy"
          src={SignUpImg}
          className="m-0 rounded-none object-cover absolute inset-0 size-full"
        /> */}
				<video
					className='m-0 rounded-none object-cover absolute inset-0 size-full'
					autoPlay
					loop
					muted
					playsInline>
					<source src={WorkingHandVid} type='video/mp4' />
					{/* <source
            src="https://cdn.prod.website-files.com/617c3138ae96290382b971a0/6187ff1a80976a1fd1f8dcd5_how_to_tie_your_scarf_trimmed-transcode.webm"
            type="video/webm"
          /> */}
				</video>
				<div className='bg-[#00000090] opacity-80 p-4'>
					<form
						onSubmit={handleSubmit(handleConatctSubmit)}
						className='relative max-w-[300px] w-full '>
						<div className='text-red-600 mt-3 '>
							{/* {apiError.map((e, indx) => (
              <div key={indx}>
                <small>ERROR: {e}</small>
              </div>
            ))} */}
						</div>
						<h2 className='font-montserrat-regular text-white text-[25px] font-medium leading-[37.5px] text-left'>
							Sign Up for Marketing Insights
						</h2>
						<p className='font-montserrat-regular text-white text-[14px] font-normal leading-[21px] mt-2 text-left'>
							Join our exclusive list to receive the latest marketing strategies
							for real estate agents. Plus, get instant access to our Business
							Growth Action Plan — free.
						</p>

						<div className='mt-[25px]'>
							<input
								{...register('firstName')}
								type='text'
								id='firstName'
								placeholder='Enter your first name'
								className='font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none'
								onKeyDown={(e) => {
									if (e.key === ' ' && e.target.value.length === 0) {
										e.preventDefault();
									}
								}}
							/>
							{errors['firstName'] && (
								<p className='mt-1 text-sm text-red-600 text-left'>
									{errors['firstName'].message}
								</p>
							)}
						</div>

						<div className='mt-[15px]'>
							<input
								type='text'
								id='lastName'
								{...register('lastName')}
								placeholder='Enter your last name'
								className='font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none'
								onKeyDown={(e) => {
									if (e.key === ' ' && e.target.value.length === 0) {
										e.preventDefault();
									}
								}}
							/>
							{errors['lastName'] && (
								<p className='mt-1 text-sm text-red-600 text-left'>
									{errors['lastName'].message}
								</p>
							)}
						</div>

						<div className='mt-[15px]'>
							<input
								type='text'
								id='email'
								{...register('email')}
								placeholder='Enter your email address'
								className='font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none'
								onKeyDown={(e) => {
									if (e.key === ' ') {
										e.preventDefault();
									}
								}}
							/>
							{errors['email'] && (
								<p className='mt-1 text-sm text-red-600 text-left'>
									{errors['email'].message}
								</p>
							)}
						</div>
						<div className='mt-[10px] text-base leading-5'>
							<div className='flex items-center space-x-3'>
								<div className='self-start'>
									<input
										type='checkbox'
										id='customCheckbox'
										className="w-6 h-6 border-2 border-white rounded-md appearance-none cursor-pointer relative checked:border-white checked:bg-transparent checked:before:content-['✓'] checked:before:text-white checked:before:text-xl checked:before:font-bold checked:before:flex checked:before:absolute checked:before:top-1/2 checked:before:left-1/2 checked:before:transform checked:before:-translate-x-1/2 checked:before:-translate-y-1/2"
										checked={isChecked}
										onChange={(e) => setIsChecked(e.target.checked)}
									/>
								</div>
								<div className='font-montserrat-regular flex-auto text-[14px] font-normal leading-[17.5px] text-left'>
									By entering your email address you are accepting our{' '}
									<Link
										to='/terms'
										className='text-white hover:text-gray-200 underline'>
										Terms & Conditions
									</Link>{' '}
									and{' '}
									<Link
										to='/privacy'
										className='text-white hover:text-gray-200 underline'>
										Privacy Policy
									</Link>
								</div>
							</div>
						</div>

						<button
							type='submit'
							disabled={!isChecked}
							className={`px-[38px] mt-[39px] py-[14px] border-[0.8px] !border-[#FFFFFF] bg-transparent font-montserrat-regular max-w-[150px] w-full h-[45px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none text-[18px] font-normal leading-[18px] text-left 
                ${
									!isChecked
										? 'opacity-50 cursor-not-allowed'
										: 'hover:bg-white hover:text-black'
								}`}>
							Sign Up
						</button>
					</form>
				</div>
			</div>

			{isSuccessModalOpen && (
				<SuccessModal
					isModalOpen={isSuccessModalOpen}
					closeModal={closeSuccessModal}
				/>
			)}

			{isErrorModalOpen && (
				<ErrorModal
					isModalOpen={isErrorModalOpen}
					isErrorModalMsg={isErrorModalMsg}
					closeModal={closeErrorModal}
				/>
			)}
		</div>
	);
}
