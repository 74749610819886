import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ className = '', ...props }) => {
	return (
		<div className='w-full overflow-auto'>
			<table
				className={`w-full caption-bottom text-sm ${className}`}
				{...props}
			/>
		</div>
	);
};

const TableHeader = ({ className = '', ...props }) => {
	return <thead className={`${className}`} {...props} />;
};

const TableBody = ({ className = '', ...props }) => {
	return <tbody className={`${className}`} {...props} />;
};

const TableFooter = ({ className = '', ...props }) => {
	return (
		<tfoot
			className={`border-t font-montserrat-medium text-clientNero ${className}`}
			{...props}
		/>
	);
};

const TableRow = ({ className = '', ...props }) => {
	return (
		<tr
			className={`border-b transition-colors hover:bg-gray-50 data-[state=selected]:bg-gray-50 ${className}`}
			{...props}
		/>
	);
};

const TableHead = ({ className = '', ...props }) => {
	return (
		<th
			className={`h-12 px-4 text-left align-middle font-montserrat-medium text-clientNero ${className}`}
			{...props}
		/>
	);
};

const TableCell = ({ className = '', ...props }) => {
	return (
		<td className={`p-4 align-middle font-body ${className}`} {...props} />
	);
};

const TableCaption = ({ className = '', ...props }) => {
	return (
		<caption
			className={`mt-4 text-sm text-gray-500 font-body ${className}`}
			{...props}
		/>
	);
};

// Alias for TableHead to maintain compatibility with both naming conventions
const TableHeaderCell = TableHead;

Table.propTypes = {
	className: PropTypes.string,
};

TableHeader.propTypes = {
	className: PropTypes.string,
};

TableBody.propTypes = {
	className: PropTypes.string,
};

TableFooter.propTypes = {
	className: PropTypes.string,
};

TableRow.propTypes = {
	className: PropTypes.string,
};

TableHead.propTypes = {
	className: PropTypes.string,
};

TableHeaderCell.propTypes = {
	className: PropTypes.string,
};

TableCell.propTypes = {
	className: PropTypes.string,
};

TableCaption.propTypes = {
	className: PropTypes.string,
};

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Footer = TableFooter;
Table.Row = TableRow;
Table.Head = TableHead;
Table.HeaderCell = TableHeaderCell;
Table.Cell = TableCell;
Table.Caption = TableCaption;

export default Table;
