import axios from "axios";
import { BASE_URL } from "../config";

/** API Class.
 *
 * Static class tying together methods used to get/send to to the API.
 * There shouldn't be any frontend-specific stuff here, and there shouldn't
 * be any API-aware stuff elsewhere in the frontend....
 *
 */

class SublyApi {
  // the token for interactive with the API will be stored here.
  static token;

  static async request(endpoint, data = {}, method = "get", header) {
    console.debug("API Call:", endpoint, data, method);
    const url = `${BASE_URL}/${endpoint}`;
    const headers = header || { Authorization: `Bearer ${SublyApi.token}` };
    const params = method === "get" ? data : {};

    try {
      const res = await axios({ url, method, data, params, headers });
      return res ? res.data : "There is no return response for this route";
      // return (await axios({ url, method, data, params, headers })).data;
    } catch (err) {
      console.error("API Error:", err.response);
      let message = err.response?.data?.error?.message;

      if (message) {
        throw Array.isArray(message) ? message : [message];
      } else {
        throw err.response?.data?.message;
      }

      //If api throws and error(s), we extract the message(s) and rethrow the error.
      //   throw Array.isArray(message) ? message : [message];
    }
  }

  /** NOTE: The entire user object is
   *
   * user: { username, password, firstName, lastName, email, verified, customerId, phone, designations, certifications, specialties
   *
   * officeAddressOne, officeAddressTwo, officeCity, officeState, officeZip, mlsInfo, websiteUrl, googleBusinessUrl
   *
   * crmName, crmUsername, crmPassword, logo, headshot, brokerName, brokerAddressONe, brokerAddressTwo, brokerCity, brokerState,
   *
   * brokerZip, brokerLicence, brokerEmail, serviceAreas, uniqueness, favoritePlaces, travelDestinations, hobbies, otherDetails}
   *
   */

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /** POST /auth/userRegister:   { data } => { token, user }
   *
   * user data must include { username, password, firstName, lastName, email } or else you will receive bad request error
   *
   * (min, max) length restrictions ==> {email: (6, 30), username: (5, 20), password: (8,30), firstName & LastName: (2,20) ;
   *
   * email must be in email format
   *
   * Note: specific error messages come from JSONSCHEMA validation
   *
   * Authorization required: none
   *
   * @param data {Object} { username, password, firstName, lastName, email }
   * @returns {Object} {token, user (minus password)}
   */

  static async userSignup(data) {
    let res = await this.request(`auth/userRegister`, data, "post");
    return res;
  }

  /** POST /auth/userToken:  { username, password } => { token, user }
   *
   * user Login
   *
   * Authorization required: none
   *
   * @param data {Object} {username, password}
   * @returns {object} {token}
   */

  static async userLogin(data) {
    let res = await this.request(`auth/userToken`, data, "post");
    return res;
  }

  static async sendWelcomeEmail({ username, token }) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `auth/welcomeEmail`,
      { username },
      "post",
      header
    );
    return res;
  }

  static async sendNewUserEmail({ token }) {
    console.log("this is the token", token);
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`auth/adminEmail`, {}, "post", header);
    return res;
  }

  /******************************************************
   * Mailer API ROUTES
   *******************************************************/

  /** POST /mailer/add-contact
   *
   * Adds a new contact to the mailer list
   *
   * Authorization required: none
   *
   * @param data {Object} {firstName, lastName, email}
   * @returns {object} {message, bigmailer}
   */

  static async addNewContactToMailer({ data }) {
    let res = await this.request(`mailer/add-contact`, data, "post");
    return res;
  }

  /******************************************************
   * USERS API ROUTES
   *******************************************************/

  /** POST / { user }  => { user, token }
   *
   * Adds a new user. This is not the user registration endpoint --- instead, this is
   * only for admin users to add new users.
   *
   * This returns the newly created user and an authentication token for them:
   *  {user , token }
   *
   * Authorization required: approved admin
   **/

  static async adminUserSignup({ data, token }) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`users`, data, "post", header);
    return res;
  }

  /** GET / => { users: [ {username, firstName, lastName, email, verified... }, ... ] }
   *
   * Returns list of all users.
   *
   * Authorization required: approved admin
   *
   * @param token {string}
   * @returns {object} {users}
   **/

  static async getUsers(token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`users`, undefined, undefined, header);
    return res;
  }

  /** GET /[username] => { user, token }
   *
   * get a particular user using username
   *
   * Authorization required: approved admin or same user-as-:username
   *
   * !Note: we get token. May not be necessary
   *
   * @param token {string}
   * @param username {string}
   * @returns {object} {token, user (minus password)}
   **/

  static async getUser(username, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `users/${username}`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /** PATCH /[username] { user } => { user } or {user, token}
   *
   * User input data can include:
   *  { user (minus username)}
   *
   * Authorization required: Approved admin or same-user-as-:username
   *
   * @param token {String}
   * @param username {String}
   * @param data {Object} can include all user fields except for username.
   * @returns {object} { user (minus password) } OR { user (minus password), token  } if verified status is changed.
   **/

  static async updateUser(username, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`users/${username}`, data, "patch", header);
    return res;
  }

  /** PATCH /[username]/password { oldPassword, password } => { 'success' }
   *
   *
   * Authorization required: Approved admin or same-user-as-:username
   *
   * @param token {String}
   * @param username {String}
   * @param data {Object} includes only: {oldPassword, password}.
   * @returns {String} {message: 'success!' }
   **/

  static async changePassword(username, data, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `users/${username}/password`,
      data,
      "patch",
      header
    );
    return res;
  }

  /** /** POST /[username]/forgot-password  => { contact_id } (from Bigmailer)
   *
   * Route for sending forgot password email to user
   *
   * Authorization not required
   *
   * @param {*} username
   * @returns {object} {contact_id}(from bigMailer)
   */

  static async forgotPassword(username) {
    let res = await this.request(
      `users/${username}/forgot-password`,
      undefined,
      "post"
    );
    return res;
  }

  /** PATCH /[username] { data} => { user }
   *
   * Function for api call to update images
   *
   * @param username {String}
   * @param  data {Object} FormData object. expects a single field of type Blob for an image.
   * @param  token {String}
   * @returns {Object} {user (minus password)}
   */

  static async updateImages(username, data, token) {
    const header = {
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      Authorization: `Bearer ${token}`,
    };
    let res = await this.request(
      `users/images/${username}`,
      data,
      "patch",
      header
    );
    return res;
  }

  /******************************************************
   * Admin API ROUTES
   *******************************************************/

  /** sign admin up and get token  */
  /** POST /auth/adminRegister:   { data } => { token, admin }
   *
   * user data must include { username, password, firstName, lastName, email } or else you will receive bad request error
   *
   * (min, max) length restrictions ==> {email: (6, 30), username: (5, 20), password: (8,30), firstName & LastName: (2,20) ;
   *
   * email must be in email format
   *
   * Note: specific error messages come from JSONSCHEMA validation
   *
   * Authorization required: none
   *
   * @param data {Object} { username, password, firstName, lastName, email }
   * @returns {Object} {token, { username, firstName, lastName, email, isApproved, imageUrl }}
   */

  static async adminSignup(data) {
    let res = await this.request(`auth/adminRegister`, data, "post");
    return res;
  }

  /** log admin in and get token  */
  static async adminLogin(data) {
    let res = await this.request(`auth/adminToken`, data, "post");
    return res;
  }

  /** GET /[username] => { admin }
   *
   * get a particular admin using username
   *
   * Authorization required: approved admin
   *
   * @param token {string}
   * @param username {string}
   * @returns {object} {token, { username, first_name, last_name, email, isApproved, image_URL}}
   **/

  static async getAdmin(username, token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `admins/${username}`,
      undefined,
      undefined,
      header
    );
    return res;
  }

  /******************************************************
   * Stripe API ROUTES
   * Currently, products are stored on Stripe API
   *******************************************************/

  /** Get a list of all pricess from stripe, accounting for filtering data
   * filtering data options can be found here https://stripe.com/docs/api/prices/list?lang=node
   */
  static async getStripePrices(token, data = {}) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`stripe/get-prices`, data, undefined, header);
    return res;
  }

  /** Retrieves product subscriptions from the stripe API for each user.  Returns {stripeSubs: [array of stripe price objects]} */
  static async getStripeSubscriptions(customerId, token) {
    console.log("we are getting stripe subs");
    const header = { Authorization: `Bearer ${token}` };
    const data = { customerId };
    let res = await this.request(`stripe/get-user-subs`, data, "post", header);
    return res;
  }
  /** Retrieves product subscriptions from the stripe API for each user.  Returns {stripeSubs: [array of stripe price objects]} */
  static async getAllStripeSubscriptions(token) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`stripe/get-all-subs`, null, "get", header);
    return res;
  }

  /** creates a new stripe customer given email, and name */
  static async createCustomer(email, name, token) {
    const header = { Authorization: `Bearer ${token}` };
    const data = { email, name };
    let res = await this.request(
      `stripe/create-customer`,
      data,
      "post",
      header
    );
    return res.customerId;
  }

  /** forwards user to customer portal */
  static async customerPortal(customerId, token) {
    console.log("hi there");
    const header = { Authorization: `Bearer ${token}` };
    const data = { customerId };
    let res = await this.request(
      `stripe/customer-portal`,
      data,
      "post",
      header
    );
    return res;
  }

  /** creates checkout session
   * data= {priceId, customerId}
   */
  static async createCheckoutSession(data, token) {
    console.log("hi there");
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `stripe/create-checkout-session`,
      data,
      "post",
      header
    );
    return res;
  }

  /******************************************************
   * Stripe API ROUTES
   * Currently, products are stored on Stripe API
   *******************************************************/
  /** Get a list of all user's responses from typeform for a particular form,
   * data = {formId}
   * see responses at typeform dev api here: https://www.typeform.com/developers/responses/
   */
  static async getTypeformResponses({ username, formId, token }) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `typeform/get-responses/${username}`,
      { formId },
      undefined,
      header
    );
    return res;
  }

  /******************************************************
   * Product API ROUTES
   * Currently, these routes are unused, since we are not storing product data
   * !This is Depreciated
   *******************************************************/

  /** Get a list of all products, accounting for filtering data  (max price, description, title) */
  static async getProducts(token, data = {}) {
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(`products`, data, undefined, header);
    return res.products;
  }

  /** Applies to product given username and product id params */
  static async subscribeToProduct(username, productId, token) {
    console.log("we are attempting to subscribe");
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `users/${username}/products/${productId}`,
      undefined,
      "post",
      header
    );
    return res.subcribed;
  }

  /** Applies to product given username and product id params */
  static async unsubscribeFromProduct(username, productId, token) {
    console.log("we are attempting to unsubscribe");
    const header = { Authorization: `Bearer ${token}` };
    let res = await this.request(
      `users/${username}/products/${productId}`,
      undefined,
      "delete",
      header
    );
    return res.unsubcribed;
  }
}

// for now, put token ("testuser" / "password" on class)
SublyApi.token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZ" +
  "SI6InRlc3R1c2VyIiwiaXNBZG1pbiI6ZmFsc2UsImlhdCI6MTU5ODE1OTI1OX0." +
  "FtrMwBQwe6Ue-glIFgz_Nf8XxRT2YecFCiSpYL0fCXc";

SublyApi.adminToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6InBlYW51dCIsImlzQXBwcm92ZWQiOnRydWUsImlhdCI6MTY0OTM4OTY0NX0.HhdqAZHDs0gCryngCOVCsZyqqk9xqeLmgwgkY5P7LqM";

export default SublyApi;
