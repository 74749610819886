import React from 'react';

const PrivacyPolicy = () => {
	return (
		<div className='container mx-auto px-4 pt-32 pb-8'>
			<div className='max-w-4xl mx-auto'>
				<h1 className='text-4xl font-bold mb-6 text-gray-900'>
					Privacy Policy
				</h1>

				<p className='text-lg mb-8 text-gray-700'>
					Last Updated: February 24, 2025
				</p>

				<div>
					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						1. Introduction
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						The Jane Rothe Co. ("we," "our," or "us") respects your privacy and
						is committed to protecting it through our compliance with this
						policy. This policy describes the types of information we may
						collect from you or that you may provide when you use our services
						and our practices for collecting, using, maintaining, protecting,
						and disclosing that information.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						2. Information We Collect
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						We collect several types of information from and about our clients
						and their customers, including:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Personal identification information (name, email address,
							telephone number, postal address)
						</li>
						<li className='mb-3'>
							Business information related to real estate services
						</li>
						<li className='mb-3'>Payment and billing information</li>
						<li className='mb-3'>
							Customer data provided by our clients for marketing purposes
						</li>
						<li className='mb-3'>
							Usage details, IP addresses, and browser information
						</li>
						<li className='mb-3'>
							Information stored in cookies and tracking technologies
						</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						3. How We Use Your Information
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						We use the information we collect to:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>
							Provide, maintain, and improve our services
						</li>
						<li className='mb-3'>Process payments and send billing notices</li>
						<li className='mb-3'>
							Execute marketing campaigns on behalf of our clients
						</li>
						<li className='mb-3'>Communicate with you about our services</li>
						<li className='mb-3'>
							Analyze service usage and optimize user experience
						</li>
						<li className='mb-3'>Comply with legal obligations</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						4. Data Storage and Security
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We implement appropriate technical and organizational security
						measures to protect your personal information. While we strive to
						use commercially acceptable means to protect your information, no
						method of transmission over the Internet or electronic storage is
						100% secure.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						5. Cookies and Tracking Technologies
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We use cookies and similar tracking technologies to track activity
						on our services and hold certain information. Cookies are files with
						a small amount of data which may include an anonymous unique
						identifier. You can instruct your browser to refuse all cookies or
						to indicate when a cookie is being sent.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						6. Third-Party Services
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						We may engage various third-party service providers to support and
						enhance our operations, including but not limited to:
					</p>
					<ul className='list-disc ml-8 mb-4 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>Payment processing and billing services</li>
						<li className='mb-3'>
							Analytics and performance monitoring services
						</li>
						<li className='mb-3'>
							Marketing and advertising service providers
						</li>
						<li className='mb-3'>Email and communication service providers</li>
						<li className='mb-3'>Cloud storage and hosting services</li>
						<li className='mb-3'>Customer relationship management systems</li>
						<li className='mb-3'>Direct mail and printing service providers</li>
					</ul>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						These third-party services may have access to your information only
						to perform specific tasks on our behalf and are contractually
						obligated to maintain the confidentiality and security of your
						information. They are prohibited from using your personal
						information for any purpose other than providing services to us.
						However, we are not responsible for the privacy practices of these
						third parties, and we encourage you to review their respective
						privacy policies. We may update our selection of third-party service
						providers from time to time as necessary to maintain and improve our
						services.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						7. Data Retention and Deletion
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We retain your personal information only for as long as necessary to
						provide you with our services and as described in this Privacy
						Policy. When you terminate your services with us, we will delete
						your information from our active databases. However, some
						information may be retained in our files to prevent fraud,
						troubleshoot problems, assist with investigations, enforce our Terms
						of Service, and/or comply with legal requirements.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						8. Your Rights and Choices
					</h2>
					<p className='text-lg mb-4 leading-relaxed text-gray-700'>
						You have the right to:
					</p>
					<ul className='list-disc ml-8 mb-8 text-lg leading-relaxed text-gray-700'>
						<li className='mb-3'>Access your personal information</li>
						<li className='mb-3'>
							Correct inaccurate or incomplete information
						</li>
						<li className='mb-3'>
							Request deletion of your information upon service termination
						</li>
						<li className='mb-3'>Object to our use of your information</li>
						<li className='mb-3'>Request a copy of your information</li>
					</ul>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						9. Children's Privacy
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						Our services are not intended for use by individuals under the age
						of 18. We do not knowingly collect personal information from
						children. If we learn we have collected or received personal
						information from a child without verification of parental consent,
						we will take steps to delete that information. If you believe we
						might have any information from or about a child, please contact us.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						10. Data Security and Breach Procedures
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We maintain appropriate technical and organizational measures to
						protect your personal information. In the event of a data breach
						that affects your personal information, we will notify you and
						relevant authorities as required by applicable law. This
						notification will include the nature of the breach, likely
						consequences, and measures taken to address the breach.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						11. Marketing Communications
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						As part of our services, we may send you communications about our
						services, updates, and marketing materials. While service-related
						communications are necessary for maintaining our business
						relationship, you may opt out of marketing communications at any
						time by contacting us or using the unsubscribe methods provided in
						our marketing materials. Please note that even if you opt out of
						marketing communications, you will continue to receive
						service-related communications necessary for the administration of
						your account.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						12. Automated Decision Making and Analytics
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We may use automated systems and analytics to improve our services
						and marketing effectiveness. This may include automated analysis of
						client data to provide targeted marketing services, improve user
						experience, and optimize our operations. While we use these tools to
						enhance our services, significant decisions affecting your account
						or services will involve human review.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						13. International Data Processing
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						While we primarily operate in the United States, our data processing
						operations may involve transfers of personal information within our
						systems and to our third-party service providers. We ensure
						appropriate safeguards are in place for any data transfers and
						processing, in compliance with applicable data protection laws.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						14. California Privacy Rights
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						California residents have specific rights regarding their personal
						information under the California Consumer Privacy Act (CCPA). This
						includes the right to request disclosure of personal information
						collected, request deletion of personal information, and the right
						to opt-out of the sale of personal information. We do not sell
						personal information to third parties.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						15. Changes to Privacy Policy
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						We may update our Privacy Policy from time to time. We will notify
						you of any changes by posting the new Privacy Policy on this page
						and updating the "Last Updated" date. You are advised to review this
						Privacy Policy periodically for any changes.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						16. Governing Law and Jurisdiction
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						This Privacy Policy and any disputes related to it or our services
						shall be governed by and construed in accordance with the laws of
						the State of California, without regard to its conflict of law
						principles. Any legal action or proceeding arising out of or
						relating to this Privacy Policy shall be exclusively brought in the
						state or federal courts located in [specific California
						county/city], California, and you consent to the personal
						jurisdiction and venue of such courts.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						17. Severability
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						If any provision of this Privacy Policy is found to be unenforceable
						or invalid, that provision shall be limited or eliminated to the
						minimum extent necessary so that this Privacy Policy shall otherwise
						remain in full force and effect and enforceable.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						18. Entire Agreement
					</h2>
					<p className='text-lg mb-8 leading-relaxed text-gray-700'>
						This Privacy Policy, together with our Terms of Service, constitutes
						the entire agreement between you and The Jane Rothe Co. regarding
						the use of our services and the handling of your personal
						information. This Privacy Policy supersedes any prior agreements or
						understandings regarding the subject matter hereof.
					</p>

					<h2 className='text-2xl font-semibold mb-6 text-gray-900'>
						19. Contact Information
					</h2>
					<p className='text-lg mb-2 leading-relaxed text-gray-700'>
						If you have any questions about this Privacy Policy, please contact
						us at:
					</p>
					<div className='text-lg mb-8 leading-relaxed text-gray-700'>
						<p>The Jane Rothe Co.</p>
						<p>3338 Parque Way</p>
						<p>Sacramento, CA 95838</p>
						<p>Email: info@janerothe.co</p>
						<p>Phone: 916-716-4070</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
