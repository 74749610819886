import React, { useEffect, useRef, useState } from 'react';
// import MainLayout from "../../layouts/MainLayout";
// import LandingSection from "../../section/LandingSection";
import Header from './Header';

import HeroSection from './HeroSection';
import ContactForm from './ContactForm';

import whatWeDo from '../../../assets/marketing/what-we-do-new.webp';
import whatWeDoSec from '../../../assets/marketing/what-we-do-sub.webp';

import whatWeDo2 from '../../../assets/marketing/service2.webp';
import whatWeDoSec2 from '../../../assets/marketing/service2Sec.webp';

import whatWeDo3 from '../../../assets/marketing/service3.webp';
import whatWeDoSec3 from '../../../assets/marketing/service3sec.webp';

import broker1 from '../../../assets/marketing/logo_1.webp';
import broker2 from '../../../assets/marketing/logo_2.webp';
import broker3 from '../../../assets/marketing/logo_3.webp';
import broker4 from '../../../assets/marketing/logo_4.webp';
import broker5 from '../../../assets/marketing/logo_5.webp';
import broker6 from '../../../assets/marketing/logo_6.webp';
import broker7 from '../../../assets/marketing/logo_7.webp';
import broker8 from '../../../assets/marketing/logo_8.webp';
import broker9 from '../../../assets/marketing/logo_9.webp';
import JaneImg from '../../../assets/marketing/jane.webp';
import homeImg from '../../../assets/marketing/image_2.webp';
import mobileImg from '../../../assets/marketing/img_1.webp';
import digital from '../../../assets/marketing/digitalImg.webp';
import houseImg from '../../../assets/marketing/image_1.webp';
import lineImg from '../../../assets/marketing/Line.webp';
import arrow from '../../../assets/marketing/Arrow 1.webp';
import dimond from '../../../assets/marketing/dimond.svg';
import gift from '../../../assets/marketing/gift.svg';
import refund from '../../../assets/marketing/refund.svg';
import strategy from '../../../assets/marketing/strategy-success-plan.svg';
// import flexible from '../../../assets/marketing/flexible.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import LandingFooter from '../../landingFooter/LandingFooter';

import './styles.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const serviceItems = [
	{
		image1: whatWeDo,
		image2: whatWeDoSec,
		title: 'The Power of Stories and Connections',
		desc: "At The Jane Rothe Co., we recognize that stories are more than just words—they are powerful tools that connect people, build trust, and keep you top of mind. Our marketing approach is deeply rooted in storytelling, crafting narratives that resonate with potential clients and strengthen connections within your network so that when real estate needs arise, you're the first person they think of and call.",
	},
	{
		image1: whatWeDo2,
		image2: whatWeDoSec2,
		title: 'Empowering Real Estate Agents to Build Lasting Relationships',
		desc: 'At The Jane Rothe Co., we empower real estate agents with tools and strategies to build lasting client relationships. We help agents foster trust and loyalty in their communities, focusing on long-term connections that drive referrals and repeat business rather than one-time transactions.',
	},
	{
		image1: whatWeDo3,
		image2: whatWeDoSec3,
		title:
			'Blending the Familiarity of Traditional Marketing with Cutting-Edge Strategies',
		desc: "Our approach combines the best of both worlds: the reliability of traditional marketing with the innovation of modern strategies. At The Jane Rothe Co., we leverage time-tested methods that build trust and familiarity while integrating fresh, forward-thinking techniques tailored to today's fast-paced real estate market. By staying on top of emerging trends and technologies, we ensure that our clients are not just keeping up but leading the way in their marketing efforts.",
	},
];

const LandingPage = () => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceItems.length);
	};
	const images = [
		broker1,
		broker2,
		broker3,
		broker4,
		broker5,
		broker6,
		broker7,
		broker8,
		broker9,
	];

	const settings = {
		infinite: true, // Infinite loop
		speed: 5000, // Slow & smooth transition
		slidesToShow: 5, // Number of images visible at a time
		slidesToScroll: 1, // Move one image at a time
		autoplay: true, // Enable auto-scroll
		autoplaySpeed: 0, // Instant transition (continuous)
		cssEase: 'linear', // Smooth movement
		pauseOnHover: false, // No pause on hover
		arrows: false, // Hide navigation arrows
		dots: false, // Hide navigation dots
		responsive: [
			{
				breakpoint: 1024, // Large screens (tablets, smaller desktops)
				settings: {
					slidesToShow: 4, // Show 4 slides
				},
			},
			{
				breakpoint: 768, // Medium screens (tablets)
				settings: {
					slidesToShow: 3, // Show 3 slides
					speed: 4000, // Slightly faster for smaller screens
				},
			},
			{
				breakpoint: 640, // Small screens (phones)
				settings: {
					slidesToShow: 2, // Show 2 slides
					speed: 3000, // Faster for mobile
				},
			},
			{
				breakpoint: 480, // Extra small screens
				settings: {
					slidesToShow: 1, // Show 1 slide
					speed: 2000, // Fastest for smallest screens
				},
			},
		],
	};

	return (
		<>
			<div className='min-h-screen flex flex-col bg-white w-full mx-auto'>
				<div className='mx-auto text-justify max-w-7xl flex overflow-hidden flex-col w-full items-center bg-white'>
					<Header></Header>
					<HeroSection></HeroSection>
					<main className='absolute top-[calc(100vh)] mx-auto w-full'>
						<div>
							{/* Desktop "What We Do" Section */}
							<div className='relative bg-alabaster pt-[168px] pb-[168px] w-full maxdesktop:hidden'>
								{/* Jane's Image (commented out) */}
								{/* <img
									className="absolute max-w-[130.1px] w-full z-10 h-[242px] top-[30px] right-[150px] xl:right-[200px]"
									src={JaneImg}
								></img> */}

								{/* Main Content Container */}
								<div className='w-full'>
									{/* White Background Card */}
									<div className='relative aspect-[295/128]  max-w-[1180px] mx-auto  w-full bg-aliceBlue flex flex-wrap'>
										{/* Left Side - Black Card with Image */}
										<div className='w-full max-w-[440px] h-full'>
											{/* Black Card Container */}
											<div className='absolute aspect-[420/632] max-w-[420px] flex flex-col left-[20px] bottom-[-52px] justify-between  w-full bg-raisinBlack'>
												{/* "What we do" Title */}
												<div className='font-canela-trial text-[32px] font-light leading-[32px] text-white  my-auto pt-[15px]  flex  justify-center text-center'>
													What we do
												</div>
												{/* Main Feature Image */}
												<div className='max-w-[390px] aspect-[390/547] w-full m-[15px]'>
													<img
														className='w-full rounded-none p-0 m-0 h-full'
														alt='what we do service'
														src={serviceItems[currentIndex].image1}
													/>
												</div>
											</div>
										</div>
										{/* Jane's Image (Absolute Positioned) */}
										<img
											className='absolute max-w-[130.1px] w-full z-10 h-[242px] top-[-142px] right-[120px]'
											src={JaneImg}></img>

										{/* Right Side Content */}
										<div className='flex flex-wrap aspect-[740/512] max-w-[740px] w-full '>
											{/* Text Content Area */}
											<div className='flex flex-col pb-[22px] pt-[12px] pl-[50px] h-full max-w-[355px] w-full font-montserrat-regular text-left'>
												{/* Title */}
												<div className='text-[20px] mb-[25px] font-semibold  leading-[26px]'>
													{serviceItems[currentIndex].title}
												</div>
												{/* Description */}
												<div className='text-[16px] grow font-body leading-[22.4px] text-[#333333]'>
													{serviceItems[currentIndex].desc}
												</div>
												{/* "View next" Button */}
												<div
													className='max-w-[170px] max-h-[50px] h-full flex 
													items-center justify-center border-[0.8px] border-[solid] border-[#252525]'>
													<button
														onClick={handleNext}
														className='text-[16px] font-normal leading-[16px] text-center h-[50px]'>
														View next{' '}
														<ArrowForwardIosIcon className='w-[16px] h-auto opacity-[0px] text-[#252525]' />{' '}
													</button>
												</div>
											</div>
											{/* Secondary Image Container */}
											<div className='w-full aspect-[385/232]  flex flex-col max-w-[385px]'>
												<div className='grow h-6'></div>
												{/* Black Card for Secondary Image */}
												<div className='bg-raisinBlack max-w-[343px] w-full aspect-[343/232] mr-[20px] mb-[28px] ml-[22px] p-[8px] pr-[29px]'>
													<div className='aspect-[306/216] w-full max-w-[306px] '>
														<img
															alt='what we do service second'
															className='w-full rounded-none p-0 m-0 h-full object-center'
															src={serviceItems[currentIndex].image2}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='bg-alabaster py-[60px] sm:py-[80px] md:py-[100px] w-full mindesktop:hidden px-[20px]'>
								<div className='bg-aliceBlue flex md:flex-row flex-col p-[20px] md:p-[30px]'>
									<div className='w-full max-w-[420px] md:max-w-[380px] bg-raisinBlack relative z-10 mx-auto'>
										<div className='font-canela-trial text-[32px] font-light leading-[32px] text-white my-auto pt-[15px] flex justify-center text-center'>
											What We Do
										</div>
										<div className='p-[10px]'>
											<img
												alt='homeImg'
												src={serviceItems[currentIndex].image1}
												className='w-full max-w-[390px] md:max-w-[360px] h-full object-cover m-0 rounded-[2px] hover-transform-3d !mx-auto'
											/>
										</div>
									</div>
									<div className='max-w-[355px] md:max-w-[330px] w-full font-montserrat-regular text-left md:ml-[30px] h-full mt-[30px] md:mt-0 mx-auto'>
										<div className='text-[22px] mb-[20px] font-medium leading-[28px]'>
											{serviceItems[currentIndex].title}
										</div>
										<div className='text-[17px] md:text-[16px] grow font-body leading-[24px] md:leading-[22px] text-[#333333] md:h-[320px] overflow-y-auto pr-[10px]'>
											{serviceItems[currentIndex].desc}
										</div>
										<div className='max-w-[170px] max-h-[50px] h-full flex items-center justify-center border-[0.8px] border-[solid] border-[#252525] mt-[30px]'>
											<button
												onClick={handleNext}
												className='text-[16px] font-normal leading-[16px] text-center h-[50px] w-full'>
												View next{' '}
												<ArrowForwardIosIcon className='w-[16px] h-auto opacity-[0px] text-[#252525]' />{' '}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='w-full bg-raisinBlack py-[40px] px-[20px]'>
							<div className='font-canela-trial text-[28px] sm:text-[32px] font-light leading-[32px] text-center text-white pb-[30px] sm:pb-[50px]'>
								Our Broker Partners
							</div>

							<div className='w-full overflow-hidden px-2 sm:px-4 md:px-8'>
								<Slider {...settings}>
									{images.concat(images).map((image, index) => (
										<div
											key={index}
											className='px-4 sm:px-6 md:px-8 flex justify-center'>
											<img
												src={image}
												alt={`broker${index + 1}`}
												className='m-0 w-auto h-[40px] sm:h-[45px] md:h-[52px] rounded-none transform hover:scale-110 transition duration-300 opacity-50 hover:opacity-100'
											/>
										</div>
									))}
								</Slider>
							</div>
						</div>

						<div className='w-full py-10 sm:py-14 lg:py-20 bg-white'>
							<div className='w-full px-[20px] sm:px-[30px] md:px-[40px] lg:px-[60px] xl:px-[80px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-y-8 sm:gap-x-5 lg:gap-x-8'>
								<div className='col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-3'>
									<div className='w-full mx-auto lg:mx-0'>
										<h2 className='font-canela-trial text-[28px] sm:text-[32px] lg:text-[36px] font-light leading-[1.3] text-center lg:text-left'>
											Our Services
										</h2>
										<p className='font-montserrat-regular max-w-[280px] lg:max-w-[320px] w-full text-[16px] lg:text-[18px] font-medium leading-[1.4] text-[#333333] my-[25px] sm:my-[37px] text-center lg:text-left mx-auto lg:mx-0'>
											All services are monthly subscriptions. We create
											flexibility to meet your needs as your business grows.
										</p>

										<div
											className='max-w-[170px] lg:max-w-[200px] max-h-[50px] lg:max-h-[60px] h-full backdrop-filter backdrop-blur-[10px] flex 
                          items-center justify-center border-[0.8px] border-raisinBlack mx-auto lg:mx-0'>
											<button className='bg-[#FFFFFF0D] font-montserrat-regular text-[16px] lg:text-[18px] font-normal leading-[16px] text-center h-[50px] lg:h-[60px] w-full text-raisinBlack'>
												<Link to='/services'>View more </Link>
											</button>
										</div>

										<img
											alt='arrow'
											src={arrow}
											className='m-0 p-0 rounded-none w-full object-contain max-w-[235px]'
										/>
									</div>
								</div>
								<div className='col-span-12 lg:col-span-6'>
									<div className='relative w-full desktop:mx-[auto] desktop:max-w-[520px]'>
										<div>
											<div className='w-full max-w-[510px] bg-raisinBlack z-10 relative'>
												<div className='p-[10px]'>
													<img
														alt='houseImg'
														src={houseImg}
														className='w-full max-w-[486px] filter brightness-[1.3] h-[182px] object-cover m-0 rounded-[2px] hover-transform-3d '
													/>
													<p className='font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite'>
														The Premier Package
													</p>
													<p className='font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite'>
														$850
													</p>
												</div>
											</div>
										</div>
										<div className='flex mt-[70px] w-full max-w-[510px] justify-between desktop:mt-[20px] smallView:flex-col'>
											<div className='w-full max-w-[217px] h-[319px] bg-raisinBlack z-10 smallView:mx-auto mobile:mt-[0px] smallView:max-w-[unset] smallView:h-full'>
												<div className='p-[10px]'>
													<img
														alt='mobileImg'
														src={mobileImg}
														className='w-full max-w-[201px] h-[231px] object-cover m-0 rounded-[2px] hover-transform-3d smallView:max-w-[unset] smallView:h-full'
													/>
													<p className='font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite'>
														Social Media
													</p>
													<p className='font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite'>
														$395
													</p>
												</div>
											</div>
											<div className='w-full max-w-[224px] desktop:max-w-[217px] desktop:h-[319px] bg-raisinBlack z-10 ml-[20px] smallView:mt-[20px] smallView:max-w-[unset] smallView:mx-auto smallView:h-full'>
												<div className='p-[10px]'>
													<img
														alt='digital'
														src={digital}
														className='w-full max-w-[208px] filter brightness-[1.3] h-[287px] desktop:max-w-[201px] desktop:h-[231px] object-cover m-0 rounded-[2px] hover-transform-3d smallView:max-w-[unset] smallView:h-full'
													/>
													<p className='font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite'>
														Digital Billboards
													</p>
													<p className='font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite'>
														$150
													</p>
												</div>
											</div>
										</div>
										<img
											alt='lineImg'
											src={lineImg}
											className='absolute top-[130px] left-[60px] w-full h-[300px] m-0 transform rotate-[-33.35] rounded-none desktop:hidden'
										/>
									</div>
								</div>
								<div className='col-span-12 lg:col-span-3 desktop:hidden'>
									<div className='w-full max-w-[297px] bg-raisinBlack mt-[160px] relative z-10 desktop:mt-[20px]'>
										<div className='p-[10px]'>
											<img
												alt='homeImg'
												src={homeImg}
												className='w-full max-w-[273px] filter brightness-[1.3] h-[402px] object-cover m-0 rounded-[2px] hover-transform-3d'
											/>
											<p className='font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite'>
												Brokerage Package
											</p>
											<p className='font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite'>
												$1,600
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='bg-clientNero w-full'>
							<div className='w-full px-[40px] py-[40px] grid grid-cols-12 sm:gap-x-5 gap-y-7 tabletSlider:px-[128px]'>
								<div className='col-span-12 md:col-span-4'>
									<img
										alt='dimond'
										src={dimond}
										className='w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]'
									/>
									<h3 className='font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite'>
										Superior Quality
									</h3>
									<p className='font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]'>
										We take pride in our process to ensure we deliver services
										of the highest quality and excellence to our clients.
									</p>
								</div>
								<div className='col-span-12 md:col-span-4'>
									<img
										alt='strategy'
										src={strategy}
										className='w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]'
									/>
									<h3 className='font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite'>
										Custom Strategy
									</h3>
									<p className='font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]'>
										We create tailored marketing plans that align with your
										unique brand, market, and business goals.
									</p>
								</div>
								<div className='col-span-12 md:col-span-4'>
									<img
										alt='flexible'
										src={refund}
										className='w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]'
									/>
									<h3 className='font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite'>
										Flexible Terms
									</h3>
									<p className='font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]'>
										No long-term contracts required. Our month-to-month service
										gives you the freedom to adjust or cancel anytime.
									</p>
								</div>
							</div>
						</div>
						<ContactForm></ContactForm>
						<LandingFooter></LandingFooter>
					</main>
				</div>
			</div>
		</>
	);
};

export default LandingPage;
