/** @format */
import React, { useState } from 'react';
import ClientBusinessInfoForm from './ClientBusinessInfoForm.js';
import {
	FiEdit2,
	FiPhone,
	FiAward,
	FiCheckSquare,
	FiTarget,
	FiMapPin,
	FiGlobe,
	FiDatabase,
	FiLock,
} from 'react-icons/fi';
import './Client.css';

const ClientBusinessInfo = ({ user }) => {
	const [edit, setEdit] = useState(false);

	// Function to render a field with icon
	const renderField = (label, value, icon) => (
		<div className='bg-white rounded-sm p-4 border border-gray-100 hover:border-gray-200 hover:shadow-sm transition-all'>
			<div className='flex items-center mb-2'>
				{React.cloneElement(icon, { className: 'h-4 w-4 text-gray-900' })}
				<h3 className='text-sm font-montserrat-medium text-gray-900 ml-2'>
					{label}
				</h3>
			</div>
			<p className='text-gray-700 font-montserrat-regular'>
				{value || <span className='text-gray-400 italic'>Not provided</span>}
			</p>
		</div>
	);

	if (edit) {
		return <ClientBusinessInfoForm user={user} setEdit={setEdit} />;
	}

	return (
		<div className='space-y-6'>
			<div className='flex justify-between items-center mb-4'>
				<h2 className='text-lg font-glacial text-gray-900'>
					Business Information
				</h2>
				<button
					onClick={() => setEdit(true)}
					className='flex items-center justify-center h-9 w-9 rounded-sm bg-gray-100 text-gray-900 hover:bg-gray-200 transition-colors'
					aria-label='Edit business information'>
					<FiEdit2 className='h-4 w-4' />
				</button>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				{renderField('Primary Phone', user.phone, <FiPhone />)}
				{renderField('Designations', user.designations, <FiAward />)}
				{renderField('Certifications', user.certifications, <FiCheckSquare />)}
				{renderField('Real Estate Specialties', user.specialties, <FiTarget />)}
			</div>

			<h3 className='text-md font-montserrat-medium text-gray-900 mt-6 mb-3 border-b border-gray-100 pb-2'>
				Office Location
			</h3>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				{renderField('Address Line 1', user.officeAddressOne, <FiMapPin />)}
				{renderField('Address Line 2', user.officeAddressTwo, <FiMapPin />)}
				{renderField('City', user.officeCity, <FiMapPin />)}
				{renderField('State', user.officeState, <FiMapPin />)}
				{renderField('Zip Code', user.officeZip, <FiMapPin />)}
				{renderField('MLS Association', user.mlsInfo, <FiDatabase />)}
			</div>

			<h3 className='text-md font-montserrat-medium text-gray-900 mt-6 mb-3 border-b border-gray-100 pb-2'>
				Online Presence
			</h3>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				{renderField('Website URL', user.websiteUrl, <FiGlobe />)}
				{renderField(
					'Google Business Page',
					user.googleBusinessUrl,
					<FiGlobe />
				)}
			</div>

			<h3 className='text-md font-montserrat-medium text-gray-900 mt-6 mb-3 border-b border-gray-100 pb-2'>
				CRM Information
			</h3>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
				{renderField('CRM Name', user.crmName, <FiDatabase />)}
				{renderField('CRM Login Username', '••••••••••••••', <FiLock />)}
				{renderField('CRM Password', '••••••••••••••', <FiLock />)}
			</div>
		</div>
	);
};

export default ClientBusinessInfo;
