import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
	children,
	variant = 'primary',
	size = 'md',
	className = '',
	disabled = false,
	type = 'button',
	onClick,
	...props
}) => {
	const baseStyles =
		'inline-flex items-center justify-center rounded-sm font-montserrat-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none';

	const variants = {
		primary:
			'bg-clientBlack text-white hover:bg-gray-900 focus-visible:ring-gray-800',
		secondary:
			'bg-white text-clientBlack border border-clientBlack hover:bg-gray-50 focus-visible:ring-gray-500',
		outline:
			'border border-gray-300 bg-transparent hover:bg-gray-50 focus-visible:ring-gray-500',
		accent:
			'bg-primary text-white hover:bg-primary/90 focus-visible:ring-primary/50',
		ghost: 'bg-transparent hover:bg-gray-50 text-clientNero',
		link: 'bg-transparent underline-offset-4 hover:underline text-primary hover:text-primary/90 p-0 h-auto',
		destructive:
			'bg-red-500 text-white hover:bg-red-600 focus-visible:ring-red-500',
		gold: 'bg-clientLightYellow text-clientBlack hover:bg-clientLightYellow/90 focus-visible:ring-clientLightYellow/50',
	};

	const sizes = {
		sm: 'h-8 px-3 text-xs',
		md: 'h-10 px-4 py-2',
		lg: 'h-12 px-6 py-3 text-lg',
		icon: 'h-10 w-10',
	};

	const classes = `${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`;

	return (
		<button
			type={type}
			className={classes}
			disabled={disabled}
			onClick={onClick}
			{...props}>
			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	variant: PropTypes.oneOf([
		'primary',
		'secondary',
		'outline',
		'ghost',
		'link',
		'destructive',
		'accent',
		'gold',
	]),
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'icon']),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	onClick: PropTypes.func,
};

export default Button;
