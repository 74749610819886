/** @format */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateImages, updateUser, getAllUsers } from '../../store/user.js';
import { getPrices } from '../../store/price.js';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Tabs from '../../components/ui/tabs';
import Avatar from '../../components/ui/avatar';
import { useToast } from '../../components/ui/toast';
import ClientBusinessInfo from './ClientBusinessInfo.js';
import ClientBrokerInfo from './ClientBrokerInfo.js';
import ClientOtherInfo from './ClientOtherInfo.js';
import AdminInfo from './AdminInfo.js';
import newLogo from '../../assets/marketing/newLogo.webp';
import Moment from 'react-moment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
	FiDownload,
	FiUpload,
	FiTrash2,
	FiUser,
	FiMail,
	FiPhone,
	FiMapPin,
	FiCalendar,
	FiAlertCircle,
	FiCheckCircle,
	FiBriefcase,
	FiHome,
	FiInfo,
	FiSettings,
	FiEdit,
	FiImage,
	FiCamera,
	FiPlus,
	FiRefreshCw,
	FiCreditCard,
} from 'react-icons/fi';
import SublyApi from '../../helpers/Api.js';
import ManageSubscriptionsModal from './ManageSubscriptionsModal.js';

const ClientDetails = () => {
	const dispatch = useDispatch();
	const { addToast } = useToast();
	const { username } = useParams();

	// Redux state
	const token = useSelector((st) => st.admin.token);
	const users = useSelector((st) => st.user.allUsers);
	const pending = useSelector((st) => st.user.pending);
	const prices = useSelector((state) => state.price.allPrices);

	// Local state
	const [user, setUser] = useState({});
	const [activeTab, setActiveTab] = useState('business');
	const [subscriptions, setSubscriptions] = useState([]);
	const [isUploading, setIsUploading] = useState({
		logo: false,
		headshot: false,
	});
	const [imageErrors, setImageErrors] = useState({
		logo: '',
		headshot: '',
	});
	const [imagePreview, setImagePreview] = useState({
		logo: null,
		headshot: null,
	});
	const [showUploadOptions, setShowUploadOptions] = useState({
		logo: false,
		headshot: false,
	});
	const [imageLoadErrors, setImageLoadErrors] = useState({
		logo: false,
		headshot: false,
	});
	const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
	const [showManageSubscriptionsModal, setShowManageSubscriptionsModal] =
		useState(false);

	// Fetch users and set current user
	useEffect(() => {
		dispatch(getAllUsers({ token }));
	}, [dispatch, token]);

	useEffect(() => {
		const currentUser = users.find((u) => u.username === username);
		if (currentUser) {
			setUser(currentUser);
		}
	}, [users, username]);

	// Preload images when user data changes
	useEffect(() => {
		if (user && Object.keys(user).length > 0) {
			// Clear any existing image load errors when user data changes
			setImageLoadErrors({
				headshot: false,
				logo: false,
			});

			// Preload headshot
			if (user.headshot) {
				const headshotImg = new Image();
				headshotImg.onload = () => {
					// Clear error state on successful load
					setImageLoadErrors((prev) => ({
						...prev,
						headshot: false,
					}));
				};
				headshotImg.onerror = () => {
					console.error('Failed to preload headshot:', user.headshot);
				};
				headshotImg.src = user.headshot;
			}

			// Preload logo
			if (user.logo) {
				const logoImg = new Image();
				logoImg.onload = () => {
					// Clear error state on successful load
					setImageLoadErrors((prev) => ({
						...prev,
						logo: false,
					}));
				};
				logoImg.onerror = () => {
					console.error('Failed to preload logo:', user.logo);
				};
				logoImg.src = user.logo;
			}
		}
	}, [user]);

	// Fetch subscriptions when user changes
	useEffect(() => {
		const fetchSubscriptions = async () => {
			if (user.customerId) {
				try {
					const response = await SublyApi.getStripeSubscriptions(
						user.customerId,
						token
					);
					console.log('Subscription data:', response);
					// Check if response has stripeSubs property or is the array directly
					const subscriptionsData = response.stripeSubs || response || [];
					setSubscriptions(subscriptionsData);
				} catch (error) {
					console.error('Error fetching subscriptions:', error);
					addToast({
						title: 'Error',
						description: 'Failed to load subscription information',
						type: 'error',
					});
				}
			}
		};

		fetchSubscriptions();
	}, [user, token, addToast]);

	// Reset upload state when pending changes
	useEffect(() => {
		if (!pending) {
			setIsUploading({ logo: false, headshot: false });
		}
	}, [pending]);

	// Fetch prices when modal opens
	useEffect(() => {
		if (showManageSubscriptionsModal && user?.customerId) {
			dispatch(getPrices({ token }));
		}
	}, [showManageSubscriptionsModal, dispatch, token, user]);

	// Handle image download
	const handleDownloadImage = async (imageUrl, type) => {
		try {
			const response = await axios.get(imageUrl, { responseType: 'blob' });
			const filename = `${user.username}_${type}_${
				imageUrl.split('/').pop() || 'image'
			}`;
			saveAs(response.data, filename);

			addToast({
				title: 'Download Complete',
				description: `${
					type.charAt(0).toUpperCase() + type.slice(1)
				} downloaded successfully`,
				type: 'success',
			});
		} catch (error) {
			console.error('Download error:', error);
			addToast({
				title: 'Download Failed',
				description: `Could not download ${type}`,
				type: 'error',
			});
		}
	};

	// Handle image upload
	const handleImageUpload = async (e) => {
		const { name, files } = e.target;

		if (!files || !files[0]) {
			console.log('No file selected');
			return;
		}

		// Log file information for debugging
		console.log(
			`Uploading ${name} file:`,
			files[0].name,
			files[0].size,
			files[0].type
		);

		// Create preview immediately for better UX
		const reader = new FileReader();
		reader.onload = (e) => {
			setImagePreview({
				...imagePreview,
				[name]: e.target.result,
			});
		};
		reader.readAsDataURL(files[0]);

		// Validate file size (max 1MB)
		if (files[0].size > 1024 * 1024) {
			setImageErrors({
				...imageErrors,
				[name]: 'File is too large. Maximum size is 1MB.',
			});

			// Clear preview after error
			setTimeout(() => {
				setImagePreview({
					...imagePreview,
					[name]: null,
				});
			}, 3000);

			return;
		}

		setIsUploading({ ...isUploading, [name]: true });
		setImageErrors({ ...imageErrors, [name]: '' });

		try {
			// Create a new FormData instance for each upload
			const formData = new FormData();
			formData.append(name, files[0]);

			// Log the form data for debugging
			console.log(`FormData for ${name}:`, formData.get(name));

			// Dispatch the update action
			const result = await dispatch(
				updateImages({
					username,
					data: formData,
					token,
				})
			).unwrap();

			console.log(`Upload result for ${name}:`, result);

			// Clear the file input
			e.target.value = '';

			addToast({
				title: 'Upload Complete',
				description: `${
					name.charAt(0).toUpperCase() + name.slice(1)
				} uploaded successfully`,
				type: 'success',
			});

			// Clear any existing image load errors
			setImageLoadErrors({
				...imageLoadErrors,
				[name]: false,
			});

			// Add a delay before refreshing user data to ensure S3 upload is processed
			setTimeout(() => {
				// Refresh user data to ensure we have the latest image URLs
				dispatch(getAllUsers({ token })).then(() => {
					// Preload the image after data refresh
					if (result && result.imageUrl) {
						const img = new Image();
						img.onload = () => {
							// Ensure error state is cleared after successful load
							setImageLoadErrors((prev) => ({
								...prev,
								[name]: false,
							}));
						};
						img.src = result.imageUrl;
					}
				});
			}, 1500); // 1.5 second delay
		} catch (error) {
			console.error(`Upload error for ${name}:`, error);

			// Check if it's an S3-related error
			const errorMessage =
				typeof error === 'string'
					? error
					: 'Failed to upload image. Please try again.';
			const isS3Error =
				errorMessage.toLowerCase().includes('s3') ||
				errorMessage.toLowerCase().includes('amazon') ||
				errorMessage.toLowerCase().includes('storage');

			setImageErrors({
				...imageErrors,
				[name]: isS3Error
					? 'There was a problem with the image storage service. Please try again later.'
					: errorMessage,
			});

			addToast({
				title: 'Upload Failed',
				description: isS3Error
					? 'There was a problem with the image storage service. Please try again later.'
					: errorMessage,
				type: 'error',
			});

			// Clear preview after error
			setTimeout(() => {
				setImagePreview({
					...imagePreview,
					[name]: null,
				});
			}, 3000);
		} finally {
			setIsUploading({ ...isUploading, [name]: false });
		}
	};

	// Handle direct file selection
	const handleDirectFileSelect = (type) => {
		// Create a file input element
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.accept = 'image/png, image/jpeg';
		fileInput.name = type;

		// Set up the change event handler
		fileInput.onchange = (e) => {
			if (e.target.files && e.target.files[0]) {
				// Create a synthetic event object with the name property
				const syntheticEvent = {
					target: {
						name: type,
						files: e.target.files,
					},
				};
				handleImageUpload(syntheticEvent);
			}
		};

		// Trigger the file selection dialog
		fileInput.click();
	};

	// Handle image removal
	const handleRemoveImage = async (imageType) => {
		try {
			setIsUploading({ ...isUploading, [imageType]: true });

			// Clear preview immediately for better UX
			setImagePreview({
				...imagePreview,
				[imageType]: null,
			});

			await dispatch(
				updateUser({
					username,
					data: { [imageType]: '' },
					token,
				})
			).unwrap();

			addToast({
				title: 'Image Removed',
				description: `${
					imageType.charAt(0).toUpperCase() + imageType.slice(1)
				} removed successfully`,
				type: 'success',
			});

			// Add a delay before refreshing user data to ensure S3 deletion is processed
			setTimeout(() => {
				// Refresh user data to ensure we have the latest image URLs
				dispatch(getAllUsers({ token }));
			}, 1500); // 1.5 second delay
		} catch (error) {
			console.error('Remove error:', error);

			// Check if it's an S3-related error
			const errorMessage =
				typeof error === 'string' ? error : 'Could not remove image';
			const isS3Error =
				errorMessage.toLowerCase().includes('s3') ||
				errorMessage.toLowerCase().includes('amazon') ||
				errorMessage.toLowerCase().includes('storage');

			addToast({
				title: 'Remove Failed',
				description: isS3Error
					? 'There was a problem with the image storage service. Please try again later.'
					: errorMessage,
				type: 'error',
			});
		} finally {
			setIsUploading({ ...isUploading, [imageType]: false });
		}
	};

	// Toggle upload options
	const toggleUploadOptions = (type) => {
		setShowUploadOptions({
			...showUploadOptions,
			[type]: !showUploadOptions[type],
		});
	};

	// Get verification status badge
	const getVerificationBadge = () => {
		if (user.verified) {
			return (
				<div className='flex items-center text-green-600 bg-green-50 px-2 py-1 rounded-full text-xs font-medium'>
					<FiCheckCircle className='mr-1 h-3 w-3' />
					Verified
				</div>
			);
		}
		return (
			<div className='flex items-center text-yellow-600 bg-yellow-50 px-2 py-1 rounded-full text-xs font-medium'>
				<FiAlertCircle className='mr-1 h-3 w-3' />
				Pending
			</div>
		);
	};

	// Get subscription status badge
	const getSubscriptionBadge = (subscription) => {
		// Check if canceled at period end but still active
		if (subscription.cancel_at_period_end) {
			return (
				<div className='inline-flex items-center justify-center text-orange-600 bg-orange-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
					<FiAlertCircle className='mr-1 h-3 w-3 flex-shrink-0' />
					<span>Canceling</span>
				</div>
			);
		}

		// Handle based on status
		switch (subscription.status) {
			case 'active':
				return (
					<div className='inline-flex items-center justify-center text-green-600 bg-green-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiCheckCircle className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>Active</span>
					</div>
				);
			case 'canceled':
				return (
					<div className='inline-flex items-center justify-center text-red-600 bg-red-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiAlertCircle className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>Canceled</span>
					</div>
				);
			default:
				return (
					<div className='inline-flex items-center justify-center text-blue-600 bg-blue-50 px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap w-24'>
						<FiInfo className='mr-1 h-3 w-3 flex-shrink-0' />
						<span>{subscription.status || 'Unknown'}</span>
					</div>
				);
		}
	};

	// Get tab icon
	const getTabIcon = (tabName) => {
		switch (tabName) {
			case 'business':
				return <FiBriefcase className='mr-2 h-4 w-4' />;
			case 'broker':
				return <FiHome className='mr-2 h-4 w-4' />;
			case 'other':
				return <FiInfo className='mr-2 h-4 w-4' />;
			case 'admin':
				return <FiSettings className='mr-2 h-4 w-4' />;
			default:
				return null;
		}
	};

	// Add cache buster to URL
	const addCacheBuster = (url) => {
		if (!url) return url;
		const separator = url.includes('?') ? '&' : '?';
		return `${url}${separator}t=${Date.now()}`;
	};

	// Get image source with preview support
	const getImageSource = (type) => {
		// If we have a preview, use that
		if (imagePreview[type]) {
			return imagePreview[type];
		}

		let imageUrl = null;

		// Get the appropriate image URL based on type
		if (type === 'headshot') {
			imageUrl = user.headshot || null;
		} else {
			imageUrl = user.logo || null;
		}

		// If we have an image URL, return it without cache busting
		// This prevents unnecessary reloads that can cause flashing
		if (imageUrl) {
			return imageUrl;
		}

		return null;
	};

	// Get default avatar for headshot
	const getDefaultAvatar = () => {
		if (user.firstName && user.lastName) {
			return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
		}
		return 'CL';
	};

	// Get random gradient for default avatar
	const getRandomGradient = () => {
		const gradients = [
			'from-green-400 to-green-600',
			'from-blue-400 to-blue-600',
			'from-purple-400 to-purple-600',
			'from-indigo-400 to-indigo-600',
			'from-pink-400 to-pink-600',
			'from-red-400 to-red-600',
			'from-yellow-400 to-yellow-600',
			'from-teal-400 to-teal-600',
		];

		// Use username as a seed for consistent gradient
		const seed = user.username
			? user.username.charCodeAt(0) % gradients.length
			: 0;
		return gradients[seed];
	};

	// Handle image load error
	const handleImageError = (type) => {
		console.error(`Failed to load ${type} image:`, getImageSource(type));

		// Only set error if not already set to avoid re-renders
		if (!imageLoadErrors[type]) {
			setImageLoadErrors((prev) => ({
				...prev,
				[type]: true,
			}));
		}
	};

	// Handle image load success
	const handleImageLoad = (type) => {
		if (imageLoadErrors[type]) {
			setImageLoadErrors((prev) => ({
				...prev,
				[type]: false,
			}));
		}
	};

	// Handle opening subscription management modal
	const handleManageSubscriptions = () => {
		setShowManageSubscriptionsModal(true);
	};

	const handleRefreshSubscriptions = async () => {
		if (user?.customerId) {
			try {
				const response = await SublyApi.getStripeSubscriptions(
					user.customerId,
					token
				);
				console.log('Refreshed subscriptions:', response);

				// Check if response has stripeSubs property or is the array directly
				const updatedSubscriptions = response.stripeSubs || response || [];
				setSubscriptions(updatedSubscriptions);

				addToast({
					title: 'Success',
					description: 'Subscription data refreshed successfully',
					type: 'success',
				});
			} catch (error) {
				console.error('Error refreshing subscriptions:', error);
				addToast({
					title: 'Error',
					description: 'Failed to refresh subscription data',
					type: 'error',
				});
			}
		}
	};

	if (!user || Object.keys(user).length === 0) {
		return (
			<div className='flex flex-col items-center justify-center h-64 bg-white rounded-lg shadow-sm border border-gray-100 p-8'>
				<div className='animate-spin h-10 w-10 border-4 border-blue-500 border-t-transparent rounded-full mb-4'></div>
				<span className='text-gray-600 font-medium'>
					Loading client details...
				</span>
				<p className='text-gray-400 text-sm mt-2'>This may take a moment</p>
			</div>
		);
	}

	return (
		<div className='space-y-6'>
			{/* Header */}
			<div className='bg-white rounded-sm shadow-sm border border-gray-100 overflow-hidden'>
				<div className='bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-100 px-6 py-4'>
					<h2 className='text-sm font-montserrat-medium text-gray-900'>
						Client Profile
					</h2>
				</div>
				<div className='p-6'>
					<div className='flex flex-col md:flex-row md:items-center justify-between gap-4'>
						<div className='flex items-center'>
							<Avatar className='h-16 w-16 mr-4 border-2 border-white shadow-md ring-2 ring-primary/10'>
								<Avatar.Image
									src={user.headshot || newLogo}
									alt={`${user.firstName || ''} ${user.lastName || ''}`}
									className='scale-100 transition-transform duration-200 hover:scale-110'
								/>
								<Avatar.Fallback className='text-lg'>
									{user.firstName && user.lastName
										? `${user.firstName[0]}${user.lastName[0]}`
										: 'CL'}
								</Avatar.Fallback>
							</Avatar>
							<div>
								<div className='flex items-center'>
									<h1 className='text-2xl font-glacial text-gray-900'>
										{user.firstName} {user.lastName}
									</h1>
									<div className='ml-3'>{getVerificationBadge()}</div>
								</div>
								<div className='flex flex-wrap items-center mt-2 gap-4'>
									<div className='text-sm font-montserrat-regular text-gray-600 flex items-center'>
										<FiMail className='mr-1.5 h-4 w-4 text-gray-400' />
										{user.email}
									</div>
									<div className='text-sm font-montserrat-regular text-gray-600 flex items-center'>
										<FiUser className='mr-1.5 h-4 w-4 text-gray-400' />
										{user.username}
									</div>
								</div>
							</div>
						</div>
						<div className='flex flex-wrap gap-2 mt-4 md:mt-0'>
							<Button
								variant='secondary'
								size='sm'
								className='flex items-center font-montserrat-medium'>
								<FiEdit className='mr-2 h-4 w-4 text-gray-700' />
								Edit Profile
							</Button>
							<Button
								variant='primary'
								size='sm'
								onClick={handleManageSubscriptions}
								className='flex items-center font-montserrat-medium'>
								<FiCreditCard className='mr-2 h-4 w-4' />
								Manage Subscriptions
							</Button>
						</div>
					</div>
				</div>
			</div>

			{/* Main Content */}
			<div className='grid grid-cols-1 lg:grid-cols-12 gap-6'>
				{/* Sidebar */}
				<div className='lg:col-span-4 space-y-6'>
					{/* Images Card */}
					<Card className='overflow-hidden'>
						<Card.Header className='border-b border-gray-100 bg-gray-50'>
							<Card.Title className='flex items-center font-montserrat-medium text-gray-900'>
								<FiImage className='mr-2 h-5 w-5 text-primary' />
								Images
							</Card.Title>
							<Card.Description className='font-montserrat-regular'>
								Upload and manage client images
							</Card.Description>
						</Card.Header>
						<Card.Content className='p-0'>
							{/* Headshot */}
							<div className='p-6 border-b border-gray-100'>
								<div className='flex justify-center items-center mb-4'>
									<h3 className='text-sm font-montserrat-medium text-gray-900'>
										Headshot
									</h3>
								</div>

								<div className='relative'>
									<div className='flex justify-center'>
										<div className='relative group h-56 w-56'>
											<div className='absolute inset-0 rounded-sm overflow-hidden shadow-sm border border-gray-100'>
												{getImageSource('headshot') ? (
													<>
														<img
															src={getImageSource('headshot')}
															alt={`${user.firstName || ''} ${
																user.lastName || ''
															}`}
															className='h-full w-full object-cover'
															onError={() => handleImageError('headshot')}
															onLoad={() => handleImageLoad('headshot')}
														/>
														{imageLoadErrors.headshot && (
															<div className='absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-90'>
																<div className='text-center p-4'>
																	<FiAlertCircle className='h-8 w-8 text-red-500 mx-auto mb-2' />
																	<p className='text-sm font-montserrat-regular text-gray-700'>
																		Image failed to load
																	</p>
																	<button
																		className='mt-2 px-3 py-1 bg-gray-200 hover:bg-gray-300 text-xs font-montserrat-medium text-gray-800 rounded-sm transition-colors'
																		onClick={() => {
																			// Force a new image fetch with cache busting
																			const img = new Image();
																			img.onload = () => {
																				setImageLoadErrors((prev) => ({
																					...prev,
																					headshot: false,
																				}));
																			};
																			img.src = addCacheBuster(
																				getImageSource('headshot')
																			);
																		}}>
																		Retry
																	</button>
																</div>
															</div>
														)}
													</>
												) : (
													<div
														className={`h-full w-full flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 text-gray-400 text-5xl font-glacial`}
														onClick={() => handleDirectFileSelect('headshot')}>
														{getDefaultAvatar()}
													</div>
												)}
											</div>

											{/* Upload overlay */}
											<div
												className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200 rounded-sm cursor-pointer'
												onClick={() => handleDirectFileSelect('headshot')}>
												<div className='opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white rounded-full p-3 shadow-lg'>
													<FiCamera className='h-6 w-6 text-primary' />
												</div>
											</div>

											{isUploading.headshot && (
												<div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-sm'>
													<div className='animate-spin h-10 w-10 border-4 border-white border-t-transparent rounded-full'></div>
												</div>
											)}
										</div>
									</div>

									{user.headshot && !isUploading.headshot && (
										<div className='flex justify-center mt-4 space-x-3'>
											<Button
												variant='ghost'
												size='sm'
												onClick={() =>
													handleDownloadImage(
														getImageSource('headshot'),
														'headshot'
													)
												}
												className='font-montserrat-medium text-gray-500 hover:text-gray-700'>
												<FiDownload className='h-4 w-4' />
											</Button>

											<Button
												variant='ghost'
												size='sm'
												onClick={() => handleRemoveImage('headshot')}
												className='font-montserrat-medium text-red-500 hover:text-red-600'>
												<FiTrash2 className='h-4 w-4' />
											</Button>
										</div>
									)}

									{imageErrors.headshot && (
										<div className='mt-3 text-sm font-montserrat-regular text-red-500 flex items-center justify-center bg-red-50 p-2 rounded-sm'>
											<FiAlertCircle className='mr-2 h-4 w-4 flex-shrink-0' />
											<span>{imageErrors.headshot}</span>
										</div>
									)}
								</div>
							</div>

							{/* Logo */}
							<div className='p-6'>
								<div className='flex justify-center items-center mb-4'>
									<h3 className='text-sm font-medium text-gray-700'>
										Company Logo
									</h3>
								</div>

								<div className='relative'>
									<div className='flex justify-center'>
										<div className='relative group h-56 w-56'>
											<div className='absolute inset-0 rounded-lg overflow-hidden shadow-sm border border-gray-100'>
												{getImageSource('logo') ? (
													<>
														<img
															src={getImageSource('logo')}
															alt='Company Logo'
															className='h-full w-full object-cover'
															onError={() => handleImageError('logo')}
															onLoad={() => handleImageLoad('logo')}
														/>
														{imageLoadErrors.logo && (
															<div className='absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-90'>
																<div className='text-center p-4'>
																	<FiAlertCircle className='h-8 w-8 text-red-500 mx-auto mb-2' />
																	<p className='text-sm font-montserrat-regular text-gray-700'>
																		Image failed to load
																	</p>
																	<button
																		className='mt-2 px-3 py-1 bg-gray-200 hover:bg-gray-300 text-xs font-montserrat-medium text-gray-800 rounded-sm transition-colors'
																		onClick={() => {
																			// Force a new image fetch with cache busting
																			const img = new Image();
																			img.onload = () => {
																				setImageLoadErrors((prev) => ({
																					...prev,
																					logo: false,
																				}));
																			};
																			img.src = addCacheBuster(
																				getImageSource('logo')
																			);
																		}}>
																		Retry
																	</button>
																</div>
															</div>
														)}
													</>
												) : (
													<div
														className='h-full w-full flex items-center justify-center bg-gray-50 cursor-pointer'
														onClick={() => handleDirectFileSelect('logo')}>
														<div className='flex flex-col items-center justify-center text-gray-400 p-4'>
															<FiImage className='h-14 w-14 mb-3 text-gray-300' />
															<p className='text-sm text-gray-500'>
																Add company logo
															</p>
														</div>
													</div>
												)}
											</div>

											{/* Upload overlay */}
											<div
												className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200 rounded-lg cursor-pointer'
												onClick={() => handleDirectFileSelect('logo')}>
												<div className='opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white rounded-full p-3 shadow-lg'>
													<FiPlus className='h-6 w-6 text-gray-700' />
												</div>
											</div>

											{isUploading.logo && (
												<div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded-lg'>
													<div className='animate-spin h-10 w-10 border-4 border-white border-t-transparent rounded-full'></div>
												</div>
											)}
										</div>
									</div>

									{user.logo && !isUploading.logo && (
										<div className='flex justify-center mt-4 space-x-3'>
											<Button
												variant='ghost'
												size='sm'
												onClick={() =>
													handleDownloadImage(getImageSource('logo'), 'logo')
												}
												className='text-gray-500 hover:text-gray-700'>
												<FiDownload className='h-4 w-4' />
											</Button>

											<Button
												variant='ghost'
												size='sm'
												onClick={() => handleRemoveImage('logo')}
												className='text-red-500 hover:text-red-600'>
												<FiTrash2 className='h-4 w-4' />
											</Button>
										</div>
									)}

									{imageErrors.logo && (
										<div className='mt-3 text-sm text-red-500 flex items-center justify-center bg-red-50 p-2 rounded'>
											<FiAlertCircle className='mr-2 h-4 w-4 flex-shrink-0' />
											<span>{imageErrors.logo}</span>
										</div>
									)}
								</div>
							</div>
						</Card.Content>
					</Card>

					{/* Subscriptions Card */}
					<Card className='overflow-hidden'>
						<Card.Header className='border-b border-gray-100 bg-gray-50/50'>
							<div className='flex justify-between items-center w-full'>
								<div>
									<Card.Title className='flex items-center text-gray-900'>
										<FiCreditCard className='mr-2 h-5 w-5 text-primary' />
										Subscriptions
									</Card.Title>
									<Card.Description>
										Active services for this client
									</Card.Description>
								</div>
								<Button
									variant='ghost'
									size='sm'
									onClick={handleRefreshSubscriptions}
									className='text-gray-500 hover:text-gray-700'>
									<FiRefreshCw className='mr-2 h-4 w-4' />
									Refresh
								</Button>
							</div>
						</Card.Header>
						<Card.Content className='p-0'>
							{subscriptions && subscriptions.length > 0 ? (
								<div className='divide-y divide-gray-100'>
									{subscriptions.map((subscription, index) => {
										console.log(
											`Subscription ${index}:`,
											subscription,
											'Created date:',
											new Date(subscription.created * 1000)
										);
										return (
											<div
												key={index}
												className='p-4 hover:bg-gray-50/50 transition-colors'>
												<div className='flex flex-col'>
													<div className='font-medium text-gray-900'>
														{subscription.productName || 'Unnamed Subscription'}
													</div>
													<div className='flex items-center justify-between mt-1'>
														<div className='text-sm text-gray-500 flex items-center'>
															<FiCalendar className='mr-1 h-3 w-3 flex-shrink-0' />
															<Moment format='LL'>
																{subscription.created * 1000}
															</Moment>
														</div>
														<div className='flex-shrink-0'>
															{getSubscriptionBadge(subscription)}
														</div>
													</div>
													{subscription.cancel_at_period_end && (
														<div className='text-xs text-orange-600 mt-1'>
															Will be canceled at the end of the billing period
														</div>
													)}
													{subscription.price && (
														<div className='mt-2 text-sm text-gray-700'>
															${(subscription.price / 100).toFixed(2)}/month
														</div>
													)}
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div className='p-6 text-center text-gray-500'>
									<FiCreditCard className='h-8 w-8 mx-auto mb-2 text-gray-400' />
									<p>No active subscriptions</p>
								</div>
							)}
						</Card.Content>
					</Card>
				</div>

				{/* Main Content */}
				<div className='lg:col-span-8'>
					<Card className='overflow-hidden'>
						<Card.Header className='border-b border-gray-100 pb-0 bg-white'>
							<Tabs
								value={activeTab}
								onValueChange={setActiveTab}
								className='w-full'>
								<Tabs.List className='w-full -mb-px'>
									<Tabs.Trigger value='business' className='flex items-center'>
										<FiBriefcase className='mr-2 h-4 w-4 text-primary' />
										<span className='font-montserrat-medium'>
											Business Info
										</span>
									</Tabs.Trigger>
									<Tabs.Trigger value='broker' className='flex items-center'>
										<FiUser className='mr-2 h-4 w-4 text-primary' />
										<span className='font-montserrat-medium'>
											Broker Information
										</span>
									</Tabs.Trigger>
									<Tabs.Trigger value='other' className='flex items-center'>
										<FiInfo className='mr-2 h-4 w-4 text-primary' />
										<span className='font-montserrat-medium'>
											Other Information
										</span>
									</Tabs.Trigger>
									<Tabs.Trigger value='admin' className='flex items-center'>
										<FiSettings className='mr-2 h-4 w-4 text-primary' />
										<span className='font-montserrat-medium'>Admin Info</span>
									</Tabs.Trigger>
								</Tabs.List>
							</Tabs>
						</Card.Header>
						<Card.Content className='p-0'>
							<Tabs value={activeTab} className='w-full'>
								<Tabs.Content value='business' className='p-6'>
									<ClientBusinessInfo user={user} />
								</Tabs.Content>
								<Tabs.Content value='broker' className='p-6'>
									<ClientBrokerInfo user={user} />
								</Tabs.Content>
								<Tabs.Content value='other' className='p-6'>
									<ClientOtherInfo user={user} />
								</Tabs.Content>
								<Tabs.Content value='admin' className='p-6'>
									<AdminInfo user={user} />
								</Tabs.Content>
							</Tabs>
						</Card.Content>
					</Card>
				</div>
			</div>

			{/* Manage Subscriptions Modal */}
			{showManageSubscriptionsModal && user && (
				<ManageSubscriptionsModal
					user={user}
					subscriptions={subscriptions}
					availablePrices={prices}
					onClose={() => setShowManageSubscriptionsModal(false)}
					onSubscriptionChange={handleRefreshSubscriptions}
					token={token}
				/>
			)}
		</div>
	);
};

export default ClientDetails;
