import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Card from '../../components/ui/card';
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';
import Label from '../../components/ui/label';
import Form from '../../components/ui/form';
import {
	FiUser,
	FiMail,
	FiLock,
	FiAlertCircle,
	FiCheckCircle,
} from 'react-icons/fi';
import SublyApi from '../../helpers/Api';

// Form validation schema
const schema = yup
	.object({
		username: yup.string().required('Username is required'),
		password: yup
			.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
				'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
			),
		firstName: yup.string().required('First name is required'),
		lastName: yup.string().required('Last name is required'),
		email: yup
			.string()
			.email('Invalid email format')
			.required('Email is required'),
	})
	.required();

const CreateUser = () => {
	const token = useSelector((st) => st.admin.token);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	// Initialize react-hook-form
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: '',
			password: '',
			firstName: '',
			lastName: '',
			email: '',
		},
	});

	// Handle form submission
	const onSubmit = async (formData) => {
		setIsSubmitting(true);
		setErrorMsg('');
		setSuccessMsg('');

		try {
			await SublyApi.adminUserSignup({ data: formData, token });
			setSuccessMsg('User created successfully');
			reset(); // Reset form fields

			// Clear success message after 3 seconds
			setTimeout(() => {
				setSuccessMsg('');
			}, 3000);
		} catch (err) {
			setErrorMsg(Array.isArray(err) ? err[0] : err.toString());
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className='max-w-3xl mx-auto'>
			<Card>
				<Card.Header>
					<Card.Title>Create New User</Card.Title>
					<Card.Description>
						Add a new user to the system. They will receive an email with their
						login details.
					</Card.Description>
				</Card.Header>
				<Card.Content>
					{/* Success message */}
					{successMsg && (
						<div className='rounded-md bg-blue-50 p-4 mb-6'>
							<div className='flex'>
								<div className='flex-shrink-0'>
									<FiCheckCircle
										className='h-5 w-5 text-blue-400'
										aria-hidden='true'
									/>
								</div>
								<div className='ml-3'>
									<p className='text-sm font-medium text-blue-800'>
										{successMsg}
									</p>
								</div>
							</div>
						</div>
					)}

					{/* Error message */}
					{errorMsg && (
						<div className='rounded-md bg-red-50 p-4 mb-6'>
							<div className='flex'>
								<div className='flex-shrink-0'>
									<FiAlertCircle className='h-5 w-5 text-red-400' />
								</div>
								<div className='ml-3'>
									<p className='text-sm font-medium text-red-800'>{errorMsg}</p>
								</div>
							</div>
						</div>
					)}

					<Form onSubmit={handleSubmit(onSubmit)}>
						<div className='grid grid-cols-1 gap-6 sm:grid-cols-2'>
							{/* Username field */}
							<Form.Item>
								<Label htmlFor='username' required>
									Username
								</Label>
								<div className='mt-1 relative rounded-md shadow-sm'>
									<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
										<FiUser className='h-5 w-5 text-gray-400' />
									</div>
									<Input
										id='username'
										className='pl-10'
										error={!!errors.username}
										{...register('username')}
									/>
								</div>
								{errors.username && (
									<Form.Message>{errors.username.message}</Form.Message>
								)}
							</Form.Item>

							{/* Password field */}
							<Form.Item>
								<Label htmlFor='password' required>
									Password
								</Label>
								<div className='mt-1 relative rounded-md shadow-sm'>
									<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
										<FiLock className='h-5 w-5 text-gray-400' />
									</div>
									<Input
										id='password'
										type='password'
										className='pl-10'
										error={!!errors.password}
										{...register('password')}
									/>
								</div>
								{errors.password && (
									<Form.Message>{errors.password.message}</Form.Message>
								)}
							</Form.Item>

							{/* First Name field */}
							<Form.Item>
								<Label htmlFor='firstName' required>
									First Name
								</Label>
								<Input
									id='firstName'
									error={!!errors.firstName}
									{...register('firstName')}
								/>
								{errors.firstName && (
									<Form.Message>{errors.firstName.message}</Form.Message>
								)}
							</Form.Item>

							{/* Last Name field */}
							<Form.Item>
								<Label htmlFor='lastName' required>
									Last Name
								</Label>
								<Input
									id='lastName'
									error={!!errors.lastName}
									{...register('lastName')}
								/>
								{errors.lastName && (
									<Form.Message>{errors.lastName.message}</Form.Message>
								)}
							</Form.Item>

							{/* Email field */}
							<Form.Item className='sm:col-span-2'>
								<Label htmlFor='email' required>
									Email
								</Label>
								<div className='mt-1 relative rounded-md shadow-sm'>
									<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
										<FiMail className='h-5 w-5 text-gray-400' />
									</div>
									<Input
										id='email'
										type='email'
										className='pl-10'
										error={!!errors.email}
										{...register('email')}
									/>
								</div>
								{errors.email && (
									<Form.Message>{errors.email.message}</Form.Message>
								)}
							</Form.Item>
						</div>

						<div className='mt-6 flex justify-end'>
							<Button
								type='button'
								variant='outline'
								className='mr-3'
								onClick={() => reset()}>
								Reset
							</Button>
							<Button type='submit' disabled={isSubmitting}>
								{isSubmitting ? 'Creating...' : 'Create User'}
							</Button>
						</div>
					</Form>
				</Card.Content>
			</Card>
		</div>
	);
};

export default CreateUser;
